import React from "react";
import { useState, useEffect } from "react";
import qs from "qs";
import axios from "axios";
import avatar from "../images/avatar.png";
import suggested_material from "../images/suggested_material.png";
import suggested_material2 from "../images/suggested_material2.png";
import suggested_material3 from "../images/suggested_material3.png";
import suggested_material4 from "../images/suggested_material4.png";
function Suggested_materials() {
  const userDeviceKey = localStorage.getItem("DEVICE_KEY");
  const [materials, setMaterials] = useState([]);
const Materials=[
  {
    IMAGE_URL:suggested_material,
    TITLE:"Physics Volume III",
    DESCRIPTION:"It is the Physics chapter III book of higher secondary in the application",
    PRICE:"559",
},
  {
    IMAGE_URL:suggested_material2,
    TITLE:"Chemistry Volume I",
    DESCRIPTION:"It is the Chemistry chapter I book of higher secondary in the application",
    PRICE:"399",
},
  {
    IMAGE_URL:suggested_material3,
    TITLE:"Mathematics Volume II",
    DESCRIPTION:"It is the Mathematics chapter II book of higher secondary in the application",
    PRICE:"525",
},
  {
    IMAGE_URL:suggested_material4,
    TITLE:"Biology ",
    DESCRIPTION:"It is the Biology book which helps in the project management",
    PRICE:"615",
},

]
  useEffect(() => {
    const reqData = {
      mod: "Materials",
      actionType: "get-notes-and-materials-list",
      subAction: JSON.stringify({
        DEVICE_KEY: userDeviceKey,
      }),
    };
    axios({
      method: "post",
      url: "https://dev-api.guidejuniors.com/",
      data: qs.stringify(reqData),
      header: { "content-type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      let resData = res.data.XSCData;
      // console.log(resData);
      if(resData['LIST']){
         setMaterials(resData.LIST);
      }
      else{
        setMaterials([]);
      }
     
    });
  }, []);

  return (
    <div className="p-3">
      <div>
        <b style={{ fontWeight: "600", fontSize: "1.2rem" }}>
          Suggested Materials
        </b>
      </div>

      {/* {materials.length === 0 ? <p className="text-center mt-5">No Data Found</p> : */}
        <div>
          {Materials.map((data,index) => {
            return (
              <div className="mt-3 row " key={index}>
                <div className="col-2 mt-1">
                  <img
                    src={
                      data.IMAGE_URL 
                    }
                    style={{ borderRadius: "10%", height: "40px", width: "40px" }}
                    alt=""
                  />
                </div>

                <div className="ps-0 mt-0 col-10 ">
                  <div style={{ fontSize: "0.7rem", fontWeight: "500" }}>
                    <b>{data.TITLE}</b>
                  </div>

                  <div style={{ fontSize: "0.7rem", fontWeight: "500" ,whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis",width:"170px"}}>
                    {data.DESCRIPTION}
                  </div>

                  <div style={{ fontSize: "0.7rem", fontWeight: "500" }}>
                    &#8377;{data.PRICE}
                  </div>
                </div>
              </div>
            );
          })
          }
        </div>
      {/* } */}

    </div>
  );
}
export default Suggested_materials;
