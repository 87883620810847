import React, { useEffect, useState } from "react";
import gj_full_logo from "../images/gj_full_logo.png";
import message_icon from "../images/message_icon.png";
import user_icon from "../images/user_icon.png";
import arrow_icon from "../images/arrow_icon.png";
import logout_icon from "../images/logout_icon.png";
import notification from "../images/notification.png";
import header_profile from "../images/header_profile.png";
import hamburger_menu_icon from "../images/hamburger_menu_icon.png";
import { logout,auth } from "./Firebase";
import { Dropdown } from "react-bootstrap";
import "../Styles/SideBar.css";
import axios from "axios";
import qs from "qs";
import avatar from "../images/avatar.png";
import Modal from './NotificationModal';
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import request_img_2 from "../images/request_img_1.png"

export default function EditTopBar() {
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  const userDeviceKey = localStorage.getItem("DEVICE_KEY");
  const [profile, setProfile] = useState({
    profile: "",
  });
  const [user, loading, error] = useAuthState(auth);
  
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (!user) {
      const reqData = {
        mod: "AA",
        actionType: "logout-user",
        subAction: JSON.stringify({
          DEVICE_KEY: localStorage.getItem("DEVICE_KEY"),
          PLATFORM: "WEB",
        }),
      };

      axios({
        method: "post",
        url: "https://dev-api.guidejuniors.com/",
        data: qs.stringify(reqData),
        header: { "content-type": "application/x-www-form-urlencoded" },
      }).then((res) => {
        localStorage.setItem("DEVICE_KEY", "");
        localStorage.setItem("USER_ID", "");
        return navigate("/sign-in");
      });
    }
    //fetchUserName();
  }, [user, loading]);
  useEffect(() => {
    const reqData = {
      mod: "Profile",
      actionType: "get-user-profile",
      subAction: JSON.stringify({
        DEVICE_KEY: userDeviceKey,
      }),
    };

    axios({
      method: "post",
      url: "https://dev-api.guidejuniors.com/",
      data: qs.stringify(reqData),
      header: { "content-type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      let sample = res.data.XSCData;
      setProfile({
        profile: sample.PROFILE_DETAILS,
      });
    });
  }, [userDeviceKey]);

  const getProfileImage = (imageUrl) => {
    if (!imageUrl) {
      return avatar;
    }
    if (imageUrl.includes("http")) {
      return imageUrl;
    }
    return "https://dev-api.guidejuniors.com/" + imageUrl;
  };

  return (
    <div
      className="container-fluid "
      style={{ borderBottom: "2px solid #EBEDEC" }}
    >
      <div className="row desktop_topbar w-100">
        <div className="col-lg-3">
          <a href="/dashboard" className="ms-3">
            <img
              src={gj_full_logo}
              alt="gj_full_logo"
              className="my-2"
              style={{ objectFit: "cover" }}
            />
          </a>
        </div>
        <div className="col-lg-5"></div>
        <div className="col-lg-4   py-4">
          {" "}
          <div className="d-flex " style={{ float: "right" }}>
            <div className="px-2  mt-1">
              <a href="/find-mentors">
                <div
                  className="btn "
                  style={{
                    background: "linear-gradient(to right,#245D51, #00D6A9)",
                    color: "white",
                    border: "none",
                    fontSize: "0.8rem",
                  }}
                >
                  Find Mentor
                </div>
              </a>
            </div>
            <div className="px-2  mt-1">
              <a href="/messages">
                <img src={message_icon} alt="" />
              </a>{" "}
            </div>
            <div className="px-2  mt-1">
              <img src={notification} alt="" style={{ cursor: "pointer" }} onClick={openModal} />
              <Modal show={modalIsOpen} handleClose={closeModal} />
            </div>
            <Dropdown className="me-2 ">
              <Dropdown.Toggle
                id="dropdown-button-dark-example1"
                style={{
                  background: "none",
                  border: "none",
                  boxShadow: "none",
                  color: "black",
                  padding: "0",
                }}
              >
                <img
                  src={request_img_2}
                  className="rounded-circle"
                  width="40"
                  height="40"
                  style={{ objectFit: "cover" }}
                ></img>
              </Dropdown.Toggle>

              <Dropdown.Menu className="mt-2 "
                style={{ fontSize: "0.8rem", height: "220px", width: "280px" }}>
                <div className="ps-3 p-3 d-flex">
                  <img
                    src={request_img_2}
                    className="rounded-circle"
                    width="50"
                    height="50"
                    style={{ objectFit: "cover" }}
                  ></img>
                  <div className="ps-2 my-auto" style={{ fontSize: "0.7rem" }}>
                    <div>
                      <b>John</b>
                    </div>
                    <div>{profile.profile.CLASS_ID <= 12
                      ? "Class:" + profile.profile.CLASS_NAME
                      : profile.profile.CLASS_ID >= 13 &&
                        profile.profile.CLASS_ID <= 17
                        ? "Pursuving Year:" + profile.profile.CLASS_NAME
                        : "Class:" + "XII"}</div>
                  </div>
                </div>
                <Dropdown.Item href="/user-profile">
                  <div
                    className="d-flex"
                    style={{ justifyContent: "space-between", padding: "10px 0px" }}
                  >
                    <div className="d-flex jusfify-content-between">
                      <img src={user_icon} style={{ height: "20px", width: "20px" }} />
                      <span className="ps-3">  View my Profile</span>
                    </div>
                    <div >
                      <img src={arrow_icon} />
                    </div>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item>
                  <div
                    className="d-flex ps-2"
                    style={{ justifyContent: "space-between", padding: "10px 0px" }}
                    onClick={logout}
                  >
                    <div>
                      <img src={logout_icon} width="15" /> <span className="ps-3">Logout</span>
                    </div>
                    <div>
                      <img src={arrow_icon} />
                    </div>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between d-lg-none py-2 ">
        <div>
          <a href="/dashboard">
            <img
              src={gj_full_logo}
              alt="gj_full_logo"
              className=""
              style={{ width: "90%" }}
            ></img>
          </a>
        </div>
        <div>
          <button
            className="btn py-3"
            type="button"
            style={{ boxShadow: "none" }}
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasWithBothOptions"
            aria-controls="offcanvasWithBothOptions"
          >
            <img src={hamburger_menu_icon} />
          </button>

          <div
            className="offcanvas offcanvas-end"
            data-bs-scroll="true"
            tabIndex="-1"
            id="offcanvasWithBothOptions"
            aria-labelledby="offcanvasWithBothOptionsLabel"
          >
            <div className="offcanvas-header">
              <h5
                className="offcanvas-title"
                id="offcanvasWithBothOptionsLabel"
              ></h5>
              <button
                type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <div className="d-flex">
                <div>
                  <img
                    src={
                      "https://dev-api.guidejuniors.com" +
                      profile.profile.IMAGE_URL
                    }
                    className="rounded-circle"
                    width="50"
                    height="50"
                    style={{ objectFit: "cover", backgroundPosition: "center" }}
                  ></img>
                </div>
                <div className="ps-2">
                  <div>{profile.profile.FULL_NAME}</div>
                  <div>{profile.profile.CLASS_ID <= 12
                    ? "Class: " + profile.profile.CLASS_NAME
                    : profile.profile.CLASS_ID >= 13 &&
                      profile.profile.CLASS_ID <= 17
                      ? "Pursuing Year: " + profile.profile.CLASS_NAME
                      : "Years Of Experience: " + profile.profile.CLASS_NAME}</div>
                </div>
              </div>

              <div className=" py-2">
                <a
                  href="/user-profile"
                  className="text-decoration-none text-dark"
                >
                  <div className="d-flex justify-content-between">
                    <div>
                      <img
                        src={user_icon}
                        className="rounded-circle p-2"
                        style={{ background: "#E9EFEE" }}
                      />
                    </div>
                    <div>User Profile</div>
                    <div>
                      <img src={arrow_icon} />
                    </div>
                  </div>
                </a>
              </div>
              <div className=" py-1">

                <div className="d-flex justify-content-between" onClick={openModal}>
                  <div>
                    <img src={notification} alt="" style={{ cursor: "pointer", background: "#E9EFEE" }} className="rounded-circle p-1" />

                  </div>
                  <div>Notification</div>
                  <div>
                    <img src={arrow_icon} />
                  </div>
                </div>
                <Modal show={modalIsOpen} handleClose={closeModal} />
              </div>
              <div className="py-2">
                <a href="/find-mentors">
                  <div
                    className="btn "
                    style={{
                      background: "linear-gradient(to right,#245D51, #00D6A9)",
                      color: "white",
                      border: "none",
                      fontSize: "0.8rem",
                    }}
                  >
                    Find Mentor
                  </div>
                </a>
              </div>
              <div className="py-5">
                <a onClick={logout} className="text-decoration-none text-dark">
                  <div className="d-flex justify-content-between">
                    <div>
                      <img src={logout_icon} />
                    </div>
                    <div>Logout</div>
                    <div>
                      <img src={arrow_icon} />
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
