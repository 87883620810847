import React from "react";
import "../Styles/Dashboard.css";
import Recent_req from "./RecentRequest";
import Popular_mentors from "./PopularMentors";
import Suggested_materials from "./Suggested-materials";
import MessageBar from "./MessageBar";
import Carousel from "./CarouselCard";
import Sidemenu from "./Sidemenu";
import TopBar from "./TopBar";
import Notification from "./Notification";
import MyMentors from "./MyMentors";

function Dashboard() {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <Sidemenu />
          <div
            className="col-lg-10 mt-2"
            style={{ borderLeft: "2px solid #EBEDEC" }}
          >
            <TopBar />
            <div>
              <div className="row ">
                <div className="col-md-8  p-0">
                  <div className="m-2 dashboard_border"
                  style={{
                    border: "2px solid #EBEDEC",
                    borderRadius: "10px",
                    height: "300px",
                  }}
                >
                  
                  <MyMentors />
                  </div>
                </div>              
                <div className="col-md-4  p-0">
                  <div
                    className="m-2 dashboard_border"
                    style={{
                      border: "2px solid #EBEDEC",
                      borderRadius: "10px",
                      height: "300px",
                    }}
                  >
                    <Recent_req />
                  </div>
                </div>
              </div>
              <div className="row ">
                <div className="col-md-4  p-0">
                  <div
                    className=" m-2 dashboard_border"
                    style={{
                      border: "2px solid #EBEDEC",
                      borderRadius: "10px",
                      height: "320px",
                    }}
                  >
                    <Popular_mentors />
                  </div>
                </div>

                <div className="col-md-4  p-0">
                  <div
                    className="m-2 dashboard_border"
                    style={{
                      border: "2px solid #EBEDEC",
                      borderRadius: "10px",
                      height: "320px",
                    }}
                  >
                    <Suggested_materials />
                  </div>
                </div>

                <div className="col-md-4 p-0">
                  <div
                    className="m-2 dashboard_border"
                    style={{
                      border: "2px solid #EBEDEC",
                      borderRadius: "10px",
                      height: "320px",
                    }}
                  >
                    <MessageBar />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Notification />
        </div>
      </div>
    </>
  );
}
export default Dashboard;
