import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


function MentorRequestModal(props) {
   
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Mentor Connection
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className='d-flex justify-content-center'>
                    Are you want to remove this {props.checks!=="BEING_MENTORED"?"mentee?":"mentor?"}
                </p>
                <div className='d-flex p-3 justify-content-center'>
                    <button
                        className="btn my-3 btn-outline-warning  text-dark m-3"
                        style={{ fontSize: "0.8rem", cursor: "pointer", padding: "10px 40px" }}
                        onClick={props.onHide}>Cancel</button>
                    <button className="btn my-3 btn-outline-warning text-dark"
                        style={{ fontSize: "0.8rem", cursor: "pointer", padding: "10px 40px", background: "#ffc107" }}
                        onClick={props.handleMentorRequestModal}>Remove</button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default MentorRequestModal;
