import axios from "axios";
import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  onMessage,
  isSupported,
} from "firebase/messaging";

import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import qs from "qs";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";




const firebaseConfig = {
  apiKey: "AIzaSyCKEASk4qezHrbGi0cuQTpxFwt1WPSQyiA",
  authDomain: "guidejuniors.firebaseapp.com",
  projectId: "guidejuniors",
  storageBucket: "guidejuniors.appspot.com",
  messagingSenderId: "781981362129",
  appId: "1:781981362129:web:be6a4b5d6ce4f2603262f0",
  measurementId: "G-PXZBM64FXY",
};

const FIREBASE_VAPID_KEY =
  "BFdV1fHwbSIFm61bAocObH6kAcXQnvsC-3bIt6Hqg7BPmQcN_YZgwQYzlmC1ZC-wvWb-H0RMP2fiqPYhAMOkOkA";

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

//const messaging = getMessaging();
// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//       console.log("payload", payload);
//       resolve(payload);
//     });
//   });

const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      return getMessaging(); //getMessaging(config);
    }
    console.log("Firebase not supported this browser");
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
})();

export const requestForToken = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      await getFirebaseToken();
    }
  } catch (error) {
    console.log("An error occurred while getting user permission. ", error);
  }
};

const getFirebaseToken = async () => {
  try {
    const messagingResolve = await messaging;
    const currentToken = await getToken(messagingResolve, {
      vapidKey: FIREBASE_VAPID_KEY,
    });

    if (!currentToken) {
      // console.log(
      //   "No registration token available. Request permission to generate one."
      // );
    } else {
      updateFcmToken(currentToken);
    }
    //.then((currentToken) => { })
    //.catch((err) => { console.log("An error occurred while retrieving token. ", err);  });
  } catch (error) {
    // console.log("An error occurred while retrieving token. ", error);
  }
};

export const onMessageListener = async () =>
  new Promise((resolve) =>
    (async () => {
      const messagingResolve = await messaging;
      onMessage(messagingResolve, (payload) => {
        // console.log("On message: ", messaging, payload);
        resolve(payload);
      });
    })()
  );

const signInWithGoogle = async () => {
  
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const users = res.user;
    console.log("user", users);
    const reqData = {
      mod: "AA",
      actionType: "verify-sign-in",
      subAction: JSON.stringify({
        EMAIL: users.email,
        UUID: users.uid,
      }),
    };

    axios({
      method: "post",
      url: "https://dev-api.guidejuniors.com/",
      data: qs.stringify(reqData),
      header: { "content-type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      console.log(res, "he");
      let userData = res.data.XSCMessage;
      // userData === "Login Verified Successfully"
        // ? useNavigate("/create-profile")
        // : useNavigate("dashboard");
    });
  } catch (err) {
    console.error(err); //err.message
    alert("Error Signing In");
  }
};

const updateFcmToken = async (fcmToken) => {
  //  const navigate = useNavigate();
  try {
    const deviceKey = localStorage.getItem("DEVICE_KEY");
    if (deviceKey && fcmToken) {
      const reqData = {
        mod: "GJ",
        actionType: "update-fcm-token",
        subAction: JSON.stringify({
          DEVICE_KEY: deviceKey,
          TOKEN: fcmToken,
          PLATFORM: "WEB",
        }),
      };
      // console.log("fcm token reqData", reqData);
      axios({
        method: "post",
        url: "https://dev-api.guidejuniors.com/",
        data: qs.stringify(reqData),
        header: { "content-type": "application/x-www-form-urlencoded" },
      }).then((res) => {
        // console.log("fcm token update", res);
      });
    }
  } catch (err) {
    // console.error(err);
  }
};

const logInWithEmailandPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error("login", err); //err.message
    // alert("Invalid Email or Password");
    toast.warning("Invalid Username or Password");
  }
};

const registerWithEmailAndPassword = async (email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    //const user = res.user;
    console.log("signup user", res);
  } catch (err) {
    console.error(err);
    // alert(err.message);
    // console.error("register", err.message); //err.message
    // alert("Invalid Email or Password");
    toast.warning(
      err.message === "Firebase: Error (auth/email-already-in-use)."
        ? "Email already-in-use"
        : err.message ===
          "Firebase: Password should be at least 6 characters (auth/weak-password)."
        ? "Pasword must be 6 Characters"
        : err.message === "Firebase: Error (auth/internal-error)."
        ? "Plase enter the empty field"
        : "Invalid Username or Password"
    );
  }
  <ToastContainer duration="2000" />;
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    // console.error(err);
    alert(err.message);
  }
};


const logout = () => {
  signOut(auth);
};

export {
  auth,
  signInWithGoogle,
  logInWithEmailandPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,

  //   signInWithEmailAndPassword,
  // sendPasswordResetEmail,
};
