import "../Styles/SignIn.css";
import Blog_admin_side_img from "../images/sign_in_side_img.png";
import { Link, useNavigate } from "react-router-dom";
import { auth, logInWithEmailandPassword, signInWithGoogle } from "./Firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import React, { useEffect, useState } from "react";
import axios from "axios";
import qs from "qs";
import Gj_Side_Logo from "../images/gj_side_logo.png";
import google_icon from "../images/Google_icon.png";
import { ToastContainer } from "react-toastify";
// const eye = <FontAwesomeIcon icon={faEye} />;
function Sign_in() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error, uid] = useAuthState(auth);

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.uid) {
      const reqData = {
        mod: "AA",
        actionType: "verify-sign-in",
        subAction: JSON.stringify({
          EMAIL: user.email,
          UUID: user.uid,
          PLATFORM: "WEB",
        }),
      };
      // console.log(user, "user");

      axios({
        method: "post",
        url: "https://dev-api.guidejuniors.com/",
        data: qs.stringify(reqData),
        header: { "content-type": "application/x-www-form-urlencoded" },
      }).then((res) => {
        // console.log(res, "ges");
        let userData = res.data;
        localStorage.setItem("DEVICE_KEY", userData.XSCData.DEVICE_KEY);
        localStorage.setItem("USER_ID", userData.XSCData.USER_ID);
        userData.XSCData.REGISTRATION_STATUS === "COMPLETED"
          ? navigate("/dashboard")
          : navigate("/create-profile");
      });
    }
  }, [user]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setPassword("");
    setEmail("");
    // console.log(user.email, "email");
    // console.log(user.uid, "password");
  };
  const signinWidth = window.innerWidth;
  return (

    <div className=" container-fluid ">


      <div className="sign_in_page">
        <div className={(signinWidth<767)?"row px-2 px-md-0 py-1":(signinWidth<991)?"row py-5 px-2 ":"row signin_resolution  "}>
          <div className="signin_form  mt-lg-5  col-lg-6 col-md-12 col-sm-12 col-xs-12">
            {signinWidth < 991 ?
              <div className="d-flex  justify-content-between py-2">
                <div> <i
                  className="fa fa-long-arrow-left "
                  style={{ fontSize: "1.5rem" }}
                ></i>
                  <a
                    href="https://guidejuniors.com/"
                    className="text-decoration-none text-dark"
                  >
                    <span
                      className="ps-2 "
                      style={{ fontSize: "0.8rem", fontWeight: "600" }}
                    >

                    </span>
                  </a></div>
                <div><img src={Gj_Side_Logo} width="30" alt="GJ_LOGO" /></div>
              </div>
              : 
              <div className="home_navigation_signin">
                <i
                  className="fa fa-long-arrow-left "
                  style={{ fontSize: "0.8rem" }}
                ></i>
                <a
                  href="https://guidejuniors.com/"
                  className="text-decoration-none text-dark"
                >
                  <span
                    className="ps-2 "
                    style={{ fontSize: "0.8rem", fontWeight: "600" }}
                  >
                    Home
                  </span>
                </a>
              </div>}
            <div className=" px-md-5 signin_form  mobile_width_signin ">
              <p style={{ fontColor: "#515C5A" }}>
                <b>Sign-in</b>
              </p>
              <div
                style={{
                  fontWeight: "900",
                  fontSize: "2.3rem",
                  lineHeight: "56px",
                }}
              >
                <b>Welcome Back</b>
              </div>
              <div>
                <div
                  style={{
                    color: "#6C7674",
                    fontSize: "0.8rem",
                    fontWeight: "500",
                    lineHeight: "24px",
                  }}
                >
                  {" "}
                  Please enter your account details
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="py-1 mt-4" style={{ fontSize: "0.8rem" }}>
                    {" "}
                    <div className="mb-2">
                      <label>
                        <b>Email address</b>
                      </label>
                    </div>
                    <input
                      type="email"
                      className="resolution_width_signin"
                      style={{
                        height: "60px",
                        width: "400px",
                        backgroundColor: " #FAFAFA",
                        borderRadius: "8px",
                        border: " 1.2px solid #DFE1E1",
                        padding: "20px",
                      }}
                      placeholder="Your Email Address"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value.replace(/\s+/g))}
                    />
                  </div>
                  <div className="py-1 mt-2" style={{ fontSize: "0.8rem" }}>
                    {" "}
                    <div>
                      {" "}
                      <label>
                        <b>Password</b>
                      </label>
                    </div>
                    <div className="mt-2">
                      <input
                        type="password"
                        className="resolution_width_signin"
                        style={{
                          height: "60px",
                          width: "400px",
                          backgroundColor: " #FAFAFA",
                          borderRadius: "8px",
                          border: " 1.2px solid #DFE1E1",
                          padding: "20px",
                        }}
                        placeholder="Your Password"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value.trimStart())}
                      />
                      {/* <i onClick={togglePasswordVisiblity}>{eye}</i> */}{" "}
                    </div>
                  </div>
                  <div
                    className="row py-1 mt-2 resolution_width_signin"
                    style={{ fontSize: "0.8rem", width: "400px" }}
                  >
                    <div className="col-6">
                      <label>
                        <input
                          type="checkbox"
                          style={{ accentColor: "#FFAA3B" }}
                        />
                        <span className="ps-2" style={{ fontSize: "0.8rem" }}>
                          Keep me signed in
                        </span>
                      </label>
                    </div>
                    <div className="col-6 ">
                      <a
                        className="text-decoration-none text-dark"
                        href="/forget-password"
                      >
                        <div className="float-end">Forgot Password?</div>
                      </a>
                    </div>
                  </div>

                  <div
                    className="py-1 mt-2 resolution_width_signin"
                    style={{ fontSize: "0.8rem", width: "400px" }}
                  >
                    <button
                      type="submit"
                      className="btn resolution_width_signin"
                      style={{
                        height: "60px",
                        width: "400px",
                        background: "#FFAA3B",
                        fontSize: "0.8rem",
                        borderRadius: "8px",
                      }}
                      placeholder="SUBMIT"
                      onClick={() => logInWithEmailandPassword(email, password)}
                    >
                      <b>Sign in</b>
                    </button>
                  </div>

                  <div
                    className="row py-1 mt-2 resolution_width_signin"
                    style={{ fontSize: "0.8rem", width: "400px" }}
                  >
                    <div className="col-5">
                      <hr />
                    </div>
                    <div
                      className="col-2 mt-2 justify-content-center"
                      style={{
                        fontSize: "0.8rem",
                        color: "#7B8382",
                        fontWeight: "600",
                      }}
                    >
                      OR
                    </div>
                    <div className="col-5">
                      {" "}
                      <hr />
                    </div>
                  </div>
                  <div className="py-1 mt-2">
                    <div
                      className="btn resolution_width_signin"
                      style={{
                        border: "1.2px solid #DFE1E1",
                        boxSizing: "border-box",
                        width: "400px",
                        height: "60px",
                        textAlign: "center",
                        fontSize: "0.8rem",
                        paddingTop: "20px",
                      }}
                      onClick={signInWithGoogle}
                    >
                      <div>
                        <img src={google_icon}></img>
                        <span className="ps-2">Login with Google</span>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div
                align="center"
                className="mt-3 resolution_width_signin"
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "500",
                  width: "400px",
                }}
              >
                Don't have an account yet?
                <span>
                  <Link
                    className="ps-2 text-decoration-none"
                    style={{ color: "#FFAA3B" }}
                    to="/sign-up"
                  >
                    <b>Sign up</b>
                  </Link>
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-6 display_hidden_medium_signin ">
            <div className="float-end" style={{ margin: "-12px" }}>
              <img
                src={Blog_admin_side_img}
                className="img-fluid "
                alt="blog_admin"
                style={{ height: "100vh" }}
              />
            </div>
          </div>
        </div>
      </div>
<ToastContainer/>
    </div>
  );
}

export default Sign_in;
