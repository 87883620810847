import React, { useEffect, useState } from "react";
import DropFileInput from "./drop-file-input/DropFileInput";
import axios from "axios";
import qs from "qs";
function ProfilePageTwo({ formData, setFormData,fileUrl }) {




  const onFileChange = (files) => {
    console.log(files);
    setFormData({ ...formData, idCard:files})
  }

 
  return (
    <div className="px-md-5">
      
      
     {(formData.check === false)? 
        <div>
          <div className="py-2">
            <input type="number" placeholder="Enter Price" style={{border: "1px solid #DFE1E1",background: "#FAFAFA"}}
             value={formData.price}
             onChange={(event) =>
               setFormData({ ...formData, price: event.target.value })}
            className="p-2 w-100"/>
          </div>
        </div>
        :
        <>
      {/* <div>
          <div className="py-2">
            <input type="number" placeholder="Enter Price" style={{border: "1px solid #DFE1E1",background: "#FAFAFA"}}
             value={0}
             onClick={(event) =>
               setFormData({ ...formData, price: event.target.value })}
            className="p-2 w-100"/>
          </div>
        </div> */}
         {/* <input type="number" placeholder="Enter Price" style={{display:"none"}}
             value={formData.price}
             onChange={(event) =>
               setFormData({ ...formData, price: 0 })
             }
            className="p-2 w-100"/> */}
          
      </>
}

      <div className="py-2">
        
        <label style={{fontWeight:700}}>Upload Document</label>
        <DropFileInput
           
          onFileChange={(files) => onFileChange(files)}
          old={fileUrl}
        />
         {/* <input type="file" onChange={handleChange} /> */}
            {/* <img src={file} /> */}
          {/* <p onClick={remove}>remove</p> */}
      </div>

    </div>
  );
}

export default ProfilePageTwo;
