import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import gj_side_logo from "../../images/gj_side_logo.png";
import EditTopBar from "../EditTopBar";
import ProfilePageOne from "./MaterialPageOne";
import ProfilePageTwo from "./MaterialPageTwo";
import axios from "axios";
import qs from "qs";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.css'

function Create_Profile(props) {

  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [formData, setFormData] = useState({
    title: "",
    describe: "",
    price: "",
    study: "BOOK",
    profileImg:'',
    idCard: '',
    check:true

  });

 const[handle,setHandle]=useState(0);
 const userDeviceKey = localStorage.getItem("DEVICE_KEY");

    // e.prevenatDefault();
//  console.log("hellosd",formData.profileImg);
  // useEffect(()=>{

const[submit,setSubmit]=useState();



const handleS=()=>{
 


  const profileData=new FormData();
  profileData.append("mod","Materials");
  profileData.append("actionType","add-material");
  profileData.append("subAction",JSON.stringify({
    DEVICE_KEY:userDeviceKey,
    TITLE:formData.title,
    CATEGORY:formData.study,
    PRICE:formData.price,
    DESCRIPTION:formData.describe,
    // DOC_FILE:formData.idCard,
    // MATERIAL_IMAGE:formData.profileImg
  }));

  profileData.append("MATERIAL_IMAGE",formData.profileImg);
  profileData.append("DOC_FILE",formData.idCard);
  
  console.log("profile",formData.idCard);


        axios({
          method: 'post',
          url: "https://dev-api.guidejuniors.com/",
          data: profileData,
          header: { "content-type": "multipart/form-data" },
        }).then((res) => {

          if(res.data.XSCStatus===0){
          // alert("submitted");
          setTimeout(function(){
            navigate('/user-profile/edit-profile/edit-materials');
           }, 3000);
           
         
          toast.success("Material Added Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          className: 'toastM'
          });

          }
          else{
            toast.error("Please fill empty fields", {
              position: toast.POSITION.TOP_RIGHT,
              className: 'toastU'
              // className: 'toastM'
              });
              
    
            // navigate('/user-profile/edit-profile/edit-materials');
          
            // props.setAlert(true);

          }
         
    
        })
           
      }


  
const[sub,setSub]=useState();

  const FormTitles = ["ProfilePageOne", "ProfilePageTwo"];

  const PageDisplay = () => {
    if (page === 0) {
      return <ProfilePageOne setSub={sub} formData={formData} setFormData={setFormData} />;
    } else {
      return <ProfilePageTwo formData={formData} setFormData={setFormData} />;
    }
  };

  return (
    <div className="w-100" >
      <EditTopBar />
      
        <div className="row w-100">
        <div className='col-lg-4' style={{fontSize:"0.9rem"}}>
                   <div className='ps-2' style={{fontWeight:600}}>
                   <a href="/user-profile" style={{textDecoration:"none",color:"black"}}> My Profile </a> {" > "} 
                   <a href="/user-profile/edit-profile/edit-materials" style={{textDecoration:"none",color:"black"}}> Book & Materials </a> {" > "} Add Materials
                    </div> 
                </div>
          <div
            className="col-lg-4 mt-4 main"
            style={{ background: "white",border:"3px solid #E9EFEE",borderRadius: "10%" }}
          >
            <div
              className="progressbar w-100 mt-5 mx-2"
              style={{
                height: "5px",
                backgroundColor: "white",

                borderRadius: "30px",
              }}
            >
              <div
                style={{
                  width: page === 0 ? "50%" : page === 1 ? "100%" : "100%"

                }}
              ></div>
            </div>
            <div>
              <div style={{ fontSize: "1rem" }}>
              <div>{PageDisplay()}</div>
                  
                  <div className="float-end my-2 p-2 mt-2">
                    {
                      (page === 0) ? <></> : <button  style={{fontWeight:600}}
                        className="btn w-50"
                        disabled={page === 0}
                        onClick={() => {
                          setPage((currPage) => currPage - 1);
                        }}
                      >
                        Back
                      </button>

                    }

                     <button className={(page===0)?'w-100 btn':'w-50 btn'}  style={(page === 0) ? { marginRight: "-20%", backgroundColor: "#FFAA3B",boxShadow:"none" } : { border: "none", backgroundColor: "#FFAA3B" ,boxShadow:"none"}}
                     form='my-form' type="submit"
                      onClick={() => {
                       
                        if (page === FormTitles.length - 1) {
                          handleS();
                          // navigate('/user-profile/edit-profile/edit-materials')
                          // console.log(formData);
                          // alert("submitted")
                          
                            
                        } else {
                          setPage((currPage) => currPage + 1);
                        }
                      }}
                      
                    >
                      <b style={{ backgroundColor: "FFAA3B",boxShadow:"none" }}>{page === FormTitles.length - 1 ? "Finish" : "Next"}</b>
                    </button>
                    {/* <button form='my-form' type="submit">Outside Button</button> */}
                    {/* <button type="submit">hello</button> */}
                  </div>
                  <ToastContainer />
              </div>
            </div>
          </div>
         
        </div>
      </div>
    
  );
}
export default Create_Profile;
