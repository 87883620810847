import React from "react";
import socketio from "socket.io-client";

const SOCKET_URL = "https://dev-api.guidejuniors.com:8085";
const myUserId = localStorage.getItem("USER_ID"); // get jwt token from local storage or cookie
export const socket = socketio(SOCKET_URL, {
  query: { user_id: myUserId, platform: "WEB" },
});

// export const socket = () => {
//   const myUserId = localStorage.getItem("USER_ID"); // get jwt token from local storage or cookie
//   if (myUserId) {
//     return socketio(SOCKET_URL, {
//       query: { user_id: myUserId },
//     });
//   }
//   return socketio(SOCKET_URL);
// };

// const getSocket = () => {
//   // return socketio.connect(SOCKET_URL, { query: { token },  });
//   const myUserId = localStorage.getItem("USER_ID"); // get jwt token from local storage or cookie
//   if (myUserId) {
//     return socketio(SOCKET_URL, {
//       query: { user_id: myUserId },
//     });
//   }
//   return socketio(SOCKET_URL);
// };

export const SocketContext = React.createContext();
