import React, { useCallback, useEffect, useRef, useState } from "react";
import profile_img from "../../images/profile_img.png";
import Webcam from "react-webcam";
import edit_img from "../../images/user_profile_material.jpg";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Camera_img from "./assets/camera_img.png"
// import { useCallback, useState } from "react";
import Form from 'react-bootstrap/Form';
// import Cropper from "react-easy-crop";
// import getCroppedImg from "./Crop";
// import "../../Styles/MaterialCrop.css"

const videoConstraints = {
  facingMode: 'user',
}

function ProfilePictureModal(props) {
  // const [imgs, setImg] = useState('')

  const hiddenFileInput = React.useRef(null);

  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  const handleChange = event => {
    props.setImgsrc(event.target.files[0]);
    // console.log("profilemodal",event.target.files[0]);
    props.setShowImg(URL.createObjectURL(event.target.files[0]));
    props.setShow(true);
  };



  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton >
        <Modal.Title id="contained-modal-title-vcenter">
          Profile Picture
        </Modal.Title>
      </Modal.Header>
      <Modal.Body align="center">
        <div>

          <Button onClick={handleClick} style={{ fontWeight: 600, background: "#FBA93E", border: "none", boxShadow: "none", whiteSpace: "nowrap", color: "black" }}>
            Upload Photo
          </Button>
          <input type="file" accept="image/*"
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{ display: 'none' }}
          />
          {/* <img src={imgs} width="10%"/> */}

          {/* <Button  onClick={props.handleEdit} style={{ background: "#FBA93E", border: "none" }}></Button> */}
          {/* <Button style={{ background: "#FBA93E", border: "none" }}> */}

          {/* <input type="file" accept="image/*" onChange={handleImage} style={{ display:"none"}} /></Button> */}
        </div>
        {/* <div className="mt-2">
          <Button onClick={props.onCapture} style={{fontWeight:500, background: "white", color: "black", border: "none" }}>Use camera</Button>
        </div> */}
      </Modal.Body>
    </Modal>
  );
}
function SaveDetailsModal(props) {

  const handleSaveExit = (e) => {
    // props.showCroppedImage();
    props.onHide();
    props.setSaveShow();
  }

  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton >
        <Modal.Title id="contained-modal-title-vcenter">
          Save Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to exit ?
        {/* <h4>Centered Modal</h4>
        <p>
          Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
          dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
          consectetur ac, vestibulum at eros.
        </p> */}
      </Modal.Body>
      <Modal.Footer style={{ border: "none" }}>
        <Button onClick={props.onHide} style={{ background: "white", color: "black", border: "none" }}>Exit</Button>
        <Button onClick={handleSaveExit} style={{ background: "#FBA93E", border: "none" }}>Save & Exit</Button>
      </Modal.Footer>
    </Modal>
  );
}
function EditProfileModal(props) {



  // const [crop, setCrop] = useState({ x: 0, y: 0 });
  // const [zoom, setZoom] = useState(1);
  // const [rotation, setRotation] = useState(0);
  // const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  // const [croppedImage, setCroppedImage] = useState(null);

  // const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
  //   setCroppedAreaPixels(croppedAreaPixels);
  // }, []);

  // const showCroppedImage = useCallback(async () => {
  //   try {
  //     const croppedImage = await getCroppedImg(
  //       props.imagesrc,
  //       croppedAreaPixels,
  //       rotation
  //     );
  //     console.log("donee", { croppedImage });
  //     setCroppedImage(croppedImage);
  //     props.setEdit(croppedImage);
  //   } catch (e) {
  //     console.error(e);
  //   }
  // }, [croppedAreaPixels, rotation, props.imagesrc]);

  // const onClose = useCallback(() => {
  //   setCroppedImage(null);
  // }, []);
  const [saveShow, setSaveShow] = useState(false);
  const handleSave = (e) => {
    // e.preventDefault();
    setSaveShow(true);
  }
  const handleClose = (e) => {
    setSaveShow(false);
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >

      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Preview
        </Modal.Title>
      </Modal.Header>
      <Modal.Body align="center" style={{ width: "object-fit" }}>
        <img src={props.showImg} width="50%" height="50%" />
        {/* <div>
          <div
            className="container"
            style={{
              display: props.imagesrc === null || croppedImage !== null ? "none" : "block",
            }}
          >
            <div className="crop-container">
              <Cropper
                image={props.imagesrc}
                crop={crop}
                rotation={rotation}
                zoom={zoom}
                zoomSpeed={4}
                maxZoom={6}
                zoomWithScroll={true}
                showGrid={false}
                aspect={4 / 3}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                onRotationChange={setRotation}
              />
            </div>

          </div>
          <div className="cropped-image-container">
            {croppedImage && (
              <img className="cropped-image" src={croppedImage} alt="cropped" />
            )}
            {croppedImage && <button onClick={onClose}>close</button>}
          </div>
        </div> */}
        <SaveDetailsModal
          show={saveShow}
          onHide={handleClose}
          // showCroppedImage={showCroppedImage}
          setSaveShow={props.onHide}
        />



      </Modal.Body>

      <Modal.Footer style={{ border: "none" }}>

        <Button onClick={handleSave} style={{ background: "#FBA93E", border: "none" }}>Save</Button>

      </Modal.Footer>
    </Modal>
  );
}
function ProfileCaptureModal(props) {
  const handleCapture = () => {
    props.handleClick()
    props.onHide()
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton >
        <Modal.Title id="contained-modal-title-vcenter">
          Profile Picture
        </Modal.Title>
      </Modal.Header>
      <Modal.Body align="center">
        <Webcam
          audio={false}
          style={{
            width: "100%",
            backgroundSize: "cover",
            height: "50vh"
          }}
          ref={props.webcamRef}

          screenshotFormat="imaege/jpeg"
          videoConstraints={props.videoConstraints}
        />
      </Modal.Body>
      <Modal.Footer>

        <Button onClick={props.onHide} style={{ background: "white", color: "black", border: "none" }}>close</Button>
        <Button onClick={handleCapture} style={{ background: "#FBA93E", border: "none" }}>Capture</Button>
      </Modal.Footer>
    </Modal>
  );
}
function ProfilePageOne({ formData, setFormData, setSub }) {

  const [isValid, setIsValid] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  // const [editedImg, setEdit] = useState('');

  const [imgsrc, setImgsrc] = useState('');
  const [showImg, setShowImg] = useState('');

  const [gender, setGender] = useState(formData.gender);
  // const [study, setStudy] = useState('');
  const [show, setshow] = useState(false);
  const [uploadshow, setUploadshow] = useState(false);
  const [saveShow, setSaveShow] = useState(false);
  const [captureshow, setCaptureShow] = useState(false);
  const [picture, setPicture] = useState('');

  const webcamRef = React.useRef(null)
  const capture = React.useCallback(() => {
    const pictureSrc = webcamRef.current.getScreenshot()
    setPicture(pictureSrc)
    // console.log(pictureSrc);
  })



  useEffect(() => {
    console.log("imagefile", imgsrc);
    setFormData({ ...formData, profileImg: imgsrc })

  }, [imgsrc])
  // console.log("lsls",imgsrc);

  const handleCapture = () => {
    setCaptureShow(true);
  }
  const handleChange = (e) => {
    setGender(e.target.value);
    setFormData({ ...formData, gender: e.target.value })
  }
  const handleEdit = (e) => {
    // e.preventDefault();
    setshow(true);
  }
  const handleSave = (e) => {
    // e.preventDefault();
    setSaveShow(true);
  }
  const handleUpload = (e) => {
    // e.preventDefault();


    setUploadshow(true);
  }
  const handleClose = (e) => {
    setshow(false);
    setUploadshow(false);
    setSaveShow(false);
    setCaptureShow(false);
  }
  const handleStudy = (e) => {

    setFormData({ ...formData, study: e.target.value })
  }
  const handleProfilePicture = (e) => {
    setPicture(e.target.value)
  }
  const handleClick = () => {
    capture()
  }
  return (
    <div className="px-md-5" >
      {/* {show ?  */}
      <EditProfileModal show={show}
        onHide={handleClose}
        handleSave={handleSave}
        showImg={showImg}
      // setEdit={setEdit}
      />

      <ProfilePictureModal show={uploadshow}
        onCapture={handleCapture}
        handleEdit={handleEdit}
        onHide={handleClose}
        setImgsrc={setImgsrc}
        setShow={setshow}
        setShowImg={setShowImg}
      />
      <ProfileCaptureModal show={captureshow}
        onHide={handleClose}
        webcamRef={webcamRef}
        videoConstraints={videoConstraints}
        handleClick={handleClick}
      />
      <div align="center">
        {(picture) ? (
          <img src={picture}
            alt="profile_img"
            width="100%"
            height="100%"
            style={{ width: "25%", borderRadius: "100%", width: "70px", height: "70px", objectFit: "cover" }}
            onClick={handleUpload}
          // onChange={(event) =>
          //   setFormData({ ...formData, profileImg: imgsrc })}
          />
        ) : (

          <img

            src={showImg === '' ? Camera_img : showImg}

            alt="upload img"
            width="100%"
            height="100%"
            style={{ width: "25%", borderRadius: "100%", width: "70px", height: "70px", objectFit: "cover" }}
            onClick={handleUpload}
          // onChange={(event) =>
          // setFormData({ ...formData, profileImg: imgsrc})}
          ></img>
        )}
        <div className="d-flex w-100 my-2 p-2 mt-2" >
          <div className="form-check">
            <input className="form-check-input"
              onChange={handleStudy}
              value='BOOK' type="radio" name="flexRadioDefault" id="school" checked={(formData.study === "BOOK" || formData.study == '') ? true : false} />
            <label className="form-check-label" for="school" style={{ fontWeight: 600 }}>
              Hard Copy
            </label>
          </div>
          <div className="form-check mx-2" >
            <input className="form-check-input"

              type="radio"
              onChange={handleStudy}
              value='STUDY MATERIAL'
              name="flexRadioDefault" id="college" checked={(formData.study === "STUDY MATERIAL") ? true : false} />
            <label className="form-check-label" style={{ fontWeight: 600 }} for="college">
              Soft Copy
            </label>
          </div>

        </div>
      </div>
      <Form>
        <div>

          <Form.Control

            type="text"
            className="w-100 my-2 p-2 mt-4"
            style={{ border: "1px solid #DFE1E1", background: "#FAFAFA" }}
            placeholder="Material, book or topic name"
            color="FAFAFA"
            value={formData.title}
            isInvalid={!isValid && isTouched}
            isValid={isValid}
            maxLength={50}
            onChange={(event) => {
              setIsTouched(true);
              const lettersOnly = /^[A-Za-z\s]*$/;
              if (lettersOnly.test(event.target.value)) {
                setFormData({ ...formData, title: event.target.value.trimStart() })
                setIsValid(true);
              } else {
                setIsValid(false);
              }


            }
            }
            required />
          <Form.Control.Feedback type="invalid" style={{ fontWeight: 700, fontSize: "0.8rem" }}>
            Only letters are allowed
          </Form.Control.Feedback>

        </div>
        <div>
          <Form.Control
            as="textarea"
            style={{ border: "1px solid #DFE1E1", background: "#FAFAFA" }}
            placeholder="Description"
            className="w-100 my-2 p-2 mt-2"
            value={formData.describe}
            maxLength={255}
            onChange={(event) =>

              setFormData({ ...formData, describe: event.target.value.trimStart() })
            }
            required />
          <Form.Control.Feedback type="invalid" >
            Please choose a Description
          </Form.Control.Feedback>
        </div>
      </Form>

      <div className="d-flex genders w-100 my-2 p-2 mt-2">

        <label style={{ fontWeight: 600 }}><input type="checkbox" checked={formData.check} onChange={(event) =>
          setFormData({ ...formData, check: event.target.checked })
        } style={{ accentColor: "#FFAA3B", color: "white" }} /> Make it as a free document</label>
      </div>

    </div>
  );
}

export default ProfilePageOne;
