import React, { useCallback, useRef } from 'react'
import Sidemenu from './Sidemenu'
import TopBar from './TopBar'
import menu_dot_vertical from '../images/menu_dots_vertical.png'
import message_send_btn from '../images/message_send_btn.png'
import { useState, useEffect, useContext } from 'react'
import qs from 'qs'
import axios from 'axios'
import avatar from '../images/avatar.png'
import '../Styles/Message.css'
import { SocketContext } from '../../src/context/socket'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Card, Nav, Tab } from 'react-bootstrap'
import {
  createSearchParams,
  useNavigate,
  useSearchParams
} from 'react-router-dom'
import MentorsChatHistory from './MentorsChatHistory'
import search_icon from '../images/search_icon.png'
import circle_img from '../images/circle.png'
import DOMPurify from 'dompurify'

export default function Message() {
  const decodeNames = x => {
    if (x) {
      let r = /\\u([\d\w]{4})/gi
      x = x.replace(r, function (match, grp) {
        return String.fromCharCode(parseInt(grp, 16))
      })
    }

    return x
  }
  const [disabled, setDisabled] = React.useState(false)
  let [searchParams] = useSearchParams()
  const [search, setSearch] = useState('')

  const userDeviceKey = localStorage.getItem('DEVICE_KEY')
  const socket = useContext(SocketContext)

  const [userProfile, setUserProfile] = useState({})
  const [chatId, setChatId] = useState(0)
  const [chatHistory, setChatHistory] = useState([])

  const [message, setMessage] = useState('')
  //const messagesEndRef = useRef(null);
  // const scrollToBottom = () => {
  //     messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  // };

  const currentDateTime =
    new Date().getDate() +
    '/' +
    (new Date().getMonth() + 1) +
    '/' +
    new Date().getFullYear();
  const getMyCreateMessageDate = (messageDate) => {
    const tempDate = new Date(messageDate * 1000);
    const DateFormat = tempDate.getDate() + '/' + (tempDate.getMonth() + 1) + '/' + tempDate.getFullYear();
    return (DateFormat);
  }


  const fetchOlderChatsNew = () => {
    if (userProfile.USER_ID) {
      let firstChatId = 0
      if (chatHistory.length > 0) {
        firstChatId = chatHistory[chatHistory.length - 1]['ID']
      }

      if (firstChatId !== 0) {
        const reqData = {
          mod: 'Chat',
          actionType: 'get-user-chat-history',
          subAction: JSON.stringify({
            DEVICE_KEY: userDeviceKey,
            USER_ID: userProfile.USER_ID,
            CHAT_ID: firstChatId
          })
        }
        axios({
          method: 'post',
          url: 'https://dev-api.guidejuniors.com/',
          data: qs.stringify(reqData),
          header: { 'content-type': 'application/x-www-form-urlencoded' }
        }).then(res => {
          let resData = res.data
          if (resData.XSCData.LIST.length === 0) {
            setDisabled(true)
          } else {
            setDisabled(false)
            setChatHistory([...chatHistory, ...resData.XSCData.LIST.reverse()])
          }
        })
      }
    }
  }
  function DateFormat(unixTimestamp) {
    let date = new Date(unixTimestamp * 1000)
    return date.toLocaleTimeString([], { timeStyle: 'short' })
  }
  function getChatDate(unixTimestamp) {
    let date = new Date(unixTimestamp * 1000)
    const options = {
      weekday: 'short',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }
    let displayDate = date.toLocaleDateString(undefined, options)
    return displayDate
  }
  function getDateTitle(unixTimestamp, index) {
    if (
      index === chatHistory.length - 1 ||
      getChatDate(unixTimestamp) !==
      getChatDate(chatHistory[index + 1]['UTC_DATETIME'])
    ) {
      return (
        <div align='center' className='py-2' style={{ fontSize: '0.7rem' }}>
          {getMyCreateMessageDate(unixTimestamp) === currentDateTime ? "Today" : getChatDate(unixTimestamp)}
        </div>
      )
    } else return ''
  }
  {
    /* get details from the other mentor profile through search params */
  }
  useEffect(() => {
    const user_id = searchParams.get('id')
    const full_name = searchParams.get('fullName')
    const img_url = searchParams.get('imgURL')
    const is_Online = searchParams.get("isOnline")
    if (user_id) {
      let userData = {}
      userData['USER_ID'] = user_id
      userData['FULL_NAME'] = full_name
      userData['IMAGE_URL'] = img_url
      userData['IS_ONLINE'] = is_Online
      setChatHistory([])
      setChatId(0)
      if (userData.USER_ID) {
        setUserProfile(userData)
      }
    }

    socket.on('connect', () => {
      console.log('socket', 'connected')
    })
    socket.on('disconnect', () => {
      console.log('socket', 'disconnected')
    })

    socket.on('new_message', handleIncomingMessage)
    return () => {
      socket.off('new_message')
      //socket.disconnect();
    }
  }, [])
  function convertUnicode(input) {
    return input
      .split('')
      .map(unit => '\\u' + unit.charCodeAt(0).toString(16).padStart(4, '0'))
      .join('')
  }
  const [profileData, setProfileData] = useState('')
  {
    /* Get user Profile*/
  }
  useEffect(() => {
    const reqData = {
      mod: 'Profile',
      actionType: 'get-user-profile',
      subAction: JSON.stringify({
        DEVICE_KEY: userDeviceKey
      })
    }
    axios({
      method: 'post',
      url: 'https://dev-api.guidejuniors.com/',
      data: qs.stringify(reqData),
      header: { 'content-type': 'application/x-www-form-urlencoded' }
    }).then(res => {
      setMessage('')
      let resData = res.data.XSCData.PROFILE_DETAILS
      setProfileData(resData)
    })
  }, [userDeviceKey])
  const handleSubmit = () => {
    const reqData = {
      mod: 'Chat',
      actionType: 'send-message',
      subAction: JSON.stringify({
        DEVICE_KEY: userDeviceKey,
        USER_ID: userProfile.USER_ID,
        CONTENT: convertUnicode(message)
      })
    }

    axios({
      method: 'post',
      url: 'https://dev-api.guidejuniors.com/',
      data: qs.stringify(reqData),
      header: { 'content-type': 'application/x-www-form-urlencoded' }
    }).then(res => {
      setMessage('')
      let resData = res.data
      if (resData.XSCStatus === 0) {
        socket.emit('send_message', {
          content: convertUnicode(message),
          to_user_id: userProfile.USER_ID,
          full_name: profileData.FULL_NAME,
          image_url: profileData.IMAGE_URL,
          chat_id: resData.XSCData.CHAT_ID
        })
        setChatId(resData.XSCData.CHAT_ID)
      }
    })
  }

  const handleIncomingMessage = data => {
    setChatId(data.chat_id)
  }

  useEffect(() => {
    if (userProfile.USER_ID && userProfile.USER_ID !== 0) {
      let lChatId = 0
      if (chatHistory.length > 0) {
        lChatId = chatHistory[0]['ID']
      }
      const reqData = {
        mod: 'Chat',
        actionType: 'get-user-new-chat',
        subAction: JSON.stringify({
          DEVICE_KEY: userDeviceKey,
          USER_ID: userProfile.USER_ID,
          CHAT_ID: lChatId
        })
      }
      axios({
        method: 'post',
        url: 'https://dev-api.guidejuniors.com/',
        data: qs.stringify(reqData),
        header: { 'content-type': 'application/x-www-form-urlencoded' }
      }).then(res => {
        let resData = res.data
        if (resData.XSCStatus === 0) {
          let chatList = resData.XSCData.LIST.reverse()

          let newChatList = [...chatList, ...chatHistory]
          setChatHistory(newChatList)
          if (chatList.length === 0 && lChatId == 0) {
            setDisabled(true)
          }
          //scrollToBottom();
        }
      })
    }
  }, [chatId, userProfile])

  const handleChat = profile => {
    if (profile && profile.USER_ID !== userProfile.USER_ID) {
      setChatHistory([])
      setDisabled(false)
      setChatId(0)
    }
    if (profile.USER_ID) {
      setUserProfile(profile)
    }
  }

  const handleSearch = e => {
    setSearch(e.target.value)
  }

  const handleKeypress = e => {
    if (e.keyCode === 13) {
      e.preventDefault()
      handleSubmit()
    }
  }
  const navigate = useNavigate()
  const handleProfileId = ID => {
    // console.log(ID)
    const mentorId = ID
    navigate({
      pathname: '/find-mentors/mentor-profile',
      search: `?${createSearchParams({
        id: mentorId
      })}`
    })
  }
  return (
    <div className='container-fluid'>
      <div className='row'>
        <Sidemenu />
        <div
          className='col-lg-10 col-md-12 mt-2'
          style={{ borderLeft: '2px solid #EBEDEC' }}
        >
          <TopBar />
          <div className='p-lg-3 desktop_message_tab'>
            <div className='row '>
              <div className='col-lg-8 p-lg-2 mobile_chat_hide'>
                <div
                  className='p-lg-2 rounded chat_border_mobile  p-0 m-0'
                  style={{ border: '1px solid #EBEDEC' }}
                >
                  <div className=''>
                    {!userProfile.USER_ID ? (
                      <div
                        align='center'
                        style={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          textAlign: 'center'
                        }}
                      >
                        {' '}
                        No Chat
                      </div>
                    ) : (
                      <div
                        onClick={() => handleProfileId(userProfile.USER_ID)}
                        style={{ cursor: 'pointer' }}
                      >
                        <img
                          className=''
                          src={
                            userProfile.IMAGE_URL === ''
                              ? avatar
                              : 'https://dev-api.guidejuniors.com/' +
                              userProfile.IMAGE_URL
                          }
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null // prevents looping
                            currentTarget.src = avatar
                          }}
                          style={{
                            borderRadius: '50%',
                            height: '40px',
                            width: '40px',
                            objectFit: 'cover'
                          }}
                          alt=''
                        />
                        {userProfile.IS_ONLINE === '1' ? (
                          <span
                            style={{
                              position: 'relative',
                              left: '-10px',
                              top: '15px'
                            }}
                          >
                            <img src={circle_img} alt='' />
                          </span>
                        ) : (
                          ''
                        )}

                        <span> {userProfile.FULL_NAME}</span>
                      </div>
                    )}
                    {/* <div className="mt-2">
                                                              <img
                                                                  src={menu_dot_vertical}
                                                                  width="15"
                                                                  height="15"
                                                                  alt=""
                                                              />
                                                          </div> */}
                  </div>

                  {/* <div id="scrollableDiv" className="chatbox_scroll"> */}

                  <div
                    id='scrollableDiv'
                    style={{
                      height: 495,
                      overflowY: 'scroll',
                      overflowX: 'hidden',
                      marginRight: -10,
                      display: 'flex',
                      flexDirection: 'column-reverse'
                    }}
                  >
                    <InfiniteScroll
                      dataLength={chatHistory.length}
                      next={fetchOlderChatsNew}
                      // height='100vh'
                      style={{
                        display: 'flex',
                        flexDirection: 'column-reverse'
                      }} //To put endMessage and loader to the top.
                      inverse={true}
                      hasMore={true && !disabled}
                      loader={
                        !userProfile.USER_ID ? <></> : <h4>Loading...</h4>
                      }
                      endMessage={
                        <p style={{ textAlign: 'center' }}>
                          <b>Yay! You have seen it all</b>
                        </p>
                      }
                      scrollableTarget='scrollableDiv'
                    >
                      {chatHistory.map((data, index) => {
                        return (
                          <div
                            key={index}
                            // ref={myRef}
                            className='row '
                            style={{ marginRight: '5px' }}
                          >
                            {getDateTitle(data.UTC_DATETIME, index)}
                            <span className='d-none'>{getMyCreateMessageDate(data.CREATED_DATE)}</span>
                            <div className='col-12'>
                              <div
                                className='py-2'
                                style={{
                                  textAlign: 'left',
                                  fontSize: '0.7rem',
                                  width: '100%'
                                }}
                              >
                                {data.MSG_TYPE === 'RECEIVED' ? (
                                  <div
                                    style={{
                                      width: 'fit-content',
                                      float: 'left'
                                    }}
                                  >
                                    {data.MATERIAL_ID != 0 ? (
                                      <div
                                        className='row rounded p-2'
                                        style={{
                                          backgroundColor: '#F5F5F5',
                                          border: '1px solid #EBEDEC'
                                        }}
                                      >
                                        <div className='col'>
                                          <img
                                            src={
                                              'https://dev-api.guidejuniors.com/' +
                                              data.MATERIAL_IMAGE_URL
                                            }
                                            style={{
                                              borderRadius: '10%',
                                              height: '40px',
                                              width: '40px'
                                            }}
                                            alt=''
                                          />
                                        </div>
                                        <div className='col'>
                                          <div>{data.MATERIAL_NAME}</div>
                                          <div>{data.DESCRIPTION}</div>
                                        </div>
                                        {data.PRICE !== '0' && (
                                          <div className='col'>
                                            Requested Rs&nbsp;{data.PRICE}
                                          </div>
                                        )}
                                      </div>
                                    ) : (
                                      <div
                                      
                                        className='rounded p-2'
                                        style={{
                                        backgroundColor: '#F5F5F5',
                                        border: '1px solid #EBEDEC',
                                        width: "auto",
                                        height: "auto",
                                        minHeight: "40px",
                                        maxWidth: "270px",
                                        overflowWrap:"break-word",
                                        wordBreak:"break-word"
                                        }}
                                      >
                                        <b
                                          dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(
                                              decodeNames(data.MESSAGE)
                                            )
                                          }}
                                        ></b>
                                      </div>
                                    )}
                                    <div>{DateFormat(data.UTC_DATETIME)}</div>
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      width: 'fit-content',
                                      float: 'right'
                                    }}
                                  >
                                    <div
                                      className='rounded p-2 float-end'
                                      style={{
                                        backgroundColor: '#E6FFFA',
                                        border: '1px solid #EBEDEC',
                                        width: "auto",
                                        height: "auto",
                                        minHeight: "40px",
                                        maxWidth: "270px",
                                        overflowWrap:"break-word",
                                        wordBreak:"break-word"
                                      }}
                                    >
                                      <b
                                        dangerouslySetInnerHTML={{
                                          __html: DOMPurify.sanitize(
                                            decodeNames(data.MESSAGE)
                                          )
                                        }}
                                      ></b>
                                      {/* {data.MESSAGE} */}
                                    </div>
                                    <div align='right'>
                                      {DateFormat(data.UTC_DATETIME)}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </InfiniteScroll>
                    {/* <div ref={messagesEndRef} /> */}
                  </div>
                  {!userProfile.USER_ID ? (
                    <></>
                  ) : (
                    <div
                      className='d-flex fixed-bottom search_bar'
                      style={{ zIndex: '0' }}
                    >
                      <form className='d-flex col-12' id='targetform'>
                        <input
                          className='w-100 rounded-pill ps-2 mx-2 '
                          type='text'
                          placeholder='Type Your message ...'
                          value={message}
                          onChange={e => setMessage(e.target.value.trimStart())}
                          style={{ border: '1px solid #EBEDEC' }}
                          onKeyDown={handleKeypress}
                        />
                        <span>
                          <img
                            src={message_send_btn}
                            width='40'
                            height='40'
                            type='submit'
                            form='targetform'
                            onClick={handleSubmit}
                            alt=''
                          />
                        </span>
                      </form>
                    </div>
                  )}
                </div>
              </div>

              <div className='col-lg-4 p-lg-2 mt-lg-0 mt-2'>
                <div
                  className=' p-lg-2 p-md-1 rounded chat_border_mobile'
                  style={{ border: '1px solid #EBEDEC' }}
                >
                  <div
                    className='d-flex'
                    style={{ justifyContent: 'space-between' }}
                  >
                    <div>
                      <b>Message</b>
                    </div>

                    {userProfile === '' ? (
                      <></>
                    ) : (
                      <div
                        style={{ position: 'relative', display: 'inlineBlock' }}
                      >
                        <span
                          className='my-2'
                          style={{
                            position: 'absolute',
                            right: '10px',
                            top: '-5px'
                          }}
                        >
                          <img src={search_icon} width='20' className='ps-1' />
                        </span>
                        <input
                          className='rounded'
                          placeholder='search... '
                          style={{
                            backgroundColor: '#F5F6F6',
                            border: '1px solid grey',
                            borderRadius: '50%',
                            // paddingLeft: "30px",
                            width: '100%',
                            height: '30px'
                          }}
                          onChange={handleSearch}
                        />
                      </div>
                    )}
                  </div>
                  <Tab.Container
                    id='left-tabs-example'
                    defaultActiveKey='first'
                  >
                    <Nav
                      className='flex-row  p-1 my-2 active_link'
                      style={{ backgroundColor: '#E9E9EA' }}
                    >
                      <Nav.Item
                        className='tablet_width w-50'
                        style={{ color: 'black' }}
                      >
                        <Nav.Link
                          eventKey='first'
                          className='btn    w-100'
                          style={{ color: 'black', fontSize: '0.8rem' }}
                        >
                          My network
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className='tablet_width w-50'>
                        <Nav.Link
                          eventKey='second'
                          className='btn   w-100'
                          style={{ color: 'black', fontSize: '0.8rem' }}
                        >
                          My Mentors
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey='first'>
                        <MentorsChatHistory
                          handleChat={handleChat}
                          actionType='get-my-network-list'
                          searchKey={search}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey='second'>
                        <MentorsChatHistory
                          handleChat={handleChat}
                          actionType='get-mentor-list'
                          searchKey={search}
                        />
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="mobile_tablet_tab">
                          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Nav
                              className="flex-row  p-1 my-2 active_link"
                              style={{ backgroundColor: "#E9E9EA" }}
                            >
                              <Nav.Item
                                className="tablet_width w-50"
                                style={{ color: "black" }}
                              >
                                <Nav.Link
                                  eventKey="first"
                                  className="btn    w-100"
                                  style={{ color: "black", fontSize: "0.8rem" }}
                                >
                                  My network
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item className="tablet_width w-50">
                                <Nav.Link
                                  eventKey="second"
                                  className="btn   w-100"
                                  style={{ color: "black", fontSize: "0.8rem" }}
                                >
                                  My Mentors
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                            <Tab.Content>
                              <Tab.Pane eventKey="first">
                                <MentorsChatHistory
                                  handleChat={handleChat}
                                  actionType="get-my-network-list"
                                  searchKey={search}
                                />
                              </Tab.Pane>
                              <Tab.Pane eventKey="second">
                                <MentorsChatHistory
                                  handleChat={handleChat}
                                  actionType="get-mentor-list"
                                  searchKey={search}
                                />
                              </Tab.Pane>
                            </Tab.Content>
                          </Tab.Container>
                        </div> */}
        </div>
      </div>
    </div>
  )
}
