import React from "react";
import add_mentor_icon from "../images/add_mentor_icon.png";
import request_cancel_icon from "../images/request_cancel_icon.png";
import { useState, useEffect } from "react";
import qs from "qs";
import axios from "axios";
import avatar from "../images/avatar.png";
import Swal from "sweetalert2";
import request_img_1 from "../images/request_img_1.png"
import request_img_2 from "../images/request_img_2.png"
import request_img_3 from "../images/request_img_3.png"
import request_img_4 from "../images/request_img_4.png"

function Recent_req() {
  const userDeviceKey = localStorage.getItem("DEVICE_KEY");
  const [notification, setNotification] = useState([]);
  const [notificationAction, setNotificationAction] = useState({});
const Notification=[
  
  {
    IMAGE_URL:request_img_2,
    FULL_NAME:"Joseph",
    CONTENT:"Wants to connect with you"
  },
  {
    IMAGE_URL:request_img_3,
    FULL_NAME:"John",
    CONTENT:"Wants to connect with you"
  },
  {
    IMAGE_URL:request_img_4,
    FULL_NAME:"Anand",
    CONTENT:"Wants to connect with you"
  }
]
  useEffect(() => {
    if (notificationAction.mod) {
      axios({
        method: "post",
        url: "https://dev-api.guidejuniors.com/",
        data: qs.stringify(notificationAction),
        header: { "content-type": "application/x-www-form-urlencoded" },
      }).then((res) => {
        let resData = res.data;
        if (resData.XSCStatus === 0) {
          Swal.fire("Request accepted!", "", "success");
          getNotificationList();
        } else {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
    }
  }, [notificationAction]);

  useEffect(() => {
    getNotificationList();
  }, []);

  const getNotificationList = () => {
    const reqData = {
      mod: "Notification",
      actionType: "get-notification-list",
      subAction: JSON.stringify({
        DEVICE_KEY: userDeviceKey,
      }),
    };
    axios({
      method: "post",
      url: "https://dev-api.guidejuniors.com/",
      data: qs.stringify(reqData),
      header: { "content-type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      let resData = res.data;
      // console.log(resData);
      setNotification(resData.XSCData.LIST);
    });
  };

  const handleRequest = (requestData, actionType) => {
    Swal.fire({
      title:
        actionType == 1
          ? "Do you want to accept the request ?"
          : "Do you want to decline the request?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText:"No",
    }).then((result) => {
      if (result.isConfirmed) {
        let subAction = requestData.SUBACTION;
        subAction.DEVICE_KEY = localStorage.getItem("DEVICE_KEY");

        const reqData = {
          mod: requestData.MOD,
          actionType: requestData.ACTION_TYPE,
          subAction: JSON.stringify(subAction),
        };
        window.location.reload();
        setNotificationAction(reqData);
      }
    });
  };

  return (
    <div>
      {" "}
      <div className="p-3">
        <div>
          <div style={{ fontWeight: "600", fontSize: "1.2rem" }}>
            Recent Request
          </div>

          {Notification.length === 0 ? (
            <p className="text-center mt-5">No Data Found</p>
          ) : (
            <div>
              {Notification.slice(0, 4).map((data,index) => {
                return (
                  <div className="d-flex py-1 px-1 " key={index}>
                    <div className="my-2">
                      <img
                        src={
                          data.IMAGE_URL 
                        }
                        style={{
                          borderRadius: "50%",
                          height: "40px",
                          width: "40px",
                          objectFit:"cover"
                        }}
                      />
                    </div>
                    <div
                      className="ps-2 pt-1 recent_request_clamp d-flex "
                      style={{ fontSize: "0.7rem", width: "100%" }}
                    >
                      <div className="my-auto">
                        <b>{data.FULL_NAME}</b>
                      <span style={{}}>{data.CONTENT}</span>
                      </div>
                      
                    </div>

              
                      <div className="d-flex">
                        <div className="my-auto">
                          <img
                            src={add_mentor_icon}
                            className="ps-2"
                            width="20"
                            alt=""
                            
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleRequest(data.ACTION.DESC.POSITIVE, 1)
                            }
                          />
                        </div>

                        <div align="center" className=" ms-3 my-auto">
                          <img
                            src={request_cancel_icon}
                            width="20"
                            alt=""
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleRequest(data.ACTION.DESC.NEGATIVE, 0)
                            }
                          />
                        </div>
                      </div>

                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default Recent_req;
