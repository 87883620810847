
import React, { useEffect, useState } from 'react'
import EditTopBar from './EditTopBar'
import mentor_profile_bg from '../images/mentor_profile_bg.png'
import request_img_2 from "../images/request_img_2.png"
import share_icon from '../images/share_icon.png'
import message_icon from '../images/message_icon.png'
import MentorProfileCarousel from './MentorProfileCarousel'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Rating } from 'react-simple-star-rating'
import '../Styles/MentorProfile.css'
import avatar from '../images/avatar.png'
import qs from 'qs'
import {
  createSearchParams,
  useNavigate,
  useSearchParams
} from 'react-router-dom'
import axios from 'axios'
import { Card } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import ConnectionRequestModal from './ConnectionRequestModal'
import MentorRequestModal from './MentorRequestModal'
import BasicCard from './BasicCard'
import ReactStars from 'react-rating-stars-component'
import ShareOnSocial from "react-share-on-social";
import favicon from "../images/gj_side_logo.png"
import { toast, ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2'
import MyMentors from './MyMentors'
import suggested_material from "../images/suggested_material.png";
import suggested_material2 from "../images/suggested_material2.png";
import suggested_material3 from "../images/suggested_material3.png";
import suggested_material4 from "../images/suggested_material4.png";
import carousal_img1 from "../images/carousal_img1.png"
import carousal_img2 from "../images/carousal_img2.png"
import carousal_img3 from "../images/carousal_img3.png"
import carousal_img4 from "../images/carousal_img4.png"
import carousal_img5 from "../images/carousal_img5.png"
import carousal_img6 from "../images/carousal_img6.png"
function SampleNextArrow (props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'red' }}
      onClick={onClick}
    >
      NEXT
    </div>
  )
}

function SamplePrevArrow (props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'green' }}
      onClick={onClick}
    >
      BACK
    </div>
  )
}
export default function MentorProfile () {
  const [modalShow, setModalShow] = React.useState(false)
  const [mentorModalShow, setmentorModalShow] = React.useState(false)
  const [mentorProfile, setMentorProfile] = useState({
    profile: '',
    connectionDetails: '',
    mentoringSubjects: [],
    myMaterials: [],
    mentees: [],
    connectionStatus: '',
    mentorStatus: '',
    connectionReqId: ''
  })
  const [rating, setRating] = useState([])
  let [searchParams] = useSearchParams()
  const mentor_id = searchParams.get('id')
  const userDeviceKey = localStorage.getItem('DEVICE_KEY')
  const userID = localStorage.getItem('USER_ID')
  const navigate = useNavigate() 
  if(userDeviceKey===""){
  
     

        navigate("/sign-in")

   
  }
  
  const handleClick = mentor => {
    const mentorId = mentor.USER_ID
    navigate({
      pathname: '/find-mentors/mentor-profile',
      search: `?${createSearchParams({
        id: mentorId
      })}`
    })
  }
  useEffect(() => {
    const reqData = {
      mod: 'UserRating',
      actionType: 'get-user-ratings-list',
      subAction: JSON.stringify({
        USER_ID: mentor_id,
        DEVICE_KEY: userDeviceKey
      })
    }

    axios({
      method: 'post',
      url: 'https://dev-api.guidejuniors.com/',
      data: qs.stringify(reqData),
      header: { 'content-type': 'application/x-www-form-urlencoded' }
    }).then(res => {
      let sample = res.data
      setRating(sample.XSCData.LIST)
    })
  }, [mentor_id, userDeviceKey])
  useEffect(() => {
    const reqData = {
      mod: 'Profile',
      actionType: 'get-mentor-user-profile',
      subAction: JSON.stringify({
        DEVICE_KEY: userDeviceKey,
        USER_ID: mentor_id
      })
    }

    axios({
      method: 'post',
      url: 'https://dev-api.guidejuniors.com/',
      data: qs.stringify(reqData),
      header: { 'content-type': 'application/x-www-form-urlencoded' }
    }).then(res => {
      let sample = res.data
      // console.log("mentor profile",sample.XSCData.MENTOR_STATUS)
      setMentorProfile({
        profile: sample.XSCData.PROFILE_DETAILS,
        connectionDetails: sample.XSCData.CONNECTION_DETAILS,
        mentoringSubjects: sample.XSCData.MENTORING_SUBJECT_DETAILS,
        myMaterials: sample.XSCData.MY_MATERIALS,
        mentees: sample.XSCData.MY_JUNIORS.LIST,
        connectionStatus: sample.XSCData.CONNECTION_STATUS,
        mentorStatus: sample.XSCData.MENTOR_STATUS
      })
    })
  }, [mentor_id])
  const count = mentorProfile.mentees.length

  const getProfileImage = imageUrl => {
    if (!imageUrl) {
      return avatar
    }
    if (imageUrl.includes('http')) {
      return imageUrl
    }
    return 'https://dev-api.guidejuniors.com/' + imageUrl
  }

  const getMentorRequestStatus = status => {
    if (status === 'BEING_MENTORED') {
      return 'Being Mentored'
    } else if (status === 'REQUEST') {
      return ' Ask to be mentor'
    } else if (status === 'REQUESTED') {
      return 'Requested Mentor'
    } else if (status === 'MENTORING') {
      return 'Mentoring'
    }
  }

  const getConnectionStatus = status => {
    if (status === 'CONNECT') {
      return 'Connect'
    } else if (status === 'REQUESTED') {
      return 'Requested'
    } else {
      return 'Connected'
    }
  }
  const decodeNames = x => {
    if (x) {
      let r = /\\u([\d\w]{4})/gi
      x = x.replace(r, function (match, grp) {
        return String.fromCharCode(parseInt(grp, 16))
      })
    }

    return x
  }
  const handleConnectStatus = connectionStatus => {
    let subAction = {}
    let connectActionType = ''

    if (connectionStatus == 'REQUESTED') {
      setModalShow(true)
      // subAction.USER_ID = mentorProfile.profile.USER_ID;
      // subAction.DEVICE_KEY = userDeviceKey;
      // subAction.REQUEST_ID = mentorProfile.connectionStatus.REQUEST_ID;
      // subAction.REQUEST_TYPE = "CONNECTION";
      // connectActionType = "cancel-request"
    } else if (connectionStatus == 'CONNECT') {
      subAction.USER_ID = mentorProfile.profile.USER_ID
      subAction.DEVICE_KEY = userDeviceKey
      connectActionType = 'make-connection-request'
    } else if (connectionStatus == 'CONNECTED') {
      setModalShow(true)
      // subAction.USER_ID = mentorProfile.profile.USER_ID;
      // subAction.DEVICE_KEY = userDeviceKey;
      // connectActionType = "remove-connection"
    }

    // console.log("requested subAction", subAction)
    const reqData = {
      mod: 'Mentor',
      actionType: connectActionType,
      subAction: JSON.stringify(subAction)
    }
    axios({
      method: 'post',
      url: 'https://dev-api.guidejuniors.com/',
      data: qs.stringify(reqData),
      header: { 'content-type': 'application/x-www-form-urlencoded' }
    }).then(res => {
      let resData = res.data
      // console.log(resData)
      if (resData.XSCStatus === 0) {
        window.location.reload()
      }
    })
  }

  useEffect(() => {
    handleConnectStatus()
  }, [])

  const MentorRequest = action => {
    const reqData = {
      mod: 'Mentor',
      actionType: 'mentor-request-action',
      subAction: JSON.stringify({
        DEVICE_KEY: userDeviceKey,
        REQUEST_ID: mentorProfile.mentorStatus.REQUEST_ID,
        ACTION: action == 'accept' ? 'ACCEPT' : 'REJECT'
      })
    }
    axios({
      method: 'post',
      url: 'https://dev-api.guidejuniors.com/',
      data: qs.stringify(reqData),
      header: { 'content-type': 'application/x-www-form-urlencoded' }
    }).then(res => {
      let resData = res.data
      // console.log("accept/ignore", resData)
      if (resData.XSCStatus === 0) {
        window.location.reload()
      }
    })
  }

  const handleConnectRequest = action => {
    const reqData = {
      mod: 'Mentor',
      actionType: 'connect-request-action',
      subAction: JSON.stringify({
        DEVICE_KEY: userDeviceKey,
        REQUEST_ID: mentorProfile.connectionStatus.REQUEST_ID,
        ACTION: action == 'accept' ? 'ACCEPT' : 'REJECT'
      })
    }
    axios({
      method: 'post',
      url: 'https://dev-api.guidejuniors.com/',
      data: qs.stringify(reqData),
      header: { 'content-type': 'application/x-www-form-urlencoded' }
    }).then(res => {
      let resData = res.data
      // console.log("accept/ignore", resData)
      if (resData.XSCStatus === 0) {
        window.location.reload()
      }
    })
  }

  // mentor request modalAPI
  const handleMentorRequest = mentorStatus => {
    let subAction = {}
    let connectActionType = ''

    if (mentorStatus == 'BEING_MENTORED') {
      // setModalShow(true)
      setmentorModalShow(true)
    } else if (mentorStatus == 'MENTORING') {
      // setModalShow(true)
      setmentorModalShow(true)
    } else if (mentorStatus == 'REQUEST') {
      // subAction.MENTOR_ID = mentorProfile.mentorStatus.MENTOR_ID;
      subAction.MENTOR_ID = 531
      subAction.DEVICE_KEY = userDeviceKey
      connectActionType = 'make-mentor-request'
    } else if (mentorStatus == 'REQUESTED') {
      // setModalShow(true)
      setmentorModalShow(true)
    }

    const reqData = {
      mod: 'Mentor',
      actionType: connectActionType,
      subAction: JSON.stringify(subAction)
    }
    axios({
      method: 'post',
      url: 'https://dev-api.guidejuniors.com/',
      data: qs.stringify(reqData),
      header: { 'content-type': 'application/x-www-form-urlencoded' }
    }).then(res => {
      let resData = res.data
      // console.log(resData)
      if (resData.XSCStatus === 0) {
        window.location.reload()
      }
    })
  }
  useEffect(() => {
    handleMentorRequest()
  }, [])

  const handleMentorRequestModal = mentorStatus => {
    let subAction = {}
    let connectActionType = ''

    if (mentorStatus == 'BEING_MENTORED') {
      subAction.USER_ID = mentorProfile.profile.USER_ID
      subAction.DEVICE_KEY = userDeviceKey
      subAction.MENTOR_ID = mentorProfile.mentorStatus.MENTOR_ID
      connectActionType = 'remove-mentor'
    } else if (mentorStatus == 'MENTORING') {
      subAction.USER_ID = mentorProfile.profile.USER_ID
      subAction.MENTOR_ID = mentorProfile.mentorStatus.MENTOR_ID
      subAction.DEVICE_KEY = userDeviceKey
      connectActionType = 'remove-mentee'
    } else if (mentorStatus == 'REQUESTED') {
      subAction.USER_ID = mentorProfile.profile.USER_ID
      subAction.REQUEST_ID = mentorProfile.mentorStatus.REQUEST_ID
      subAction.DEVICE_KEY = userDeviceKey
      subAction.REQUEST_TYPE = 'MENTOR'
      connectActionType = 'cancel-request'
    }

    const reqData = {
      mod: 'Mentor',
      actionType: connectActionType,
      subAction: JSON.stringify(subAction)
    }
    axios({
      method: 'post',
      url: 'https://dev-api.guidejuniors.com/',
      data: qs.stringify(reqData),
      header: { 'content-type': 'application/x-www-form-urlencoded' }
    }).then(res => {
      let resData = res.data
      // console.log(resData)
      if (resData.XSCStatus === 0) {
        window.location.reload()
      }
    })
  }

  // Connection Request ModalAPI -->  Connected,Requested
  const handleRequest = connectionStatus => {
    let subAction = {}
    let connectActionType = ''

    if (connectionStatus == 'REQUESTED') {
      subAction.USER_ID = mentorProfile.profile.USER_ID
      subAction.DEVICE_KEY = userDeviceKey
      subAction.REQUEST_ID = mentorProfile.connectionStatus.REQUEST_ID
      subAction.REQUEST_TYPE = 'CONNECTION'
      connectActionType = 'cancel-request'
    } else if (connectionStatus == 'CONNECTED') {
      subAction.USER_ID = mentorProfile.profile.USER_ID
      subAction.DEVICE_KEY = userDeviceKey
      connectActionType = 'remove-connection'
    }
    const reqData = {
      mod: 'Mentor',
      actionType: connectActionType,
      subAction: JSON.stringify(subAction)
    }
    axios({
      method: 'post',
      url: 'https://dev-api.guidejuniors.com/',
      data: qs.stringify(reqData),
      header: { 'content-type': 'application/x-www-form-urlencoded' }
    }).then(res => {
      let resData = res.data
      // console.log(resData)
      if (resData.XSCStatus === 0) {
        window.location.reload()
      }
    })
  }

  const handleUserChatHistory = mentor => {
    const userId = mentor.USER_ID
    const full_name = mentor.FULL_NAME
    const img_url = mentor.IMAGE_URL
    const onOff = mentor.IS_ONLINE
    if (userId !== userID) {
      navigate({
        pathname: '/messages',
        search: `?${createSearchParams({
          id: userId,
          fullName: full_name,
          imgURL: img_url,
          isOnline: onOff
        })}`
      })
    }
  }
  const currentProfile = window.location.href
  const handleShare = () => {
    console.log(currentProfile)
  }
  const MentorProfile=[
    {SUBJECT:'Mathematics'},{SUBJECT:'Chemistry'},{SUBJECT:'Physics'}
  ]
  const Materials=[
  {
    IMAGE_URL:suggested_material,
    TITLE:"Physics Volume III",
    DESCRIPTION:"It is the Physics chapter III book of higher secondary in the application",
    PRICE:"559",
},
  {
    IMAGE_URL:suggested_material2,
    TITLE:"Chemistry Volume I",
    DESCRIPTION:"It is the Chemistry chapter I book of higher secondary in the application",
    PRICE:"399",
},
  {
    IMAGE_URL:suggested_material3,
    TITLE:"Mathematics Volume II",
    DESCRIPTION:"It is the Mathematics chapter II book of higher secondary in the application",
    PRICE:"525",
},
  {
    IMAGE_URL:suggested_material4,
    TITLE:"Biology ",
    DESCRIPTION:"It is the Biology book which helps in the project management",
    PRICE:"615",
},

]
const Reviews=[
  {
    IMAGE_URL:carousal_img1,
    FULL_NAME:"Teena",
    RATINGS:"3",
    FEEDBACK:"Good analytical skills",
    CREATED_DATE:"24/01/2023"
  }
]
  return (
    <div
      style={{ fontSize: '0.7rem', padding: '0' }}
      className='container-fluid'
    >
      <EditTopBar />

      <div className='mentor_header_content'>
        <div className='pb-4'>
          <div
            className=' pb-5 '
            style={{
              backgroundImage: `url(${mentor_profile_bg})`,
              objectFit: 'cover',
              backgroundSize: '100%',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat'
            }}
          >
            <div className='mentor_mobile py-2 px-3  justify-content-between'>
              <div className='text-white'>
                <a
                  href='/dashboard'
                  className='text-decoration-none text-light'
                >
                  Home
                </a>{' '}
                {'>'}{' '}
                <a
                  href='/find-mentors'
                  className='text-decoration-none text-light'
                >
                  find-mentors
                </a>{' '}
                {'>'}mentor-profile
              </div>
              <div>
                <ShareOnSocial linkTitle="GuideJuniors Profile" linkFavicon={favicon}><img src={share_icon} width='15' height='15' alt='share_icon' /></ShareOnSocial>{' '}
                <span  onClick={() => handleUserChatHistory(mentorProfile.profile)}
                disabled={
                  mentorProfile.profile.USER_ID === userID ? true : false
                }>
                  <img
                    src={message_icon}
                    width='15'
                    height='15'
                    className='ms-2'
                    alt='share_icon'
                  />
                </span>
              </div>
            </div>
            <div className='py-5 ps-2 mentor_mbl_not_show text-light'>
              <a href='/dashboard' className='text-decoration-none text-light'>
                Home
              </a>{' '}
              {'>'}{' '}
              <a
                href='/find-mentors'
                className='text-decoration-none text-light'
              >
                find-mentors
              </a>{' '}
              {'>'}mentor-profile
            </div>
            <div className='float-end mentor_mbl_not_show'>
              <ShareOnSocial linkTitle="GuideJuniors Profile" linkFavicon={favicon} link={currentProfile}>
                <div
                // onClick={handleShare}
                className='btn btn-outline-light me-2'
                style={{ fontSize: '0.7rem' }}
              >
                <img src={share_icon} width='15' height='15' alt='share_icon' />{' '}
                Share Profile
              </div>
              </ShareOnSocial>
              

              <button
                className='btn btn-light text-dark me-2'
                style={{ fontSize: '0.7rem' }}
                onClick={() => handleUserChatHistory(mentorProfile.profile)}
                disabled={
                  mentorProfile.profile.USER_ID === userID ? true : false
                }
              >
                <img
                  src={message_icon}
                  width='15'
                  height='15'
                  alt='share_icon'
                />
                <i className='fa-solid fa-message-lines'></i> Chat
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='row ps-3 ' style={{ width: '100%' }}>
        <div className='col-md-2'>
          <div
            className='card mentor_card p-2'
            style={{
              fontSize: '0.7rem',
              width: '15%',
              position: 'absolute',
              top: '30%'
            }}
          >
            <div align='center' className='py-1'>
              <img
                src={request_img_2}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null // prevents looping
                  currentTarget.src = avatar
                }}
                className='rounded-circle'
                style={{ objectFit: 'cover' }}
                width='50'
                height='50'
                alt=''
              />
            </div>
            <div align='center' className='py-1'>
              <b>Albert</b>
            </div>
            <div align='center' className=''>
              {mentorProfile.profile.CLASS_ID <= 12
                ? 'Class:' + mentorProfile.profile.CLASS_NAME
                : mentorProfile.profile.CLASS_ID >= 13 &&
                  mentorProfile.profile.CLASS_ID <= 17
                ? 'Pursuving:' + mentorProfile.profile.CLASS_NAME
                : 'Class:'+ "V"}
            </div>
            {mentorProfile.mentorStatus.STATUS == 'RESPOND' ? (
              <div>
                <p>has sent a mentor request</p>
                <button
                  className='btn my-1 btn-outline-warning w-100 text-dark'
                  style={{ fontSize: '0.7rem', cursor: 'pointer' }}
                  onClick={() => MentorRequest('accept')}
                >
                  <b>Accept</b>
                </button>
                <button
                  className='btn my-1 btn-outline-warning w-100 text-dark'
                  style={{ fontSize: '0.7rem', cursor: 'pointer' }}
                  onClick={() => MentorRequest('ignore')}
                >
                  <b>Ignore</b>
                </button>
              </div>
            ) : (
              <div
                className='btn btn-warning py-1 text-dark w-100'
                style={{ fontSize: '0.7rem' }}
                onClick={() =>
                  handleMentorRequest(mentorProfile.mentorStatus.STATUS)
                }
              >
                <b>
                  Ask to be mentor
                </b>
              </div>
            )}

            {mentorProfile.connectionStatus.STATUS == 'RESPOND' ? (
              <div>
                <p>has sent a connection request</p>
                <button
                  className='btn my-1 btn-outline-warning w-100 text-dark'
                  style={{ fontSize: '0.7rem', cursor: 'pointer' }}
                  onClick={() => handleConnectRequest('accept')}
                >
                  <b>Accept</b>
                </button>
                <button
                  className='btn my-1 btn-outline-warning w-100 text-dark'
                  style={{ fontSize: '0.7rem', cursor: 'pointer' }}
                  onClick={() => handleConnectRequest('ignore')}
                >
                  <b>Ignore</b>
                </button>
              </div>
            ) : (
              <div
                className='btn my-1 btn-outline-warning w-100 text-dark'
                style={{ fontSize: '0.7rem', cursor: 'pointer' }}
                onClick={() =>
                  handleConnectStatus(mentorProfile.connectionStatus.STATUS)
                }
              >
                <b>
                  {getConnectionStatus(mentorProfile.connectionStatus.STATUS)}
                </b>
              </div>
            )}
            {/* cancel remove modal */}
            <div>
              <ConnectionRequestModal
                handleRequest={() =>
                  handleRequest(mentorProfile.connectionStatus.STATUS)
                }
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </div>
            <div>
              <MentorRequestModal
                handleMentorRequestModal={() =>
                  handleMentorRequestModal(mentorProfile.mentorStatus.STATUS)
                }
                show={mentorModalShow}
                onHide={() => setmentorModalShow(false)}
              />
            </div>

            <div
              className='d-flex py-2'
              style={{ justifyContent: 'space-between' }}
            >
              <div>Connections</div>
              <div>20</div>
            </div>
            <div
              className='d-flex py-2'
              style={{ justifyContent: 'space-between' }}
            >
              <div>Mentors</div>
              <div>10</div>
            </div>
            <div
              className='d-flex py-2'
              style={{ justifyContent: 'space-between' }}
            >
              <div>Mentees</div>
              <div>5</div>
            </div>
          </div>
        </div>
        <div className='col-md-10'>
          <div className='my-2'>
            <div style={{ fontSize: '1.2rem' }}>
              <b>Mentoring Subjects</b>
            </div>
            <div>
              
              {MentorProfile == '' ? (
                <div align="center" style={{ fontSize: '0.9rem' }}>No Data Found</div>
              ) : (
                MentorProfile.map((subject, index) => {
                  return (
                    <div
                      key={index}
                      className='btn rounded me-2 my-2'
                      style={{ background: '#F5F6F6', fontSize: '0.7rem' }}
                    >
                      {subject.SUBJECT}
                    </div>
                  )
                })
              )}
            </div>
          </div>
          <div>
            <div style={{ fontSize: '1.2rem' }}>
              {/* <MentorProfileCarousel mentees={mentorProfile.mentees} /> */}
              <MyMentors/>
            </div>
          </div>
          <div className='my-2'>
            <div style={{ fontSize: '1.2rem' }}>
              <b>Books & Materials Published</b>
            </div>
            <div className='row'>
              {Materials.length === 0 ? (
                <div style={{ fontSize: '0.9rem' }}>No Data Found</div>
              ) : (
                Materials.map((material, index) => {
                  return (
                    <div key={index} className='col-lg-3 col-md-6'>
                      <div className='p-2'>
                        <div
                          className='card rounded p-2'
                          style={{ width: '15rem', fontSize: '0.7rem' }}
                        >
                          <div align='center' className=''>
                            <img
                              src={
                                material.IMAGE_URL
                              }
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null // prevents looping
                                currentTarget.src = avatar
                              }}
                              width='100'
                              height='100'
                              alt='materials'
                              style={{
                                borderRadius: '10%',
                                objectFit: 'cover'
                              }}
                            />
                          </div>

                          <div className='ps-2'>
                            <div style={{ fontSize: '0.7rem' }}>
                              {material.TITLE}
                            </div>
                            <p
                              className='card-text'
                              style={{ fontSize: '0.7rem' }}
                            >
                              $ {material.PRICE}
                            </p>
                            <div
                              className='btn text-dark'
                              style={{
                                backgroundColor: '#FDCD8F',
                                fontSize: '0.7rem',
                                cursor: 'default'
                              }}
                            >
                              <b>
                                {material.CATEGORY === 'BOOK'
                                  ? 'HARD COPY'
                                  : 'SOFY COPY'}
                              </b>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              )}
            </div>
          </div>
          {/* Ratings */}
          <div className='my-2'>
            <div style={{ fontSize: '1.2rem' }}>
              <b>
                Mentor Ratings & Reviews
                {rating.length === 0 ? <span></span> : '-' + rating.length}
              </b>
            </div>
            {Reviews == '' ? (
              <div style={{ fontSize: '0.9rem' }}>No Data Found</div>
            ) : (
              Reviews.map((rating, index) => {
                return (
                  <div key={index} className='py-2'>
                    <div>
                      <img
                        src={rating.IMAGE_URL}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null // prevents looping
                          currentTarget.src = avatar
                        }}
                        className='rounded-circle'
                        style={{ objectFit: 'cover' }}
                        width='30'
                        height='30'
                      />{' '}
                      {decodeNames(rating.FULL_NAME)}
                    </div>
                    <div>
                      <ReactStars
                        count={5}
                        edit={false}
                        value={rating.RATINGS}
                        size={20}
                       
                        emptyIcon={<i className='far fa-star'></i>}
                        fullIcon={<i className='fa fa-star'></i>}
                        activeColor='#49796F'
                      />{' '}
                      {rating.CREATED_DATE}
                    </div>
                    <div>{decodeNames(rating.FEEDBACK)}</div>
                  </div>
                )
              })
            )}
          </div>
        </div>
      </div><ToastContainer/>
    </div>
  )
}