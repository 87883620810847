import React, { useEffect, useState } from "react";
import EditTopBar from "./EditTopBar";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import DropFileInput from "./ProfileUploadForm/drop-file-input/DropFileInput";
import "../Styles/EditUserProfile.css";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import qs from "qs";
import avatar from "../images/avatar.png";
import Swal from "sweetalert2";
import DOMPurify from "dompurify";
import request_img_1 from "../images/request_img_1.png"
const decodeNames = (x) => {
    if (x) {
        let r = /\\u([\d\w]{4})/gi;
        x = x.replace(r, function (match, grp) {
            return String.fromCharCode(parseInt(grp, 16));
        });
    }
    return x;
};

const AddSclClgProValue = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
    }
    const handleShow = () => setShow(true);
    const [addName, setAddName] = useState("");
    const [addScpValue, setScpValue] = useState("");

    const handlesetAddName = (e) => {
        setAddName(e.target.value);
    }
    const handlesetScpValue = (e) => {
        setScpValue(e.target.value);
    }

    const [stateList, setStateList] = useState({
        selState: [],
    });

    const [cityList, setCityList] = useState({
        selCity: [],
    });

    const [stateName, setStateName] = useState("");
    const [cityName, setCityName] = useState("");

    const handlesetStateList = (e) => {
        setStateName(e.target.value);
    }
    const handlesetcityList = (e) => {
        setCityName(e.target.value);
    }

    useEffect(() => {
        const reqData = {
            mod: "GJ",
            actionType: "get-state-list",
            subAction: JSON.stringify({
                DEVICE_KEY: props.deviceKey,
            })
        }
        axios({
            method: "post",
            url: "https://dev-api.guidejuniors.com/",
            data: qs.stringify(reqData),
            headers: { "content-type": "application/x-www-form-urlencoded" },
        })
            .then((res) => {
                let sample = res.data
                setStateList({
                    selState: sample.XSCData
                })
            })
    }, [props.deviceKey])

    useEffect(() => {
        const reqData = {
            mod: "GJ",
            actionType: "get-city-list",
            subAction: JSON.stringify({
                DEVICE_KEY: props.deviceKey,
                STATE_ID: stateName,
            })
        }
        axios({
            method: "post",
            url: "https://dev-api.guidejuniors.com/",
            data: qs.stringify(reqData),
            headers: { "content-type": "application/x-www-form-urlencoded" },
        })
            .then((res) => {
                let sample = res.data
                setCityList({
                    selCity: sample.XSCData,
                })
            })
    }, [props.deviceKey, stateName])

    const addDetailsValue = (e) => {
        e.preventDefault();
        const reqData = {
            mod: "GJ",
            actionType: "add-new-school",
            subAction: JSON.stringify(
                {
                    TYPE: props.type,
                    DEVICE_KEY: props.deviceKey,
                    NAME: addName,
                    BRANCH: addScpValue,
                    STATE_ID: stateName,
                    CITY_ID: cityName,
                }
            )
        }
        axios({
            method: "post",
            url: "https://dev-api.guidejuniors.com/",
            data: qs.stringify(reqData),
            headers: { "content-type": "application/x-www-form-urlencoded" },
        })
    }

    const getAddValue = (value) => {
        if (value == "2") {
            return <>Add new college</>
        } else if (value == "3") {
            return <>Add new organization</>
        } else {
            return <>Add new school</>
        }
    }
    const getAddNewValue = (value) => {
        if (value == "2") {
            return <>Add as a new college</>
        } else if (value == "3") {
            return <>Add as a new organization</>
        } else {
            return <>Add as a new school</>
        }
    }
    const getEnterPlace = (value) => {
        if (value == "2") {
            return "Enter college name"
        } else if (value == "3") {
            return "Enter your organization"
        } else {
            return "Enter school name"
        }
    }
    const getAddValueTitle = (value) => {
        if (value == "2") {
            return "Add new college"
        } else if (value == "3") {
            return "Add new organization"
        } else {
            return "Add new school"
        }
    }

    return (
        <>
            <Button onClick={handleShow} className="float-end mt-2"
                style={{
                    background: "#FBA93E",
                    fontSize: "0.8rem",
                    color: "#515C5A", fontWeight: "600",
                }}>
                {/* Add new school */}
                {getAddValue(props.type)}
            </Button>
            <div>
                <Modal
                    style={{ background: "rgba(95, 99, 97, 0.59)" }}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    scrollable={true}
                    show={show} onHide={handleClose} size="sm"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <h5>{getAddValueTitle(props.type)}</h5>
                        </Modal.Title>
                    </Modal.Header>

                    <form onSubmit={addDetailsValue}>
                        <Modal.Body>
                            <input
                                placeholder={getEnterPlace(props.type)}
                                type="text"
                                className="w-100 mb-3 py-2 ps-3"
                                style={{
                                    border: "1px solid #DFE1E1",
                                    background: "#FAFAFA",
                                    fontWeight: "600",
                                    fontSize: "0.8rem",
                                    color: "#515C5A",
                                }}
                                onChange={handlesetAddName}
                                required
                            />
                            <input
                                placeholder="Enter Branch Name"
                                type="text"
                                className="w-100 mb-3 py-2 ps-3"
                                style={{
                                    border: "1px solid #DFE1E1",
                                    background: "#FAFAFA",
                                    fontWeight: "600",
                                    fontSize: "0.8rem",
                                    color: "#515C5A",
                                }}
                                onChange={handlesetScpValue}
                                required
                            />

                            <select className="form-select form-select-lg mb-3 p-2" aria-label=".form-select-lg example"
                                style={{
                                    border: "1px solid #DFE1E1",
                                    fontWeight: "600",
                                    fontSize: "0.8rem",
                                    color: "#515C5A",
                                }}
                                onChange={handlesetStateList}
                                value={stateName}
                                required
                            >
                                <option value="" selected>Select State</option>
                                {
                                    stateList.selState.map((state, index) => {
                                        return (
                                            <option key={index} value={state.ID}>{state.NAME}</option>
                                        )
                                    })
                                }
                            </select>
                            <select className="form-select form-select-lg mb-3 p-2" aria-label=".form-select-lg example"
                                style={{
                                    border: "1px solid #DFE1E1",
                                    fontWeight: "600",
                                    fontSize: "0.8rem",
                                    color: "#515C5A",
                                }}
                                onChange={handlesetcityList}
                                value={cityName}
                                required
                            >
                                <option value="" selected>Select City</option>
                                {
                                    cityList.selCity.map((city) => {
                                        return (
                                            <option value={city.ID}>{city.NAME}</option>
                                        )
                                    })
                                }
                            </select>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="btn my-1 mx-1" style={{ fontSize: "0.7rem", background: "#FBA93E", color: "#515C5A", fontWeight: "600", }} onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" className="btn my-1 mx-1" type="submit" name="form"
                                style={{
                                    background: "#FBA93E",
                                    fontSize: "0.7rem",
                                    color: "#515C5A", fontWeight: "600",
                                }}
                            >
                                {/* Add as a new school */}
                                {getAddNewValue(props.type)}
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </div>

        </>
    )
}

function SchoolSearchModal(props) {
    const [getschool, setGetSchool] = useState([]);
    const [getSchoolSearch, setGetSchoolSearch] = useState("");
    useEffect(() => {
        const reqData = {
            mod: "GJ",
            actionType: "get-school-list",
            subAction: JSON.stringify({
                TYPE: props.ModelType,
                DEVICE_KEY: props.deviceKey,
                SEARCH_KEY: getSchoolSearch,
            }),
        };
        axios({
            method: "post",
            url: "https://dev-api.guidejuniors.com/",
            data: qs.stringify(reqData),
            header: { "content-type": "application/x-www-form-urlencoded" },
        }).then((res) => {
            const sample = res.data;
            setGetSchool(sample.XSCData);
        });
    }, [getSchoolSearch, props.deviceKey, props.ModelType]);

    const handleSearchInsert = (e) => {
        setGetSchoolSearch(e.target.value);
    };
    const handleSchoolId = (school) => {
        props.setSchool(school);
        props.onHide();
    };

    const getModelTitle = (type) => {
        if (type == "2") {
            return <>Select College</>
        }
        else if (type == "3") {
            return <>Select Organization</>
        }
        else {
            return <>Select School</>
        }
    }

    const getsearchplace = (name) => {
        if (name == "2") {
            return "Search your college name"
        }
        else if (name == "3") {
            return "Search your organization name"
        }
        else {
            return "Search your school name"
        }
    }
    const [show, setshow] = useState(false)
    const handlecancel = () => {
        setshow(false)
        setGetSchoolSearch("");
    }
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            scrollable={true}
        >
            <Modal.Header onHide={handlecancel} closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {getModelTitle(props.ModelType)}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <input
                        type="text"
                        onChange={handleSearchInsert}
                        className="w-100 py-1"
                        // style={{border:"none"}}
                        placeholder={getsearchplace(props.ModelType)}
                        autoFocus
                    />
                </div>
                {getschool.map((school, index) => {
                    return (
                        <div key={index} onClick={() => handleSchoolId(school)}>
                            <div style={{ fontSize: "1rem" }}>
                                <b dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(school.NAME) }}></b>
                            </div>
                            <div style={{ fontSize: "0.8rem" }}>
                                <span>
                                    <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(school.STATE) }}></span>
                                    <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(school.CITY) }}></span>
                                </span>
                            </div>
                        </div>
                    );
                })}
                <AddSclClgProValue deviceKey={props.deviceKey} type={props.ModelType} />
            </Modal.Body>
        </Modal>
    );
}

export default function EditUserProfile() {
    const [click, setclick] = useState(false);
    const [clickAddition, setclickAddition] = useState(false);
    const [clickSecurity, setclickSecurity] = useState(false);
    const [clickNoti, setclickNoti] = useState(false);
    const [profile, setProfile] = useState({
        profile: "",
    });
    const userDeviceKey = localStorage.getItem("DEVICE_KEY");
    useEffect(() => {
        const reqData = {
            mod: "Profile",
            actionType: "get-user-profile",
            subAction: JSON.stringify({
                DEVICE_KEY: userDeviceKey,
            }),
        };
        axios({
            method: "post",
            url: "https://dev-api.guidejuniors.com/",
            data: qs.stringify(reqData),
            header: { "content-type": "application/x-www-form-urlencoded" },
        }).then((res) => {
            let sample = res.data.XSCData;
            setProfile({
                profile: sample.PROFILE_DETAILS,
            });

            if (sample.PROFILE_DETAILS.TYPE == "2") {
                setSelectClg({ ID: sample.PROFILE_DETAILS.SCHOOL_ID, NAME: sample.PROFILE_DETAILS.SCHOOL_NAME });
            } else if (sample.PROFILE_DETAILS.TYPE == "3") {
                setSelectPro({ ID: sample.PROFILE_DETAILS.SCHOOL_ID, NAME: sample.PROFILE_DETAILS.SCHOOL_NAME });
            } else {
                setSelectScl({ ID: sample.PROFILE_DETAILS.SCHOOL_ID, NAME: sample.PROFILE_DETAILS.SCHOOL_NAME });
            }
        });
    }, [userDeviceKey]);



    const handleGeneralClick = () => {
        setclick(true);
        setclickNoti(false);
        setclickSecurity(false);
        setclickAddition(false);
    };
    const handleAdditionClick = () => {
        setclickAddition(true);
        setclick(false);
        setclickSecurity(false);
        setclickNoti(false);
        setStudy(getStudyTitle(profile.profile.TYPE))
    };
    const handleSecurityClick = () => {
        setclickSecurity(true);
        setclickNoti(false);
        setclick(false);
        setclickAddition(false);
    };
    const handleNotificationClick = () => {
        setclickNoti(true);
        setclick(false);
        setclickAddition(false);
        setclickSecurity(false);
    };

    const [updateName, setUpdateName] = useState();
    const handleSetName = (e) => {
        const lettersOnly = /^[A-Za-z\s]*$/;
        if (lettersOnly.test(e.target.value)) {

        setUpdateName(e.target.value.trimStart());
        }
    };
    const [updateDob, setUpdateDob] = useState();
    const handlesetDob = (e) => {
        setUpdateDob(e.target.value);
    };
    const [gender, setGender] = useState(profile.profile.GENDER);
    const handleChangeGender = (e) => {
        setGender(e.target.value);
    };
    const [study, setStudy] = useState();
    const handleStudy = (e) => {
        setStudy(e.target.value);
    };

    useEffect(
        () => setUpdateName(profile.profile.FULL_NAME),
        [profile.profile.FULL_NAME]
    );
    useEffect(() => setUpdateDob(profile.profile.DOB), [profile.profile.DOB]);
    useEffect(() => setGender(profile.profile.GENDER), [profile.profile.GENDER]);
    useEffect(() => setStudy(profile.profile.TYPE), [profile.profile.TYPE]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const profileDataEdu = new FormData();

        profileDataEdu.append("mod", "Profile");
        profileDataEdu.append("actionType", "update-user-edu-details");
        profileDataEdu.append("subAction", JSON.stringify({
            DEVICE_KEY: userDeviceKey,
            SCHOOL_ID: selectSclId,
            CLASS_ID: classId,
            STREAM_ID: streamId,
            GROUP_ID: groupId,
            YEAR: "",
            TYPE: Type,
        }));

        profileDataEdu.append("ID_CARD_IMAGE", uploadFileId);

        axios({
            method: 'post',
            url: "https://dev-api.guidejuniors.com/",
            data: profileDataEdu,
            header: { "content-type": "multipart/form-data" },
        })
            .then((res) => {
                let sample = res.data
                if (sample.XSCStatus === 0) {
                    Swal.fire("error", "", "info");
                }
                Swal.fire("Update Successfully", "", "success");
                setTimeout(function () {
                    window.location.reload(1);
                }, 1000);
            })
    };


    const handleprofileDetail = (e) => {
        e.preventDefault();
        const profileData = new FormData();

        profileData.append("mod", "Profile");
        profileData.append("actionType", "update-profile-details");
        profileData.append("subAction", JSON.stringify({
            DEVICE_KEY: userDeviceKey,
            FULL_NAME: updateName,
            DOB: updateDob,
            GENDER: gender,
            MOBILE_NUMBER: "",
        }));

        profileData.append("PROFILE_IMAGE", chnProfile);
        axios({
            method: 'post',
            url: "https://dev-api.guidejuniors.com/",
            data: profileData,
            header: { "content-type": "multipart/form-data" },
        })
            .then((res) => {
                let sample = res.data;
                console.log(res.data, "res");
                if (sample.XSCStatus === 0) {
                    Swal.fire("error", "", "info");
                }
                Swal.fire("Update Successfully", "", "success");
                setTimeout(function () {
                    window.location.reload(1);
                }, 1000);
            })
    };

    const [selectScl, setSelectScl] = useState(
        {
            ID: "",
            NAME: "",
        }
    );
    const [selectClg, setSelectClg] = useState({
        ID: "",
        NAME: "",
    });
    const [selectPro, setSelectPro] = useState(
        {
            ID: "",
            NAME: "",
        }
    );
    const [schoolShow, setSchoolShow] = useState(false);
    const handlesetscl = () => {
        setSchoolShow(true);
    }
    const handleSchoolClose = () => {
        setSchoolShow(false);
    }
    const [selectCls, setSelectCls] = useState({
        listClass: [],
    });
    const [selectStream, setSelectStream] = useState({
        listStream: [],
    });
    const [selectGroup, setSelectGoup] = useState({
        listGroup: [],
    });

    const [clsValueList, setClsValueList] = useState();
    const [clsSelectStream, setClsSelectStream] = useState();
    const [clsSelectGroup, setClsSelectGroup] = useState();
    const handlesetCls = (e) => {
        setClsValueList(e.target.value)
    }
    const handlesetStream = (e) => {
        setClsSelectStream(e.target.value)
    }
    const handlesetGroup = (e) => {
        setClsSelectGroup(e.target.value)
    }

    useEffect(() => setClsValueList(profile.profile.CLASS_ID), [profile.profile.CLASS_ID]);
    useEffect(() => setClsSelectStream(profile.profile.STREAM_ID), [profile.profile.STREAM_ID]);
    useEffect(() => setClsSelectGroup(profile.profile.GROUP_ID), [profile.profile.GROUP_ID]);

    const [ClgDegree, setClgDegree] = useState();
    const [clgPursuing, setClgPursuing] = useState();
    const [clgStream, setClgStream] = useState();
    const handlesetdegree = (e) => {
        setClgDegree(e.target.value)
    }
    const handlesetPursuing = (e) => {
        setClgPursuing(e.target.value)
    }
    const handlesetClgStream = (e) => {
        setClgStream(e.target.value)
    }
    useEffect(() => setClgDegree(profile.profile.STREAM_ID), [profile.profile.STREAM_ID]);
    useEffect(() => setClgPursuing(profile.profile.CLASS_ID), [profile.profile.CLASS_ID]);
    useEffect(() => setClgStream(profile.profile.GROUP_ID), [profile.profile.GROUP_ID]);

    const [proCategory, setProCategory] = useState();
    const [proExper, setProExper] = useState();
    const [proJobRole, setJobRole] = useState();
    const handlesetCategory = (e) => {
        setProCategory(e.target.value)
    }
    const handlesetExper = (e) => {
        setProExper(e.target.value)
    }
    const handlesetJobRole = (e) => {
        setJobRole(e.target.value)
    }
    useEffect(() => setProCategory(profile.profile.STREAM_ID), [profile.profile.STREAM_ID]);
    useEffect(() => setJobRole(profile.profile.GROUP_ID), [profile.profile.GROUP_ID]);
    useEffect(() => setProExper(profile.profile.CLASS_ID), [profile.profile.CLASS_ID]);
    let Type = "";
    let selectSclId = "";
    let streamId = "";
    let classId = ""
    let groupId = ""

    switch (study) {
        case "school":
            if (clsValueList < 11) {
                streamId = 0
                groupId = 0
            } else {
                streamId = clsSelectStream
                groupId = clsSelectGroup
            }
            Type = 1
            selectSclId = selectScl.ID
            classId = clsValueList
            break;
        case "college":
            Type = 2;
            selectSclId = selectClg.ID
            streamId = ClgDegree
            classId = clgPursuing
            groupId = clgStream
            break;
        case "profession":
            Type = 3;
            selectSclId = selectPro.ID
            streamId = proCategory
            classId = proExper
            groupId = proJobRole
            break;
        default:
            Type = 1;
    }

    useEffect(() => {
        const reqData = {
            mod: "GJ",
            actionType: "get-class-list",
            subAction: JSON.stringify({
                DEVICE_KEY: userDeviceKey,
                TYPE: Type,
            })
        }
        axios({
            method: "post",
            url: "https://dev-api.guidejuniors.com/",
            data: qs.stringify(reqData),
            header: { "content-type": "application/x-www-form-urlencoded" },

        }).then((res) => {
            let sample = res.data;
            setSelectCls({
                listClass: sample.XSCData.LIST,
            })
        })
    }, [userDeviceKey, Type])

    useEffect(() => {
        const reqData = {
            mod: "GJ",
            actionType: "get-stream-list",
            subAction: JSON.stringify({
                DEVICE_KEY: userDeviceKey,
                TYPE: Type,
            })
        }
        axios({
            method: "post",
            url: "https://dev-api.guidejuniors.com/",
            data: qs.stringify(reqData),
            header: { "content-type": "application/x-www-form-urlencoded" },

        }).then((res) => {
            let sample = res.data;
            setSelectStream({
                listStream: sample.XSCData,
            })
        })
    }, [Type, userDeviceKey])

    useEffect(() => {
        const reqData = {
            mod: "GJ",
            actionType: "get-group-list",
            subAction: JSON.stringify({
                DEVICE_KEY: userDeviceKey,
                TYPE: Type,
            }),
        };
        axios({
            method: "post",
            url: "https://dev-api.guidejuniors.com/",
            data: qs.stringify(reqData),
            header: { "content-type": "application/x-www-form-urlencoded" },
        }).then((res) => {
            let sample = res.data;
            if(sample!=null){
                setSelectGoup({
                listGroup: sample.XSCData,
            });
            }
        });
    }, [userDeviceKey, Type]);

    const [chnProfile, setChnProfile] = useState()
    const hiddenFileInput = React.useRef(null);
    const [imgPreview, setImgPreview] = useState(profile.profile.IMAGE_URL);
    useEffect(() => setImgPreview(profile.profile.IMAGE_URL), [profile.profile.IMAGE_URL]);

     const [idImgPreview, setIdImgPreview] = useState(profile.profile.ID_CARD_URL);
     useEffect(()=> setIdImgPreview(profile.profile.ID_IMAGE_URL), [profile.profile.ID_CARD_URL]);

    const handleChangeProfile = event => {
        hiddenFileInput.current.click();
    };
    const handleChange = event => {
        setChnProfile(event.target.files[0]);
        setImgPreview(URL.createObjectURL(event.target.files[0]))
    
    };
    const [uploadFileId, setUploadFileId] = useState()
    const onFileChange = (files) => {
        setUploadFileId(files);
    };

    // const [uploadId, setUploadId] = useState();

    // const handleSetIdCard = (e) => {
    //     setUploadId(e.target.files[0]);
    // };

    const getClassTitle = (myStudy) => {
        if (myStudy === "2") {
            return <div>{"Pursuing Year: " + profile.profile.CLASS_NAME}
            </div>
        } else if (myStudy === "3") {
            return <div>{"Experience in Year: " + profile.profile.CLASS_NAME}</div>
        } else {
            return <div>{"Class: " + "XII"}</div>
        }
    }

    const getStudyTitle = (myStudy) => {
        if (myStudy === "2") {
            return "college"
        } else if (myStudy === "3") {
            return "profession"
        } else {
            return "school"
        }
    }

    const getProfileImage = (imageUrl) => {
        if (!imageUrl) {
            return avatar;
        }
        if (imageUrl.includes("http")) {
            return imageUrl;
        }
        return "https://dev-api.guidejuniors.com/" + imageUrl;
    };

    const getIdImage = (idImageUrl) =>{
        return "https://dev-api.guidejuniors.com/"+ idImageUrl;
    }
    return (
        <div>
            <EditTopBar />
            <div className="row ps-2 w-100">
                <div className="col-lg-2">
                    <div style={{ fontSize: "0.9rem" }}>
                        <a href="/dashboard" className="text-decoration-none text-dark">Home</a> {">"} <a href="/user-profile" className="text-decoration-none text-dark">Profile</a> {">"} <b>Edit Info</b>
                        {/* Home {">"} Profile {">"} Edit Info */}
                    </div>
                   
                </div>
                <div className="col-lg-8">
                    <div className="pt-5">
                        <div className="d-flex">
                            <div>
                                <img
                                    src={request_img_1}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = avatar;
                                    }}
                                    className="rounded-circle"
                                    width="100"
                                    height="100"
                                    style={{ objectFit: "cover" }}
                                    alt="user_profile_image"
                                />
                            </div>
                            <div className="py-3 ps-3">
                                <div>
                                    <b>John</b>
                                </div>
                                {getClassTitle(profile.profile.TYPE)}
                                <div
                                    onClick={() => { handleChangeProfile() }}
                                    style={{ color: "#FBA93E", cursor: "pointer" }} >Change Photo</div>
                                <input align="center" type="file" accept="image/*"
                                    ref={hiddenFileInput}
                                    onChange={handleChange}
                                    style={{ display: 'none' }}
                                />
                            </div>
                        </div>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Row>
                                <Col md={4}>
                                    <Nav variant="tab" className="flex-column profile-tab">
                                        <Nav.Item onClick={handleGeneralClick}>
                                            <Nav.Link eventKey="first">
                                                <div className="mt-4"
                                                    style={
                                                        click
                                                            ? {
                                                                borderBottom: "4px solid #FBA93E",
                                                                width: "20%",
                                                                whiteSpace: "noWrap",
                                                                fontWeight: "700",
                                                                cursor: "pointer",
                                                                color: "black",
                                                            }
                                                            : {
                                                                cursor: "pointer",
                                                                color: "black",
                                                                width: "20%",
                                                                whiteSpace: "noWrap",
                                                            }
                                                    }
                                                >
                                                    General Information
                                                </div>
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item onClick={handleAdditionClick}>
                                            <Nav.Link eventKey="second">
                                                <div
                                                    style={
                                                        clickAddition
                                                            ? {
                                                                borderBottom: "4px solid #FBA93E",
                                                                width: "20%",
                                                                whiteSpace: "noWrap",
                                                                fontWeight: "700",
                                                                cursor: "pointer",
                                                                color: "black",
                                                            }
                                                            : {
                                                                cursor: "pointer",
                                                                color: "black",
                                                                width: "20%",
                                                                whiteSpace: "noWrap",
                                                            }
                                                    }
                                                >
                                                    Additional Information
                                                </div>
                                            </Nav.Link>
                                        </Nav.Item>
                                        {/* <Nav.Item onClick={handleSecurityClick}>
                                            <Nav.Link eventKey="third">
                                                <div
                                                    style={
                                                        clickSecurity
                                                            ? {
                                                                borderBottom: "4px solid #FBA93E",
                                                                width: "20%",
                                                                whiteSpace: "noWrap",
                                                                fontWeight: "800",
                                                                cursor: "pointer",
                                                                color: "black",
                                                            }
                                                            : {
                                                                cursor: "pointer",
                                                                color: "black",
                                                                width: "20%",
                                                                whiteSpace: "noWrap",
                                                            }
                                                    }
                                                >
                                                    Security Information
                                                </div>
                                            </Nav.Link>
                                        </Nav.Item> */}
                                        {/* <Nav.Item onClick={handleNotificationClick}>
                                            <Nav.Link eventKey="fourth">
                                                <div
                                                    style={
                                                        clickNoti
                                                            ? {
                                                                borderBottom: "4px solid #FBA93E",
                                                                width: "20%",
                                                                whiteSpace: "noWrap",
                                                                fontWeight: "800",
                                                                cursor: "pointer",
                                                                color: "black",
                                                            }
                                                            : {
                                                                cursor: "pointer",
                                                                color: "black",
                                                                width: "20%",
                                                                whiteSpace: "noWrap",
                                                            }
                                                    }
                                                >
                                                    Notifications
                                                </div>
                                            </Nav.Link>
                                        </Nav.Item> */}
                                        {/* <div className="py-3 " style={{ color: "#FBA93E" }}>
                                            Delete Account
                                        </div> */}
                                    </Nav>
                                </Col>

                                <Col md={5}>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="first">
                                            <form onSubmit={handleprofileDetail}>
                                                <div className="m-2">
                                                    <label>
                                                        <b>Name</b>
                                                    </label>
                                                    <input className="w-100 my-1 p-2"
                                                        style={{
                                                            border: "1px solid #DFE1E1",
                                                            background: "#FAFAFA",
                                                            fontWeight: "600",
                                                            fontSize: "0.8rem",
                                                            color: "#515C5A",
                                                        }}
                                                        color="#515C5A"
                                                        type="text"
                                                        maxLength={20}
                                                        onChange={handleSetName}
                                                        value="John"
                                                    />
                                                    <label>
                                                        <b>D.O.B</b>
                                                    </label>
                                                    <input
                                                        type="date"
                                                        className="w-100 my-1 p-2"
                                                        style={{
                                                            border: "1px solid #DFE1E1",
                                                            background: "#FAFAFA",
                                                            fontWeight: "600",
                                                            fontSize: "0.8rem",
                                                            color: "#515C5A",
                                                        }}
                                                        onChange={handlesetDob}
                                                        value="2002-01-24"
                                                        min="1921-01-10"
                                                        max="2021-01-01"
                                                    />
                                                </div>
                                                <div className="d-flex genders w-100 p-1">
                                                    <div>
                                                        <input
                                                            type="radio"
                                                            className="btn-check "
                                                            onChange={handleChangeGender}
                                                            value="MALE"
                                                            name="options"
                                                            id="male"
                                                            checked="true"
                                                        />
                                                        <label
                                                            className="btn"
                                                            style={
                                                                gender === "MALE"
                                                                    ? {
                                                                        border: "2px solid #DFE1E1",
                                                                        background: "#FDCD8F",
                                                                        borderRadius: "8px",
                                                                        padding: "12px",
                                                                        width: "110px",
                                                                        fontWeight: "600",
                                                                        fontSize: "0.8rem",
                                                                        color: "#515C5A",
                                                                        boxShadow: "none"
                                                                    }
                                                                    : {
                                                                        border: "2px solid #DFE1E1",
                                                                        borderRadius: "8px",
                                                                        padding: "12px",
                                                                        width: "110px",
                                                                        fontWeight: "600",
                                                                        fontSize: "0.8rem",
                                                                        color: "#515C5A",
                                                                        boxShadow: "none",
                                                                        background: "#FDCD8F"
                                                                    }
                                                            }
                                                            htmlFor="male"
                                                        >
                                                            Male <i className="fa fa-mars"></i>
                                                        </label>
                                                    </div>
                                                    <div className="mx-2">
                                                        <input
                                                            type="radio"
                                                            className="btn-check"
                                                            onChange={handleChangeGender}
                                                            value="FEMALE"
                                                            name="options"
                                                            id="female"
                                                            checked={gender === "FEMALE" ? true : false}
                                                        />
                                                        <label
                                                            className="btn"
                                                            style={
                                                                gender === "FEMALE"
                                                                    ? {
                                                                        border: "2px solid #DFE1E1",
                                                                        background: "#FDCD8F",
                                                                        borderRadius: "8px",
                                                                        padding: "12px",
                                                                        width: "110px",
                                                                        fontWeight: "600",
                                                                        fontSize: "0.8rem",
                                                                        color: "#515C5A",
                                                                        boxShadow: "none"
                                                                    }
                                                                    : {
                                                                        border: "2px solid #DFE1E1",
                                                                        borderRadius: "8px",
                                                                        padding: "12px",
                                                                        width: "110px",
                                                                        fontWeight: "600",
                                                                        fontSize: "0.8rem",
                                                                        color: "#515C5A",
                                                                        boxShadow: "none"
                                                                    }
                                                            }
                                                            htmlFor="female"
                                                        >
                                                            Female <i className="fa fa-venus"></i>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <input
                                                            type="radio"
                                                            className="btn-check"
                                                            onChange={handleChangeGender}
                                                            value="OTHER"
                                                            name="options"
                                                            id="other"
                                                            checked={gender === "OTHER" ? true : false}
                                                        />
                                                        <label
                                                            className="btn"
                                                            style={
                                                                gender === "OTHER"
                                                                    ? {
                                                                        border: "2px solid #DFE1E1",
                                                                        background: "#FDCD8F",
                                                                        borderRadius: "8px",
                                                                        padding: "12px",
                                                                        width: "110px",
                                                                        fontWeight: "600",
                                                                        fontSize: "0.8rem",
                                                                        color: "#515C5A",
                                                                        boxShadow: "none"
                                                                    }
                                                                    : {
                                                                        border: "2px solid #DFE1E1",
                                                                        width: "110px",
                                                                        borderRadius: "8px",
                                                                        padding: "12px",
                                                                        fontWeight: "600",
                                                                        fontSize: "0.8rem",
                                                                        color: "#515C5A",
                                                                        boxShadow: "none"

                                                                    }
                                                            }
                                                            htmlFor="other"
                                                        >
                                                            Others{" "}
                                                            <i
                                                                className="fa fa-neuter"
                                                                style={{ transform: "scaleY(-1)" }}
                                                            ></i>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="float-end  d-flex">

                                                    <a href="/user-profile/"><div className="btn my-2 mx-1" style={{ fontSize: "0.8rem", background: "#FBA93E", color: "#515C5A", fontWeight: "600", }}>cancel</div></a>
                                                    <button
                                                        className="btn my-2 mx-2"
                                                        type="submit"
                                                        name="form"
                                                        style={{
                                                            background: "#FBA93E",
                                                            fontSize: "0.8rem",
                                                            color: "#515C5A", fontWeight: "600",
                                                        }}
                                                    >
                                                        Save Changes
                                                    </button>
                                                </div>
                                            </form>

                                        </Tab.Pane>

                                        <Tab.Pane eventKey="second">
                                            <div className="d-flex w-100 my-2 p-2 mt-2"
                                                style={{
                                                    fontWeight: "600",
                                                    fontSize: "0.8rem",
                                                    color: "#515C5A",
                                                }}
                                            >
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        onChange={handleStudy}
                                                        value="school"
                                                        type="radio"
                                                        name="flexRadioDefault"
                                                        id="school"
                                                        checked={
                                                            (study === "school") ? true : false
                                                        }
                                                    />
                                                    <label className="form-check-label" htmlFor="school">
                                                        School
                                                    </label>
                                                </div>
                                                <div className="form-check mx-2">
                                                    <input
                                                        className="form-check-input"
                                                        onChange={handleStudy}
                                                        type="radio"
                                                        value="college"
                                                        name="flexRadioDefault"
                                                        id="college"
                                                        checked={study === "college" ? true : false}
                                                    />
                                                    <label className="form-check-label" htmlFor="college">
                                                        College
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        value="profession"
                                                        onChange={handleStudy}
                                                        type="radio"
                                                        name="flexRadioDefault"
                                                        id="profession"
                                                        checked={study === "profession" ? true : false}
                                                    />
                                                    <label className="form-check-label" htmlFor="profession">
                                                        Profession
                                                    </label>
                                                </div>
                                            </div>

                                            <form onSubmit={handleSubmit}>
                                                {
                                                    (study === "school") ? (
                                                        <>
                                                            <input
                                                                placeholder="Select School"
                                                                type="text"
                                                                className="w-100 mb-3 py-2 ps-3"
                                                                style={{
                                                                    border: "1px solid #DFE1E1",
                                                                    background: "#FAFAFA",
                                                                    fontWeight: "600",
                                                                    fontSize: "0.8rem",
                                                                    color: "#515C5A",
                                                                }}
                                                                value={selectScl.NAME}
                                                                onClick={() => handlesetscl()}
                                                                required
                                                            />
                                                            <SchoolSearchModal
                                                                show={schoolShow}
                                                                onHide={handleSchoolClose}
                                                                setSchool={setSelectScl}
                                                                ModelType={Type}
                                                                deviceKey={userDeviceKey}
                                                                required
                                                            />

                                                            <select
                                                                className="form-select form-select-lg mb-3 p-2"
                                                                aria-label=".form-select-lg example"
                                                                value={clsValueList}
                                                                onChange={handlesetCls}
                                                                style={{
                                                                    border: "1px solid #DFE1E1",
                                                                    // background: "#FAFAFA",
                                                                    fontWeight: "600",
                                                                    fontSize: "0.8rem",
                                                                    color: "#515C5A",
                                                                }}
                                                                required
                                                            >
                                                                <option value="" selected>Select Class</option>
                                                                {selectCls.listClass.map((data, index) => {
                                                                    return (
                                                                        <option key={index} value={data.ID}>{data.NAME}</option>
                                                                    );
                                                                })}
                                                            </select>
                                                            {
                                                                clsValueList === "11" || clsValueList === "12" ? <>
                                                                    < select
                                                                        className="form-select form-select-lg mb-3 p-2"
                                                                        aria-label=".form-select-lg example"
                                                                        value={clsSelectStream}
                                                                        onChange={handlesetStream}
                                                                        style={{
                                                                            border: "1px solid #DFE1E1",
                                                                            // background: "#FAFAFA",
                                                                            fontWeight: "600",
                                                                            fontSize: "0.8rem",
                                                                            color: "#515C5A",
                                                                        }}
                                                                        required
                                                                    >
                                                                        <option value="" selected>select Stream</option>
                                                                        {selectStream.listStream.map((data, index) => {
                                                                            return (
                                                                                <option key={index} value={data.ID}>{data.NAME}</option>
                                                                            );
                                                                        })}
                                                                    </select>

                                                                    <select
                                                                        className="form-select form-select-lg mb-2 p-2"
                                                                        aria-label=".form-select-lg example"
                                                                        value={clsSelectGroup}
                                                                        onChange={handlesetGroup}
                                                                        style={{
                                                                            border: "1px solid #DFE1E1",
                                                                            // background: "#FAFAFA",
                                                                            fontWeight: "600",
                                                                            fontSize: "0.8rem",
                                                                            color: "#515C5A",
                                                                        }}
                                                                        required
                                                                    >
                                                                        <option value="" selected>Select Group</option>
                                                                        {selectGroup.listGroup.map((data, index) => {
                                                                            return (
                                                                                <option key={index} value={data.ID}>{data.NAME}</option>
                                                                            );
                                                                        })}
                                                                    </select></> : ""
                                                            }
                                                        </>
                                                    ) : (
                                                        ""
                                                    )}

                                                {
                                                    study === "college" ? (
                                                        <>
                                                            <input
                                                                placeholder="Select College"
                                                                type="text"
                                                                className="w-100 mb-3 py-2 ps-3"
                                                                style={{
                                                                    border: "1px solid #DFE1E1",
                                                                    background: "#FAFAFA",
                                                                    fontWeight: "600",
                                                                    fontSize: "0.8rem",
                                                                    color: "#515C5A",
                                                                }}
                                                                value={selectClg.NAME}
                                                                onClick={() => handlesetscl()}
                                                                required
                                                            />
                                                            <SchoolSearchModal
                                                                show={schoolShow}
                                                                onHide={handleSchoolClose}
                                                                setSchool={setSelectClg}
                                                                ModelType={Type}
                                                            />
                                                            <select
                                                                className="form-select form-select-lg mb-3 p-2"
                                                                aria-label=".form-select-lg example"
                                                                style={{
                                                                    border: "1px solid #DFE1E1",
                                                                    // background: "#FAFAFA",
                                                                    fontWeight: "600",
                                                                    fontSize: "0.8rem",
                                                                    color: "#515C5A",
                                                                }}
                                                                value={ClgDegree}
                                                                onChange={handlesetdegree}
                                                                required
                                                            >
                                                                <option value="" selected>Select Degree</option>
                                                                {selectStream.listStream.map((data, index) => {
                                                                    return (
                                                                        <option key={index} value={data.ID}>{data.NAME}</option>
                                                                    );
                                                                })}
                                                            </select>

                                                            <select
                                                                className="form-select form-select-lg mb-3 p-2"
                                                                aria-label=".form-select-lg example"
                                                                style={{
                                                                    border: "1px solid #DFE1E1",
                                                                    // background: "#FAFAFA",
                                                                    fontWeight: "600",
                                                                    fontSize: "0.8rem",
                                                                    color: "#515C5A",
                                                                }}
                                                                value={clgPursuing}
                                                                onChange={handlesetPursuing}
                                                                required
                                                            >
                                                                <option value="" selected>Select Pursuing Year</option>
                                                                {selectCls.listClass.map((data, index) => {
                                                                    return (
                                                                        <option key={index} value={data.ID}>{data.NAME}</option>
                                                                    );
                                                                })}
                                                            </select>
                                                            <select
                                                                className="form-select form-select-lg mb-2 p-2"
                                                                aria-label=".form-select-lg example"
                                                                style={{
                                                                    border: "1px solid #DFE1E1",
                                                                    // background: "#FAFAFA",
                                                                    fontWeight: "600",
                                                                    fontSize: "0.8rem",
                                                                    color: "#515C5A",
                                                                }}
                                                                value={clgStream}
                                                                onChange={handlesetClgStream}
                                                                required
                                                            >
                                                                <option value="" selected>Select Stream</option>
                                                                {selectGroup.listGroup.map((data, index) => {
                                                                    return (
                                                                        <option key={index} value={data.ID}>{data.NAME}</option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </>
                                                    ) : (
                                                        ""
                                                    )}

                                                {
                                                    study === "profession" ? (
                                                        <>

                                                            <input placeholder='Select Organization' type="text" className="w-100 mb-3 py-2 ps-3"
                                                                style={{
                                                                    border: "1px solid #DFE1E1",
                                                                    // background: "#FAFAFA",
                                                                    fontWeight: "600",
                                                                    fontSize: "0.8rem",
                                                                    color: "#515C5A",
                                                                }}
                                                                value={selectPro.NAME} onClick={() => handlesetscl()} required />

                                                            <SchoolSearchModal
                                                                show={schoolShow}
                                                                onHide={handleSchoolClose}
                                                                setSchool={setSelectPro}
                                                                ModelType={Type}
                                                            />

                                                            <select className="form-select form-select-lg mb-3" style={{
                                                                border: "1px solid #DFE1E1",
                                                                // background: "#FAFAFA",
                                                                fontWeight: "600",
                                                                fontSize: "0.8rem",
                                                                color: "#515C5A",
                                                            }} required aria-label=".form-select-lg example" value={proCategory} onChange={handlesetCategory}>
                                                                <option value="" selected>Select Category</option>

                                                                {
                                                                    selectStream.listStream.map((data, index) => {
                                                                        return (
                                                                            <option key={index} value={data.ID}
                                                                            >{data.NAME}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                            <select className="form-select form-select-lg mb-3 p-2"
                                                                style={{
                                                                    border: "1px solid #DFE1E1",
                                                                    // background: "#FAFAFA",
                                                                    fontWeight: "600",
                                                                    fontSize: "0.8rem",
                                                                    color: "#515C5A",
                                                                }}
                                                                aria-label=".form-select-lg example" value={proJobRole} onChange={handlesetJobRole} required>

                                                                <option value="" selected>Select Job Role</option>
                                                                {
                                                                    selectGroup.listGroup.map((data, index) => {
                                                                        return (
                                                                            <option key={index} value={data.ID}>{data.NAME}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>

                                                            <select
                                                                className="form-select form-select-lg mb-2 p-2"
                                                                aria-label=".form-select-lg example"
                                                                style={{
                                                                    border: "1px solid #DFE1E1",
                                                                    // background: "#FAFAFA",
                                                                    fontWeight: "600",
                                                                    fontSize: "0.8rem",
                                                                    color: "#515C5A",

                                                                }}
                                                                required
                                                                value={proExper}
                                                                onChange={handlesetExper}
                                                            >
                                                                <option value="" selected>
                                                                    Select Experience in Year
                                                                </option>
                                                                {selectCls.listClass.map((data, index) => {
                                                                    return (
                                                                        <option key={index} value={data.ID}>{data.NAME}</option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </>
                                                    ) : ("")
                                                }
                                                <div className="p-2">
                                                    <label className="mb-1" style={{ color: "#515C5A", fontSize: "0.8rem", fontWeight: "600", }}>Upload Id card image</label>
                                                    <DropFileInput onFileChange={(files) => onFileChange(files)} urlImage={uploadFileId} />
                                                    <img  src={getIdImage(idImgPreview)} alt="id_card_img"></img>
                                                    {/* <input onChange={handleSetIdCard} type="file" /> */}
                                                </div>
                                                <div className="float-end d-flex">

                                                    <a href="/user-profile/"><div className="btn my-2 mx-1" style={{ fontSize: "0.8rem", background: "#FBA93E", color: "#515C5A", fontWeight: "600", }}>cancel</div></a>
                                                    <button
                                                        type="submit"
                                                        className="btn my-2 mx-2"
                                                        name="form"
                                                        style={{
                                                            background: "#FBA93E",
                                                            fontSize: "0.8rem",
                                                            color: "#515C5A", fontWeight: "600",
                                                        }}
                                                    >
                                                        Save Changes
                                                    </button>
                                                </div>
                                            </form>
                                        </Tab.Pane>
                                        {/* <Tab.Pane eventKey="third"> */}
                                        {/*  <div>
                                                <b>Change Password</b>
                                            </div>
                                            <div className="p-2">
                                                <div className="py-2">
                                                    <input
                                                        className="w-100 p-2"
                                                        placeholder="Old Password"
                                                    />
                                                </div>
                                                <div className="py-2">
                                                    <input
                                                        className="w-100 p-2"
                                                        placeholder="New Password"
                                                    />
                                                </div>
                                                <div>Minimum 6 Characters</div>
                                                <div className="float-end  d-flex">
                                                    <div
                                                        className="btn mx-2 disabled"
                                                        style={{ background: "#FBA93E" }}
                                                    >
                                                        Change
                                                    </div>
                                                </div>
                                            </div>*/}
                                        {/* </Tab.Pane> */}
                                        {/* <Tab.Pane eventKey="fourth">
                                            <div>
                                                <div style={{ fontSize: "1.2rem" }}>
                                                    <b>Notify me when</b>
                                                </div>
                                                <div className="py-1">
                                                    <label>
                                                        <input
                                                            style={{ accentColor: "#FBA93E" }}
                                                            type="checkbox"
                                                            defaultChecked
                                                        />{" "}
                                                        Someone ask me to be a mentor
                                                    </label>
                                                </div>
                                                <div className="py-1">
                                                    <label>
                                                        <input
                                                            style={{ accentColor: "#FBA93E" }}
                                                            type="checkbox"
                                                        />{" "}
                                                        Someone Requested my Notes
                                                    </label>
                                                </div>
                                                <div className="py-1">
                                                    <label>
                                                        <input
                                                            style={{ accentColor: "#FBA93E" }}
                                                            type="checkbox"
                                                        />{" "}
                                                        Someone Requested my Books
                                                    </label>
                                                </div>
                                                <div className="py-1">
                                                    <label>
                                                        <input
                                                            style={{ accentColor: "#FBA93E" }}
                                                            type="checkbox"
                                                            defaultChecked
                                                        />{" "}
                                                        When I received a Message
                                                    </label>
                                                </div>
                                                <div className="py-1">
                                                    <label>
                                                        <input
                                                            style={{ accentColor: "#FBA93E" }}
                                                            type="checkbox"
                                                            defaultChecked
                                                        />{" "}
                                                        When I received a Connection Request
                                                    </label>
                                                </div>
                                                <div className="py-1">
                                                    <label>
                                                        <input
                                                            style={{ accentColor: "#FBA93E" }}
                                                            type="checkbox"
                                                        />{" "}
                                                        Someone accepts my requests
                                                    </label>
                                                </div>
                                                <div className="py-1">
                                                    <label>
                                                        <input
                                                            style={{ accentColor: "#FBA93E" }}
                                                            type="checkbox"
                                                        />{" "}
                                                        New Update Launches
                                                    </label>
                                                </div>
                                                <div className="float-end  p-4">
                                                    <div
                                                        className="btn mx-2 disabled"
                                                        style={{ background: "#FBA93E" }}
                                                    >
                                                        Save Changes
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab.Pane> */}
                                    </Tab.Content>
                                </Col>
                                <Col md={3}></Col>
                            </Row>
                        </Tab.Container>
                    </div>
                </div>
                <div className="col-lg-2"></div>
            </div>
        </div >
    );
}
