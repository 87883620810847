import React from "react";
import "../Styles/SideBar.css";
import gj_full_logo from "../images/gj_full_logo.png";
import home_icon from "../images/home_icon.png";
import connection_icon from "../images/connection_icon.png";
// import add_mentor_icon from "../images/add_mentor_icon.png";
import notes_icon from "../images/notes_icon.png";
import message_icon from "../images/message_icon.png";
import { useLocation } from "react-router-dom";
function Sidemenu() {


  const path = useLocation();
  let pathName = path.pathname;
  return (
    <div className="col-lg-2 px-0 sidebar_height" >
      <div className="side_menus ms-4">
        <img
          align="center"
          src={gj_full_logo}
          alt="gj_full_logo"
          className="mt-4"
          style={{ width: "190px" }}
        ></img>
        <div className="mt-3 text-decoration-none">
          <ul className="py-2 list-unstyled" style={{ fontSize: "0.9rem" }}>
            <a href="/dashboard">
              <li
                className="ps-2 py-3 "
                style={
                  pathName == "/dashboard"
                    ? { borderRight: "4px solid #245D51" }
                    : {}
                }
              >
                {" "}
                <img src={home_icon} className=" me-2" width="8%"></img>
                <span
                  style={{
                    fontWeight: "600",
                    fontSize: "0.8rem",
                    lineHeight: "16px",
                  }}
                >
                  {" "}
                  Home
                </span>
              </li>
            </a>
            {/* <a>
                <li className="py-3">
                  <img
                    src={add_mentor_icon}
                    className="py-2 me-2"
                    width="8%"
                  ></img>
                  Mentors Connections
                </li>
              </a> */}

            <a href="/notes-materials">
              <li
                className="py-2 ps-2"
                style={
                  pathName == "/notes-materials"
                    ? { borderRight: "4px solid #245D51" }
                    : {}
                }
              >
                <img src={notes_icon} width="8%" className="py-2 me-2"></img>
                <span
                  style={{
                    fontWeight: "600",
                    fontSize: "0.8rem",
                    lineHeight: "16px",
                  }}
                >
                  {" "}
                  Notes & Materials
                </span>
              </li>
            </a>

            <a href="/connections">
              <li
                className="py-2 ps-2"
                style={
                  pathName == "/connections"
                    ? { borderRight: "4px solid #245D51" }
                    : {}
                }
              >
                <img
                  src={connection_icon}
                  width="8%"
                  className="py-2 me-2"
                ></img>
                <span
                  style={{
                    fontWeight: "600",
                    fontSize: "0.8rem",
                    lineHeight: "16px",
                  }}
                >
                  {" "}
                  Connections
                </span>
              </li>
            </a>

            <a href="/messages">
              <li
                className="py-1 ps-2"
                style={
                  pathName == "/messages"
                    ? { borderRight: "4px solid #245D51" }
                    : {}
                }
              >
                <img src={message_icon} width="8%" className="py-2 me-2"></img>
                <span
                  style={{
                    fontWeight: "600",
                    fontSize: "0.8rem",
                    lineHeight: "16px",
                  }}
                >
                  {" "}
                  Messages
                </span>
              </li>
            </a>
          </ul>
        </div>
      </div>
      <div className="bottom_menu">
        <div
          className="d-flex fixed-bottom  bg-light px-2"
          style={{ justifyContent: "space-between", fontSize: "0.7rem",boxShadow:"-2px -2px 8px 0.5px rgba(0,0,0,0.2)" }}
        >
          <div
            align="center"
            className="pt-2"
            style={
              pathName == "/dashboard" ? { borderTop: "4px solid #245D51" } : {}
            }
          >
            <a href="/dashboard" className="text-decoration-none text-dark">
              <img src={home_icon} />
              <div>Home</div>
            </a>
          </div>
          <div
            align="center"
            className="pt-2"
            style={
              pathName == "/connections"
                ? { borderTop: "4px solid #245D51" }
                : {}
            }
          >
            <a href="connections" className="text-decoration-none text-dark">
              <img src={connection_icon} />
              <div>Connections</div>
            </a>
          </div>
          <div
            align="center"
            className="pt-2"
            style={
              pathName == "/notes-materials"
                ? { borderTop: "4px solid #245D51" }
                : {}
            }
          >
            <a
              href="/notes-materials"
              className="text-decoration-none text-dark"
            >
              <img src={notes_icon} />
              <div>Notes</div>
            </a>
          </div>
          <div
            align="center"
            className="pt-2"
            style={
              pathName == "/messages" ? { borderTop: "4px solid #245D51" } : {}
            }
          >
            <a href="/messages" className="text-decoration-none text-dark">
              <img src={message_icon} />
              <div>Messages</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Sidemenu;
