import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import gj_side_logo from "../../images/gj_side_logo.png";
import ProfilePageOne from "./ProfilePageOne";
import ProfilePageTwo from "./ProfilePageTwo";
import qs from "qs";
import { toast, ToastContainer } from 'react-toastify';
import "../../Styles/CreateProfile.css"
import {  logout,auth } from "../Firebase";
import socket from "socket.io-client/lib/socket";
import { useAuthState } from "react-firebase-hooks/auth";
function Create_Profile() {
  const [user, loading, error] = useAuthState(auth);
  useEffect(() => {
    if (loading) return;
    if (!user) {
      const reqData = {
        mod: "AA",
        actionType: "logout-user",
        subAction: JSON.stringify({
          DEVICE_KEY: localStorage.getItem("DEVICE_KEY"),
          PLATFORM: "WEB",
        }),
      };

      axios({
        method: "post",
        url: "https://dev-api.guidejuniors.com/",
        data: qs.stringify(reqData),
        header: { "content-type": "application/x-www-form-urlencoded" },
      }).then((res) => {
        localStorage.setItem("DEVICE_KEY", "");
        localStorage.setItem("USER_ID", "");

        //console.log("socket", socket);
        if (socket.connected) {
          socket.disconnect();
        }

        return navigate("/sign-in");
      });
    }
    //fetchUserName();
  }, [user, loading]);
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [formData, setFormData] = useState({
    name: "",
    dob: "",
    gender: "MALE",
    study: "1",
    school: "",
    classes: "",
    higherGroup: "",
    higherSubjects: "",
    profile: [],
    college: "",
    pursueYears: "",
    degree: "",
    group: "",
    category: "",
    company: "",
    stream: "",
    jobRole: "",
    experience: "",
    idCard: [],
    // validationCheck: false
  });
  const FormTitles = ["ProfilePageOne", "ProfilePageTwo"];
  const device_key = localStorage.getItem("DEVICE_KEY");

  const EducationalData =
    page === FormTitles.length - 1
      ? formData.study === "1"
        ? {
          DEVICE_KEY: device_key,
          FULL_NAME: formData.name,
          DOB: formData.dob,
          GENDER: formData.gender,
          SCHOOL_ID: formData.school,
          CLASS_ID: formData.classes,
          STREAM_ID: formData.higherSubjects,
          GROUP_ID: formData.higherGroup,
          IMAGE_URL: "",
        }
        : formData.study === "2"
          ? {
            DEVICE_KEY: device_key,
            FULL_NAME: formData.name,
            DOB: formData.dob,
            GENDER: formData.gender,
            SCHOOL_ID: formData.college,
            CLASS_ID: formData.pursueYears,
            STREAM_ID: formData.degree,
            GROUP_ID: formData.group,
            IMAGE_URL: "",
          }
          : {
            DEVICE_KEY: device_key,
            FULL_NAME: formData.name,
            DOB: formData.dob,
            GENDER: formData.gender,
            SCHOOL_ID: formData.company,
            CLASS_ID: formData.experience,
            STREAM_ID: formData.category,
            GROUP_ID: formData.jobRole,
            IMAGE_URL: "",
          }
      : {};

  // console.log("idcard",formData.profile );
  const handleSub = () => {

    const profileData = new FormData();
    profileData.append("mod", "Profile");
    profileData.append("actionType", "update-user-details");
    profileData.append("subAction", JSON.stringify(EducationalData));
    profileData.append("PROFILE_IMAGE", formData.profile);
    profileData.append("IDCARD_IMAGE", formData.idCard);
    // const reqData = {
    //   mod: "AA",
    //   actionType: "user-sign-up",
    //   subAction: JSON.stringify(EducationalData),
    //   PROFILE_IMAGE: formData.profile,
    // };
    // console.log(formData.profile);
    axios({
      method: "post",
      url: "https://dev-api.guidejuniors.com/",
      data: profileData,
      header: { "content-type": "multipart/form-data" },
    }).then((res) => {
      const sample = res.data;
      console.log(sample);
      if (res.data.XSCData !== null) {

        setTimeout(function () {
          // navigate.push('/dashboard');

          navigate('/dashboard', { replace: true })
        }, 3000);

        toast.success("Create Profile Successfully", {
          position: toast.POSITION.TOP_RIGHT,

          // className: 'toastM'
        });

      }
      else {
        toast.error("Please fill empty fields", {
          position: toast.POSITION.TOP_RIGHT,
        });

      }
    });
  }
  const PageDisplay = () => {
    if (page === 0) {
      return <ProfilePageOne formData={formData} setFormData={setFormData} />;
    } else {
      return <ProfilePageTwo formData={formData} setFormData={setFormData} />;
    }
  };

  return (
    <div>
      <div
        className="container-fluid"
        style={{
          backgroundColor: "#E9EFEE",
          backgroundSize: "cover",
          height: "100vh",
        }}
      >
        <div className="row ">
          <div className="col-lg-4 mt-3 ">
            <div className="home_btn" onClick={logout} style={{cursor:"pointer"}}>
              <i className="fa fa-long-arrow-left "></i>
              <span className="ps-2" style={{ fontSize: "1.3rem" }}>
                Home
              </span>
            </div>
            <div className="app_logo">
              {" "}
              <img
                src={gj_side_logo}
                alt="logo"
                className="mt-2"
                style={{ width: "10%" }}
              ></img>
            </div>
          </div>
          <div
            className="col-lg-4 mt-5 create_pro_outline"
            style={{ background: "white", borderRadius: "10%" }}
          >
            <div
              className="progressbar mt-5"
              style={{
                height: "5px",
                backgroundColor: "white",

                borderRadius: "30px",
              }}
            >
              <div
                style={{
                  width: page === 0 ? "50%" : page == 1 ? "100%" : "100%",
                }}
              ></div>
            </div>
            <div>
              <div style={{ fontSize: "1rem" }}>
                <div>{PageDisplay()}</div>

                <div className="float-end my-2 p-2 ">
                  {page === 0 ? (
                    <></>
                  ) : (
                    <button
                      className="btn w-50 mt-3"
                      disabled={page === 0}
                      onClick={() => {
                        setPage((currPage) => currPage - 1);
                      }}
                      style={{
                        fontWeight: 700,
                        fontSize: "0.8rem",
                        boxShadow: "none"
                      }}
                    >
                      Back
                    </button>
                  )}

                  <button
                    form='my-form'
                    className={
                      page === 0
                        ? "w-100 btn py-3 px-4 mt-3"
                        : "w-50 py-3 px-4 pe-5 btn mt-3"
                    }
                    style={
                      page === 0
                        ? {
                          marginRight: "-20%",
                          backgroundColor: "#FFAA3B",
                          fontSize: "0.8rem",
                          fontWeight: 700,
                          boxShadow: "none"
                        }
                        : {
                          border: "none",
                          backgroundColor: "#FFAA3B",
                          fontSize: "0.8rem",
                          fontWeight: 700,
                          boxShadow: "none"
                        }
                    }
                    onClick={() => {
                      if (page === FormTitles.length - 1) {
                        // navigate("/dashboard");
                        handleSub();
                        // console.log(formData);
                      }
                      else {
                        setPage((currPage) => currPage + 1);
                      }
                    }}
                  >
                    {page === FormTitles.length - 1 ? "Submit" : "Next"}
                  </button>
                </div>
                {/* <button  form='my-form' type="submit">ddd</button> */}
              </div>
            </div>
          </div>
          <div className="col-lg-4"></div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
export default Create_Profile;
