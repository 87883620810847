import React from "react";
import avatar from "../images/avatar.png";
import { useState, useEffect } from "react";
import qs from "qs";
import axios from "axios";
import "../Styles/PopularMentors.css";
import carousal_img1 from "../images/carousal_img1.png"
import carousal_img2 from "../images/carousal_img2.png"
import carousal_img3 from "../images/carousal_img3.png"
import carousal_img4 from "../images/carousal_img4.png"
import carousal_img5 from "../images/carousal_img5.png"
import carousal_img6 from "../images/carousal_img6.png"
function PopularMentors() {
  const userDeviceKey = localStorage.getItem("DEVICE_KEY");
  const [mentorList, setMentorList] = useState([]);

  useEffect(() => {
    const reqData = {
      mod: "Mentor",
      actionType: "get-subjectwise-mentors",
      subAction: JSON.stringify({
        DEVICE_KEY: userDeviceKey,
      }),
    };
    axios({
      method: "post",
      url: "https://dev-api.guidejuniors.com/",
      data: qs.stringify(reqData),
      header: { "content-type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      let resData = res.data;
      setMentorList(resData.XSCData.LIST);
    });
  }, []);
const mentorWidth=window.innerWidth;
const mentor_count=(mentorWidth<767)?4:6;
const Mentor=[
  {
    IMAGE_URL:carousal_img1,
    MENTOR_NAME:"Teena",
    MENTOR_SUBJECT:"Physics"
    },
  {
    IMAGE_URL:carousal_img2,
    MENTOR_NAME:"Fernandez",
    MENTOR_SUBJECT:"Mathematics"
    },
  {
    IMAGE_URL:carousal_img3,
    MENTOR_NAME:"Micheal",
    MENTOR_SUBJECT:"Chemistry"
    },
  {
    IMAGE_URL:carousal_img4,
    MENTOR_NAME:"John",
    MENTOR_SUBJECT:"Biology"
    },
  {
    IMAGE_URL:carousal_img5,
    MENTOR_NAME:"Joseph",
    MENTOR_SUBJECT:"English"
    },
  {
    IMAGE_URL:carousal_img6,
    MENTOR_NAME:"Erik",
    MENTOR_SUBJECT:"Commerce"
    },


]
  return (
    <div className="p-3">
      {" "}
      <div>
        {" "}
        <b style={{ fontWeight: "800", fontSize: "1rem" }}>
          Popular Mentors for you
        </b>

        {Mentor.length === 0 ? <p className="text-center mt-5">No Data Found</p> :
          <div className="mt-3 row p-1">
            {Mentor.slice(0, mentor_count).map((data,index) => {
              return (
                <div className="col-md-6 " key={index}>
                  <div
                    className="p-2 my-1 d-flex"
                    style={{
                      backgroundColor: "#F5F6F6",
                      borderRadius: "8px",
                      fontSize: "0.7rem",
                    }}
                  >
                    <div>
                      <img
                        src={
                          data.IMAGE_URL 
                        }
                        style={{ borderRadius: "50%",objectFit:"cover"}}
                        width="40"
                        height="40"
                        alt=""
                      />
                    </div>
                    <div className="ps-2  ">
                      <div
                        // className="mentors_name "
                        style={{ fontSize: "0.7rem", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "80px" }}
                      >
                        <b>  {data.MENTOR_NAME}</b>
                      </div>
                      <div style={{ fontWeight: "500",overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "80px" }}>{data.MENTOR_SUBJECT}</div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        }
      </div>
    </div>
  );
}
export default PopularMentors;
