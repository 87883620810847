import React, { useEffect, useState } from "react";
import profile_img from "../../images/profile_img.png";
import Webcam from "react-webcam";
import edit_img from "../../images/upload_icon_image.png";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';

const videoConstraints = {
  facingMode: "user",
};

function ProfilePictureModal(props) {
  const hiddenFileInput = React.useRef(null);
  const handleClick = event => {
    hiddenFileInput.current.click();
  }
  const handleChange = event => {
    // console.log(event.target.files[0]);
    props.setImageSrc(event.target.files[0]);
    props.handleEditShow(URL.createObjectURL(event.target.files[0]));
  }
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: "1.2rem", fontWeight: "600" }}>
          Profile Picture
        </Modal.Title>
      </Modal.Header>
      <Modal.Body align="center">
        <div>
          <Button
            onClick={handleClick}
            style={{ background: "#FBA93E", border: "none", boxShadow: "none", color: "black", fontWeight: "600", fontSize: "0.8rem", whiteSpace: "nowrap" }}
          >
            Upload Photo
          </Button>
          <input type="file"
            ref={hiddenFileInput}
            onChange={handleChange}
            accept="image/jpeg,image/png" style={{ display: "none" }} />

        </div>
        <div className="mt-2">
          {/* <Button
            onClick={props.onCapture}
            style={{ background: "white", color: "black", border: "none" ,fontSize:"0.8rem",whiteSpace:"nowrap"}}
          >
            Use camera
          </Button> */}
        </div>
      </Modal.Body>
    </Modal>
  );
}
function SaveDetailsModal(props) {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: "1.2rem", fontWeight: "600" }}>
          Save Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body align="center" style={{ fontSize: "0.8rem" }}>
        Are you sure you want to exit ?
        {/* <h4>Centered Modal</h4>
        <p>
          Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
          dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
          consectetur ac, vestibulum at eros.
        </p> */}
      </Modal.Body>
      <Modal.Footer style={{ border: "none" }}>
        <div className="d-flex">
          <Button
            onClick={props.onHide}
            style={{ background: "white", color: "black", border: "none", fontSize: "0.8rem" }}
          >
            Exit
          </Button>
          <Button
            onClick={props.handleImageSave}
            style={{ background: "#FBA93E", border: "none", color: "black", whiteSpace: "nowrap", fontSize: "0.8rem", fontWeight: "600" }}
          >
            Save & Exit
          </Button>
        </div>

      </Modal.Footer>
    </Modal>
  );
}
function EditProfileModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: "1.2rem", fontWeight: "600" }}>
          Edit Profile
        </Modal.Title>
      </Modal.Header>
      <Modal.Body align="center">
        <img src={props.editImageSrc} className="img-fluid" style={{ objectFit: "cover", backgroundSize: "cover", height: "50vh" }} />
      </Modal.Body>
      <Modal.Footer style={{ border: "none" }}>
        <Button
          onClick={props.handleSave}
          style={{ background: "#FBA93E", border: "none", color: "black", fontWeight: "600", fontSize: "0.8rem" }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
function ProfileCaptureModal(props) {
  const handleCapture = () => {
    props.handleClick();
    props.onHide();
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: "1.2rem", fontWeight: "600" }}>
          Profile Picture
        </Modal.Title>
      </Modal.Header>
      <Modal.Body align="center">
        <Webcam
          audio={false}
          style={{ width: "100%", backgroundSize: "cover", height: "50vh" }}
          ref={props.webcamRef}
          screenshotFormat="imaege/jpeg"
          videoConstraints={props.videoConstraints}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex">
          <Button
            onClick={props.onHide}
            style={{ background: "white", color: "black", border: "none", fontSize: "0.8rem" }}
          >
            close
          </Button>
          <Button
            onClick={handleCapture}
            style={{ background: "#FBA93E", border: "none", color: "black", fontWeight: "600", fontSize: "0.8rem" }}
          >
            Capture
          </Button>
        </div>

      </Modal.Footer>
    </Modal>
  );
}
function ProfilePageOne({ formData, setFormData }) {

  const [isValid, setIsValid] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [isToucheddob, setIsToucheddob] = useState(false);


  // useEffect(()=>{
  //   setFormData({ ...formData, validationCheck: isTouched && isToucheddob === true ? true:false });

  // },[isTouched,isTouched])

  const [gender, setGender] = useState(formData.gender);
  const [study, setStudy] = useState("1");
  const [show, setshow] = useState(false);
  const [uploadshow, setUploadshow] = useState(false);
  const [saveShow, setSaveShow] = useState(false);
  const [captureshow, setCaptureShow] = useState(false);
  const [picture, setPicture] = useState("");
  const [imageSrc, setImageSrc] = useState([]);
  const webcamRef = React.useRef(null);
  const capture = React.useCallback(() => {
    const pictureSrc = webcamRef.current.getScreenshot();
    setPicture(pictureSrc);
    const file = new File([
      new Blob([pictureSrc])
    ], "profile_image" + Math.random(Math.round(100, 999)), { type: "image/jpeg" });
    setFormData({ ...formData, profile: file });
    // console.log("profile_page_one",file);
    // console.log(pictureSrc);
  });
  // console.log(imageSrc);
  const handleCapture = () => {
    setCaptureShow(true);
  };
  const handleChange = (e) => {
    setGender(e.target.value);
    setFormData({ ...formData, gender: e.target.value });
  };
  const handleEdit = (e) => {
    // e.preventDefault();
    setshow(true);
  };
  const handleSave = (e) => {
    // e.preventDefault();
    setSaveShow(true);
  };
  const handleUpload = (e) => {
    // e.preventDefault();
    setUploadshow(true);
  };
  const handleClose = (e) => {
    setshow(false);
    setUploadshow(false);
    setSaveShow(false);
    setCaptureShow(false);
  };
  const handleStudy = (e) => {
    setStudy(e.target.value);
    setFormData({ ...formData, study: study });
  };
  const handleProfilePicture = (e) => {
    setPicture(e.target.value);
  };
  const handleClick = () => {
    capture();
  };
  // const hiddenFileInput=React.useRef(null);

  // const handleUploadPicture=(e)=>{
  //   setFormData({...formData,profile:e.target.files[0]})
  //   console.log(e.target.files[0]);
  // }
  const [editImageSrc, setEditImageSrc] = useState("")
  const handleEditShow = (image) => {
    setshow(true);
    // console.log(image);
    setEditImageSrc(image)
  }
  const handleImageSave = () => {
    // useEffect(()=>{
    setFormData({ ...formData, profile: imageSrc })
    // },[imageSrc]);

    handleClose();
  }
  return (
    <div className="px-md-5">
      {/* {show ?  */}
      <EditProfileModal
        show={show}
        onHide={handleClose}
        handleSave={handleSave}
        editImageSrc={editImageSrc}
      />
      <SaveDetailsModal show={saveShow} onHide={handleClose} handleImageSave={handleImageSave} />
      <ProfilePictureModal
        show={uploadshow}
        onHide={handleClose}
        onCapture={handleCapture}
        handleEdit={handleEdit}
        setImageSrc={setImageSrc}
        handleEditShow={handleEditShow}
      />
      <ProfileCaptureModal
        show={captureshow}
        onHide={handleClose}
        webcamRef={webcamRef}
        videoConstraints={videoConstraints}
        handleClick={handleClick}
      />
      <div align="center">
        {editImageSrc == "" ? (
          <img
            src={edit_img}
            alt="profile_img"
            width="100%"
            height="100%"
            style={{
              width: "25%",
              borderRadius: "100%",
              width: "70px",
              height: "70px",
              objectFit: "cover",
              backgroundPosition: "center",
              backgroundSize: "cover"
            }}
            onClick={handleUpload}
            onChange={(event) =>
              setFormData({
                ...formData,
                idCard: URL.createObjectURL(event.target.files[0]),
              })
            }
          />
        ) : (
          <img
            src={editImageSrc}
            alt="profile_img"
            width="100%"
            height="100%"
            style={{
              width: "25%",
              borderRadius: "100%",
              width: "70px",
              height: "70px",
              objectFit: "cover",
            }}
            onClick={handleUpload}
          // onChange={(event) =>
          //   setFormData({
          //     ...formData,
          //     idCard: URL.createObjectURL(event.target.files[0]),
          //   })
          // }
          ></img>
        )}
      </div>
      <Form id="my-form">
        <div>
          <Form.Control
            type="text"
            id="input_font"
            className="w-100 my-2 p-2 mt-4"
            style={{
              border: "1px solid #DFE1E1",
              background: "#FAFAFA",
              fontWeight: 600,
              fontSize: "0.8rem",
            }}
            maxLength={20}
            placeholder="Your Name"
            value={formData.name}
            isInvalid={!isValid && isTouched}
            isValid={isValid}

            onChange={(event) => {
              setIsTouched(true);
              const lettersOnly = /^[A-Za-z\s]*$/;
              if (lettersOnly.test(event.target.value)) {
                setFormData({ ...formData, name: event.target.value.trimStart() })
                setIsValid(true);
              } else {
                setIsValid(false);
              }


            }


            }
            required />
          <Form.Control.Feedback type="invalid" style={{ fontWeight: 700, fontSize: "0.8rem" }}>
            Only letters are allowed
          </Form.Control.Feedback>

        </div>
        <div>
          <Form.Control

            type="date"
            style={{
              border: "1px solid #DFE1E1",
              background: "#FAFAFA",
              fontWeight: 600,
              fontSize: "0.8rem",

            }}
            min="1921-01-10"
            max="2021-01-01"
            placeholder="Your Date of birth"
            className="w-100 my-2 p-2 mt-2"
            value={formData.dob}
            isInvalid={!isValid && isToucheddob}
            onChange={(event) => {
              setIsToucheddob(true);
              let date = new Date(event.target.value);
              if (date.toString() !== "Invalid Date") {
                setFormData({ ...formData, dob: event.target.value })
                setIsValid(true);
              } else {
                setIsValid(false);
              }
            }
            }
            required />
        </div>
      </Form>
      <div className="d-flex genders my-3 ">
        <div>
          <input
            type="radio"
            className="btn-check "
            onChange={handleChange}
            value="MALE"
            name="options"
            id="male"
            checked={
              formData.gender == "MALE" || formData.gender == "" ? true : false
            }
            required />
          <label
            className="btn male_btn"
            style={
              formData.gender == "MALE" || formData.gender == ""
                ? {

                  border: "1.2px solid #DFE1E1",
                  background: "#FDCD8F",
                  borderRadius: "8px",
                  padding: "15px",
                  // width: "100px",
                  fontWeight: 700,
                  fontSize: "0.8rem",
                  whiteSpace: "nowrap",
                  boxShadow: "none"
                }
                : {

                  border: "1.2px solid #DFE1E1",
                  borderRadius: "8px",
                  padding: "15px",
                  // width: "100px",
                  fontWeight: 700,
                  fontSize: "0.8rem",
                  whiteSpace: "nowrap",
                  boxShadow: "none"
                }
            }
            htmlFor="male"
          >
            Male <i className="fa fa-mars"></i>
          </label>
        </div>
        <div className="mx-2">
          <input
            type="radio"
            className="btn-check"
            onChange={handleChange}
            value="FEMALE"
            name="options"
            id="female"
            checked={formData.gender == "FEMALE" ? true : false}
          />
          <label
            className="btn female_btn"
            style={
              formData.gender == "FEMALE"
                ? {

                  border: "2px solid #DFE1E1",
                  background: "#FDCD8F",
                  padding: "15px",
                  borderRadius: "8px",
                  // width: "120px",
                  fontWeight: 700,
                  fontSize: "0.8rem",
                  whiteSpace: "nowrap",
                  boxShadow: "none"
                }
                : {

                  border: "2px solid #DFE1E1",
                  padding: "15px",
                  borderRadius: "8px",
                  // width: "120px",
                  fontWeight: 700,
                  fontSize: "0.8rem",
                  whiteSpace: "nowrap",
                  boxShadow: "none"
                }
            }
            htmlFor="female"
          >
            Female <i className="fa fa-venus"></i>
          </label>
        </div>
        <div>
          <input
            type="radio"
            className="btn-check "
            onChange={handleChange}
            value="OTHER"
            name="options"
            id="others"
            checked={formData.gender == "OTHER" ? true : false}
          />
          <label
            className="btn others_btn"
            style={
              formData.gender == "OTHER"
                ? {

                  border: "2px solid #DFE1E1",
                  background: "#FDCD8F",
                  borderRadius: "8px",
                  padding: "15px",
                  // width: "120px",
                  fontWeight: 700,
                  fontSize: "0.8rem",
                  whiteSpace: "nowrap",
                  boxShadow: "none"
                }
                : {

                  border: "2px solid #DFE1E1",
                  // width: "120px",
                  borderRadius: "8px",
                  padding: "15px",
                  fontWeight: 700,
                  fontSize: "0.8rem",
                  whiteSpace: "nowrap",
                  boxShadow: "none"
                }
            }
            htmlFor="others"
          >
            Others{" "}
            <i className="fa fa-neuter" style={{ transform: "scaleY(-1)" }}></i>
          </label>
        </div>
      </div>
      <div className="d-flex w-100 my-2 p-2 mt-2"
        style={{

          fontWeight: 700,
          fontSize: "0.8rem",
        }}
      >
        <div className="form-check">
          <input
            className="form-check-input"
            onChange={handleStudy}
            value="1"
            type="radio"
            name="flexRadioDefault"
            id="school"
            checked={
              formData.study == "1" || formData.study == "" ? true : false
            }
          />
          <label className="form-check-label" for="school">
            School
          </label>
        </div>
        <div className="form-check mx-2">
          <input
            className="form-check-input"
            type="radio"
            onChange={handleStudy}
            value="2"
            name="flexRadioDefault"
            id="college"
            checked={formData.study == "2" ? true : false}
          />
          <label className="form-check-label" for="college">
            College
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            value="3"
            onChange={handleStudy}
            type="radio"
            name="flexRadioDefault"
            id="profession"
            checked={formData.study == "3" ? true : false}
          />
          <label className="form-check-label" for="profession">
            Profession
          </label>
        </div>
      </div>
    </div>
  );
}

export default ProfilePageOne;
