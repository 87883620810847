import React, { useEffect, useState } from "react";
import search_icon from "../images/search_icon.png";
import suggested_material_img from "../images/carousal_img1.png";
import { Card } from "react-bootstrap";
import avatar from "../images/avatar.png";
import EditTopBar from "./EditTopBar";
import { useNavigate } from "react-router";
import { createSearchParams } from "react-router-dom";
import axios from "axios";
import qs from "qs";
// import Select from "react-select";
import Select from "react-dropdown-select";

import BasicCard from "./BasicCard";
import carousal_img1 from "../images/carousal_img1.png"
import carousal_img2 from "../images/carousal_img2.png"
import carousal_img3 from "../images/carousal_img3.png"
import carousal_img4 from "../images/carousal_img4.png"
import carousal_img5 from "../images/carousal_img5.png"
import carousal_img6 from "../images/carousal_img6.png"
export default function FindMentors() {
  const [mentor, setMentor] = useState([]);
  const [mentorSearch, setMentorSearch] = useState("");

  const [selectClass, setSelectClass] = useState([]);
  const [selectStream, setSelectStream] = useState([]);
  const [selectSubjects, setSelectSubjects] = useState([]);
  const [selectMaterials, setSelectMaterials] = useState([]);
  const [selectCost, setSelectCost] = useState([]);
  const [filter, setFilter] = useState({
    classes: [],
    // stream: [],
    subjects: [],
    materials: [],
    cost: [],
  });
  const navigate = useNavigate();
  const userDeviceKey = localStorage.getItem("DEVICE_KEY");
  useEffect(() => {
    const reqData = {
      mod: "Mentor",
      actionType: "get-available-mentors",
      subAction: JSON.stringify({
        DEVICE_KEY: userDeviceKey,
        SEARCH_KEY: mentorSearch,
        FILTERS: [
          { ID: "1", NAME: "IV", SELECTED: selectClass },
          { ID: "2", NAME: "SCIENCE", SELECTED: selectStream },
          { ID: "3", NAME: "ZOOLOGY", SELECTED: selectSubjects },
          { ID: "4", NAME: "SOFT COPY", SELECTED: selectMaterials },
          { ID: "5", NAME: "Paid", SELECTED: selectCost },
        ],
      }),
    };
    // console.log("devicekey", userDeviceKey);
    axios({
      method: "post",
      url: "https://dev-api.guidejuniors.com/",
      data: qs.stringify(reqData),
      header: { "content-type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      let sample = res.data;
      console.log(sample.XSCData.LIST)
      setMentor(sample.XSCData.LIST);
    });
  }, [
    mentorSearch,
    userDeviceKey,
    selectClass,
    selectStream,
    selectSubjects,
    selectMaterials,
    selectCost,
  ]);
  const classOptions = filter.classes.map((count) => {
    return {
      value: count.ID,
      label: count.NAME,
    };
  });
  // console.log(filter.cost);
  const handleSelectClass = (e) => {
    setSelectClass(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  const handleClick = (mentor) => {
    const mentorId = mentor.USER_ID;
    navigate({
      pathname: "/find-mentors/mentor-profile",
      search: `?${createSearchParams({
        id: mentorId,
      })}`,
    });
  };
  const subjectsOptions = filter.subjects.map((count) => {
    return {
      value: count.ID,
      label: count.NAME,
    };
  });

  const handleSelectSubjects = (e) => {
    setSelectSubjects(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  const handleMentorSearch = (e) => {
    setMentorSearch(e.target.value);
  };

  useEffect(() => {
    const reqData = {
      mod: "GJ",
      actionType: "get-master-filter-list",
      subAction: JSON.stringify({
        DEVICE_KEY: userDeviceKey,
        FILTER_TYPE: "MATERIAL",
      }),
    };

    axios({
      method: "post",
      url: "https://dev-api.guidejuniors.com/",
      data: qs.stringify(reqData),
      header: { "content-type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      let sample = res.data;
      // console.log(sample);
      setFilter({
        classes: sample.XSCData[0].FILTERS,
        stream: sample.XSCData[1].FILTERS,
        subjects: sample.XSCData[1].FILTERS,
        materials: sample.XSCData[2].FILTERS,
        cost: sample.XSCData[3].FILTERS,
      });
    });
  }, [userDeviceKey]);
  const Mentor=[
    {
      IMAGE_URL:carousal_img1,
      MENTOR_NAME:"Teena",
      MENTOR_SUBJECT:"Physics"
      },
    {
      IMAGE_URL:carousal_img2,
      MENTOR_NAME:"Fernandez",
      MENTOR_SUBJECT:"Mathematics"
      },
    {
      IMAGE_URL:carousal_img3,
      MENTOR_NAME:"Micheal",
      MENTOR_SUBJECT:"Chemistry"
      },
    {
      IMAGE_URL:carousal_img4,
      MENTOR_NAME:"John",
      MENTOR_SUBJECT:"Biology"
      },
    {
      IMAGE_URL:carousal_img5,
      MENTOR_NAME:"Joseph",
      MENTOR_SUBJECT:"English"
      },
    {
      IMAGE_URL:carousal_img6,
      MENTOR_NAME:"Erik",
      MENTOR_SUBJECT:"Commerce"
      },
  
  
  ]
  return (
    <div className="container-fluid " style={{ padding: "0" }}>
      <EditTopBar />

      <div className="py-2 row w-100 container">
        <div className="col-lg-2">
          <div className='py-2 ' style={{fontWeight:600,fontSize:"0.9rem"}}>
                       <a style={{textDecoration:"none",color:"black",}} href="/user-profile">Home</a> {" "}{">"}{" "} Find Mentor
                    </div>
        </div>
        <div className="col-lg-8">
          <div className="d-md-flex py-2" style={{ fontSize: "0.9rem" }}>
            <div className="py-2 mx-md-2" style={{ width: "100%" }}>
              <div>
                <div style={{ position: "relative", display: "inlineBlock" }}>
                  <span
                    className="my-2"
                    style={{ position: "absolute", left: "0", top: "-3px" }}
                  >
                    <img src={search_icon} width="20" className="ps-1" />
                  </span>
                  <input
                    className="rounded"
                    onChange={handleMentorSearch}
                    placeholder="Search mentors by name, skill or role"
                    style={{
                      backgroundColor: "#F5F6F6",
                      border: "none",
                      paddingLeft: "30px",
                      width: "100%",
                      height: "30px",
                      fontSize: "0.7rem"
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="py-2 mx-md-2">
              <Select
                options={classOptions}
                // style={{ width: "100%", height: "30px", zIndex: "2", fontSize: "0.7rem" }}
                value={classOptions.filter((obj) =>
                  selectClass.includes(obj.value)
                )}
                multi
                placeholder="Select class"
                onChange={handleSelectClass}
              />
            </div>

            <div className="py-2 mx-md-2">
              <Select
                options={subjectsOptions}

                // style={{ width: "100%", padding: "60px", zIndex: "2", fontSize: "1.2rem" }}
                value={subjectsOptions.filter((obj) =>
                  selectSubjects.includes(obj.value)
                )}
                multi
                placeholder="Select Subjects"
                onChange={handleSelectSubjects}
              />
            </div>
            {/* <div className='mx-1 my-2' >
                                    <Select options={streamOptions} style={{ width: "100%", height: "30px" }} value={streamOptions.filter(obj => selectStream.includes(obj.value))} isMulti placeholder="Select Materials"
                                        onChange={handleSelectStream} />
                                </div> */}
            {/* <div className='mx-1' style={{ width: "15%" }}>
                            <select className='rounded' style={{ width: "100%", height: "30px" }}>
                                <option selected>All Class</option>
                            </select>
                        </div>
                        <div className='mx-1' style={{ width: "15%" }}>
                            <select className='rounded' style={{ width: "100%", height: "30px" }}>
                                <option selected>All Streams</option>
                            </select>
                        </div>
                        <div className='mx-1' style={{ width: "15%" }}>
                            <select className='rounded' style={{ width: "100%", height: "30px" }}>
                                <option selected>All Subjects</option>
                            </select>
                        </div> */}
          </div>
          {
            Mentor.length === 0 ? <div className="text-center mt-3">No Mentor Found</div> : <div></div>
          }
          <div className="row py-2">
            {Mentor.map((mentor, index) => {
              return (
                <div key={index} className="col-lg-3 col-md-3  col-sm-4 col-6">
                  <div className="p-2">
                    <Card
                      style={{
                        width: "165px",
                        height: "200px",
                        borderRadius: "3%",
                        marginBottom: "10%",
                        objectFit: "cover",
                        cursor: "pointer",
                      }}
                      onClick={() => handleClick(mentor)}
                    >
                      <p className="slideshow-wrapper">
                        <Card.Img
                          src={mentor.IMAGE_URL
                          }
                          alt="image"
                          className="img-fluid"
                          style={{
                            width: "185px",
                            height: "200px",
                            objectFit: "cover",
                            borderRadius: "3%",
                          }}
                        />
                      </p>
                      <div>
                        <h5
                          className="mentor_name ps-2"
                          style={{
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            width: "140px",
                            zIndex: "1",
                          }}
                        >
                          {mentor.MENTOR_NAME}
                        </h5>
                        <div className="mentor_subject ps-2">
                          {mentor.MENTOR_SUBJECT}
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-lg-2"></div>
      </div>
    </div>
  );
}
