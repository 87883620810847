import React, { useEffect, useState } from "react";
import DropFileInput from "./drop-file-input/DropFileInput";
import axios from "axios";
import qs from "qs";
import { Button, Modal } from "react-bootstrap";
import DOMPurify from "dompurify";
import Swal from "sweetalert2";

const AddNewSchool = (props) => {
  const [sclName, setSclName] = useState();
  const handlesetSclName = (e) => {
    setSclName(e.target.value)
  }
  const [brnchName, setBrnchName] = useState();
  const handlesetBrnchName = (e) => {
    setBrnchName(e.target.value)
  }
  const [stateList, setStateList] = useState({
    selState: [],
  });

  const [stateName, setStateName] = useState();
  const handlesetStateList = (e) => {
    setStateName(e.target.value)
  }

  useEffect(() => {
    const reqData = {
      mod: "GJ",
      actionType: "get-state-list",
      subAction: JSON.stringify({
        DEVICE_KEY: props.deviceKey,
      })
    }
    axios({
      method: "post",
      url: "https://dev-api.guidejuniors.com/",
      data: qs.stringify(reqData),
      headers: { "content-type": "application/x-www-form-urlencoded" },
    })
      .then((res) => {
        let sample = res.data
        setStateList({
          selState: sample.XSCData
        })
      })
  }, [props.deviceKey])

  const [cityName, setCityName] = useState();
  const handlesetcityList = (e) => {
    setCityName(e.target.value)
  }
  const [cityList, setCityList] = useState({
    selCity: [],
  });

  useEffect(() => {
    const reqData = {
      mod: "GJ",
      actionType: "get-city-list",
      subAction: JSON.stringify({
        DEVICE_KEY: props.deviceKey,
        STATE_ID: stateName,
      })
    }
    axios({
      method: "post",
      url: "https://dev-api.guidejuniors.com/",
      data: qs.stringify(reqData),
      headers: { "content-type": "application/x-www-form-urlencoded" },
    })
      .then((res) => {
        let sample = res.data
        setCityList({
          selCity: sample.XSCData,
        })
      })
  }, [props.deviceKey, stateName])

  const addDetailsValue = (e) => {
    e.preventDefault();
    const reqData = {
      mod: "GJ",
      actionType: "add-new-school",
      subAction: JSON.stringify(
        {
          TYPE: "1",
          DEVICE_KEY: props.deviceKey,
          NAME: sclName,
          BRANCH: brnchName,
          STATE_ID: stateName,
          CITY_ID: cityName,
        }
      )
    }
    axios({
      method: "post",
      url: "https://dev-api.guidejuniors.com/",
      data: qs.stringify(reqData),
      headers: { "content-type": "application/x-www-form-urlencoded" },
    })
      .then((res) => {
        let sample = res.data
        if (sample.XSCStatus === 0) {
          Swal.fire("error", "", "info");
        }
        Swal.fire("Update Successfully", "", "success");
        setTimeout(function () {
          window.location.reload(1);
        }, 1000);
      })
  }
  return (
    <Modal
      style={{ background: "rgba(95, 99, 97, 0.59)" }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable={true}
      show={props.show} onHide={props.onHide} size="sm"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h5>Add new school</h5>
        </Modal.Title>
      </Modal.Header>

      <form
        onSubmit={addDetailsValue}
      >
        <Modal.Body>
          <input
            placeholder="Enter school name"
            type="text"
            className="w-100 mb-3 py-2 ps-3"
            style={{
              border: "1px solid #DFE1E1",
              background: "#FAFAFA",
              fontWeight: "600",
              fontSize: "0.8rem",
              color: "#515C5A",
            }}
            onChange={handlesetSclName}
            required
          />
          <input
            placeholder="Enter Branch Name"
            type="text"
            className="w-100 mb-3 py-2 ps-3"
            style={{
              border: "1px solid #DFE1E1",
              background: "#FAFAFA",
              fontWeight: "600",
              fontSize: "0.8rem",
              color: "#515C5A",
            }}
            onChange={handlesetBrnchName}
            required
          />

          <select className="form-select form-select-lg mb-3 p-2" aria-label=".form-select-lg example"
            style={{
              border: "1px solid #DFE1E1",
              fontWeight: "600",
              fontSize: "0.8rem",
              color: "#515C5A",
            }}
            onChange={handlesetStateList}
            value={stateName}
            required
          >
            <option value="" selected>Select State</option>
            {
              stateList.selState.map((state, index) => {
                return (
                  <option key={index} value={state.ID}>{state.NAME}</option>
                )
              })
            }
          </select>
          <select className="form-select form-select-lg mb-3 p-2" aria-label=".form-select-lg example"
            style={{
              border: "1px solid #DFE1E1",
              fontWeight: "600",
              fontSize: "0.8rem",
              color: "#515C5A",
            }}
            onChange={handlesetcityList}
            value={cityName}
            required
          >
            <option value="" selected>Select City</option>
            {
              cityList.selCity.map((city) => {
                return (
                  <option value={city.ID}>{city.NAME}</option>
                )
              })
            }
          </select>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn my-1 mx-1" style={{ fontSize: "0.7rem", background: "#FBA93E", color: "#515C5A", fontWeight: "600", }} onClick={props.onHide}>
            Close
          </Button>
          <Button variant="primary" className="btn my-1 mx-1 float-end" type="submit" name="form"
            style={{
              background: "#FBA93E",
              fontSize: "0.7rem",
              color: "#515C5A", fontWeight: "600",
            }}
          >
            Add as a new school
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

function SchoolSearchModal(props) {
  const [getschool, setGetSchool] = useState([]);
  const [getSchoolSearch, setGetSchoolSearch] = useState("");
  const device_key = localStorage.getItem("DEVICE_KEY");
  useEffect(() => {
    const reqData = {
      mod: "GJ",
      actionType: "get-school-list",
      subAction: JSON.stringify({
        TYPE: "1",
        DEVICE_KEY: device_key,
        SEARCH_KEY: getSchoolSearch,
      }),
    };
    axios({
      method: "post",
      url: "https://dev-api.guidejuniors.com/",
      data: qs.stringify(reqData),
      header: { "content-type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      const sample = res.data;
      setGetSchool(sample.XSCData);
    });
  }, [getSchoolSearch, device_key]);
  const handleSearchInsert = (e) => {
    setGetSchoolSearch(e.target.value);
  };
  const handleSchoolId = (school) => {
    props.setSchool(school);
    props.onHide();
  };

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setGetSchoolSearch("");
  }
  const handleShow = () => setShow(true);


  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      scrollable={true}
    >
      <Modal.Header onHide={handleClose} closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Select School
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <input
            type="text"
            onChange={handleSearchInsert}
            className="w-100 py-1"
            // style={{border:"none"}}
            placeholder="Search your school"
            autoFocus
          />
        </div>
        {getschool.map((school, index) => {
          return (
            <div key={index} onClick={() => handleSchoolId(school)}>
              <div style={{ fontSize: "1rem" }}>
                {/* <b>{school.NAME}</b> */}
                <b dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(school.NAME) }}></b>

              </div>
              <div style={{ fontSize: "0.8rem" }}>
                <span>
                  <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(school.STATE) }}></span>
                  <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(school.CITY) }}></span>
                </span>
              </div>
            </div>
          );
        })}
        <Button onClick={handleShow} className="mt-2 mx-2 float-end"
          style={{
            background: "#FBA93E",
            fontSize: "0.7rem",
            color: "#515C5A", fontWeight: "600",
          }}
        >
          Add new School
        </Button>
        <AddNewSchool deviceKey={device_key} show={show} onHide={handleClose} />
      </Modal.Body>
    </Modal>
  );
}

const AddNewCollege = (props) => {
  const [clgName, setClgName] = useState();
  const handlesetSclName = (e) => {
    setClgName(e.target.value)
  }
  const [brnchName, setBrnchName] = useState();
  const handlesetBrnchName = (e) => {
    setBrnchName(e.target.value)
  }
  const [stateList, setStateList] = useState({
    selState: [],
  });

  const [stateName, setStateName] = useState();
  const handlesetStateList = (e) => {
    setStateName(e.target.value)
  }

  useEffect(() => {
    const reqData = {
      mod: "GJ",
      actionType: "get-state-list",
      subAction: JSON.stringify({
        DEVICE_KEY: props.deviceKey,
      })
    }
    axios({
      method: "post",
      url: "https://dev-api.guidejuniors.com/",
      data: qs.stringify(reqData),
      headers: { "content-type": "application/x-www-form-urlencoded" },
    })
      .then((res) => {
        let sample = res.data
        setStateList({
          selState: sample.XSCData
        })
      })
  }, [props.deviceKey])

  const [cityName, setCityName] = useState();
  const handlesetcityList = (e) => {
    setCityName(e.target.value)
  }
  const [cityList, setCityList] = useState({
    selCity: [],
  });

  useEffect(() => {
    const reqData = {
      mod: "GJ",
      actionType: "get-city-list",
      subAction: JSON.stringify({
        DEVICE_KEY: props.deviceKey,
        STATE_ID: stateName,
      })
    }
    axios({
      method: "post",
      url: "https://dev-api.guidejuniors.com/",
      data: qs.stringify(reqData),
      headers: { "content-type": "application/x-www-form-urlencoded" },
    })
      .then((res) => {
        let sample = res.data
        setCityList({
          selCity: sample.XSCData,
        })
      })
  }, [props.deviceKey, stateName])

  const addDetailsValue = (e) => {
    e.preventDefault();
    const reqData = {
      mod: "GJ",
      actionType: "add-new-school",
      subAction: JSON.stringify(
        {
          TYPE: "2",
          DEVICE_KEY: props.deviceKey,
          NAME: clgName,
          BRANCH: brnchName,
          STATE_ID: stateName,
          CITY_ID: cityName,
        }
      )
    }
    axios({
      method: "post",
      url: "https://dev-api.guidejuniors.com/",
      data: qs.stringify(reqData),
      headers: { "content-type": "application/x-www-form-urlencoded" },
    })
      .then((res) => {
        let sample = res.data
        if (sample.XSCStatus === 0) {
          Swal.fire("error", "", "info");
        }
        Swal.fire("Update Successfully", "", "success");
        setTimeout(function () {
          window.location.reload(1);
        }, 1000);
      })
  }
  return (
    <Modal
      style={{ background: "rgba(95, 99, 97, 0.59)" }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable={true}
      show={props.show} onHide={props.onHide} size="sm"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h5>Add new college</h5>
        </Modal.Title>
      </Modal.Header>

      <form
        onSubmit={addDetailsValue}
      >
        <Modal.Body>
          <input
            placeholder="Enter college name"
            type="text"
            className="w-100 mb-3 py-2 ps-3"
            style={{
              border: "1px solid #DFE1E1",
              background: "#FAFAFA",
              fontWeight: "600",
              fontSize: "0.8rem",
              color: "#515C5A",
            }}
            onChange={handlesetSclName}
            required
          />
          <input
            placeholder="Enter Branch Name"
            type="text"
            className="w-100 mb-3 py-2 ps-3"
            style={{
              border: "1px solid #DFE1E1",
              background: "#FAFAFA",
              fontWeight: "600",
              fontSize: "0.8rem",
              color: "#515C5A",
            }}
            onChange={handlesetBrnchName}
            required
          />

          <select className="form-select form-select-lg mb-3 p-2" aria-label=".form-select-lg example"
            style={{
              border: "1px solid #DFE1E1",
              fontWeight: "600",
              fontSize: "0.8rem",
              color: "#515C5A",
            }}
            onChange={handlesetStateList}
            value={stateName}
            required
          >
            <option value="" selected>Select State</option>
            {
              stateList.selState.map((state, index) => {
                return (
                  <option key={index} value={state.ID}>{state.NAME}</option>
                )
              })
            }
          </select>
          <select className="form-select form-select-lg mb-3 p-2" aria-label=".form-select-lg example"
            style={{
              border: "1px solid #DFE1E1",
              fontWeight: "600",
              fontSize: "0.8rem",
              color: "#515C5A",
            }}
            onChange={handlesetcityList}
            value={cityName}
            required
          >
            <option value="" selected>Select City</option>
            {
              cityList.selCity.map((city) => {
                return (
                  <option value={city.ID}>{city.NAME}</option>
                )
              })
            }
          </select>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn my-1 mx-1" style={{ fontSize: "0.7rem", background: "#FBA93E", color: "#515C5A", fontWeight: "600", }} onClick={props.onHide}>
            Close
          </Button>
          <Button variant="primary" className="btn my-1 mx-1 float-end" type="submit" name="form"
            style={{
              background: "#FBA93E",
              fontSize: "0.7rem",
              color: "#515C5A", fontWeight: "600",
            }}
          >
            Add as a new college
            {/* {getAddNewValue(props.type)} */}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

function CollegeSearchModal(props) {
  const [getcollege, setGetCollege] = useState([]);
  const [getCollegeSearch, setGetCollegeSearch] = useState("");
  const device_key = localStorage.getItem("DEVICE_KEY");
  useEffect(() => {
    const reqData = {
      mod: "GJ",
      actionType: "get-school-list",
      subAction: JSON.stringify({
        TYPE: "2",
        DEVICE_KEY: device_key,
        SEARCH_KEY: getCollegeSearch,
      }),
    };
    axios({
      method: "post",
      url: "https://dev-api.guidejuniors.com/",
      data: qs.stringify(reqData),
      header: { "content-type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      const sample = res.data;
      setGetCollege(sample.XSCData);
    });
  }, [device_key, getCollegeSearch]);
  
  const handleCollegeSearchInsert = (e) => {
    setGetCollegeSearch(e.target.value);
  };
  const handleCollegeId = (college) => {
    props.setCollege(college);
    props.onHide();
  };
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setGetCollegeSearch("")
  }
  const handleShow = () => setShow(true);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      scrollable={true}
    >
      <Modal.Header onHide={handleClose} closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Select college
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <input
            type="text"
            onChange={handleCollegeSearchInsert}
            className="w-100 py-1"
            // style={{border:"none"}}
            placeholder="Search your college"
            autoFocus
          />
        </div>
        {getcollege.map((college, index) => {
          return (
            <div key={index} onClick={() => handleCollegeId(college)}>
              <div style={{ fontSize: "1rem" }}>
                {/* <b>{college.NAME}</b> */}
                <b dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(college.NAME) }}></b>

              </div>
              <div style={{ fontSize: "0.8rem" }}>
                <span>
                  <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(college.STATE) }}></span>
                  <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(college.CITY) }}></span>
                </span>
              </div>
            </div>
          );
        })}
        <Button onClick={handleShow} className="mt-2 mx-2 float-end"
          style={{
            background: "#FBA93E",
            fontSize: "0.7rem",
            color: "#515C5A", fontWeight: "600",
          }}
        >
          Add new college
        </Button>
        <AddNewCollege deviceKey={device_key} show={show} onHide={handleClose} />
      </Modal.Body>
    </Modal>
  );
}

const AddNewOrg = (props) => {
  const [orgName, setOrgName] = useState();
  const handlesetSclName = (e) => {
    setOrgName(e.target.value)
  }
  const [brnchName, setBrnchName] = useState();
  const handlesetBrnchName = (e) => {
    setBrnchName(e.target.value)
  }
  const [stateList, setStateList] = useState({
    selState: [],
  });

  const [stateName, setStateName] = useState();
  const handlesetStateList = (e) => {
    setStateName(e.target.value)
  }

  useEffect(() => {
    const reqData = {
      mod: "GJ",
      actionType: "get-state-list",
      subAction: JSON.stringify({
        DEVICE_KEY: props.deviceKey,
      })
    }
    axios({
      method: "post",
      url: "https://dev-api.guidejuniors.com/",
      data: qs.stringify(reqData),
      headers: { "content-type": "application/x-www-form-urlencoded" },
    })
      .then((res) => {
        let sample = res.data
        setStateList({
          selState: sample.XSCData
        })
      })
  }, [props.deviceKey])

  const [cityName, setCityName] = useState();
  const handlesetcityList = (e) => {
    setCityName(e.target.value)
  }
  const [cityList, setCityList] = useState({
    selCity: [],
  });

  useEffect(() => {
    const reqData = {
      mod: "GJ",
      actionType: "get-city-list",
      subAction: JSON.stringify({
        DEVICE_KEY: props.deviceKey,
        STATE_ID: stateName,
      })
    }
    axios({
      method: "post",
      url: "https://dev-api.guidejuniors.com/",
      data: qs.stringify(reqData),
      headers: { "content-type": "application/x-www-form-urlencoded" },
    })
      .then((res) => {
        let sample = res.data
        setCityList({
          selCity: sample.XSCData,
        })
      })
  }, [props.deviceKey, stateName])

  const addDetailsValue = (e) => {
    e.preventDefault();
    const reqData = {
      mod: "GJ",
      actionType: "add-new-school",
      subAction: JSON.stringify(
        {
          TYPE: "3",
          DEVICE_KEY: props.deviceKey,
          NAME: orgName,
          BRANCH: brnchName,
          STATE_ID: stateName,
          CITY_ID: cityName,
        }
      )
    }
    axios({
      method: "post",
      url: "https://dev-api.guidejuniors.com/",
      data: qs.stringify(reqData),
      headers: { "content-type": "application/x-www-form-urlencoded" },
    })
      .then((res) => {
        let sample = res.data
        if (sample.XSCStatus === 0) {
          Swal.fire("error", "", "info");
        }
        Swal.fire("Update Successfully", "", "success");
        setTimeout(function () {
          window.location.reload(1);
        }, 1000);
      })
  }
  return (
    <Modal
      style={{ background: "rgba(95, 99, 97, 0.59)" }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable={true}
      show={props.show} onHide={props.onHide} size="sm"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h5>Add new organization</h5>
        </Modal.Title>
      </Modal.Header>

      <form
        onSubmit={addDetailsValue}
      >
        <Modal.Body>
          <input
            placeholder="Enter your organization"
            type="text"
            className="w-100 mb-3 py-2 ps-3"
            style={{
              border: "1px solid #DFE1E1",
              background: "#FAFAFA",
              fontWeight: "600",
              fontSize: "0.8rem",
              color: "#515C5A",
            }}
            onChange={handlesetSclName}
            required
          />
          <input
            placeholder="Enter Branch Name"
            type="text"
            className="w-100 mb-3 py-2 ps-3"
            style={{
              border: "1px solid #DFE1E1",
              background: "#FAFAFA",
              fontWeight: "600",
              fontSize: "0.8rem",
              color: "#515C5A",
            }}
            onChange={handlesetBrnchName}
            required
          />

          <select className="form-select form-select-lg mb-3 p-2" aria-label=".form-select-lg example"
            style={{
              border: "1px solid #DFE1E1",
              fontWeight: "600",
              fontSize: "0.8rem",
              color: "#515C5A",
            }}
            onChange={handlesetStateList}
            value={stateName}
            required
          >
            <option value="" selected>Select State</option>
            {
              stateList.selState.map((state, index) => {
                return (
                  <option key={index} value={state.ID}>{state.NAME}</option>
                )
              })
            }
          </select>
          <select className="form-select form-select-lg mb-3 p-2" aria-label=".form-select-lg example"
            style={{
              border: "1px solid #DFE1E1",
              fontWeight: "600",
              fontSize: "0.8rem",
              color: "#515C5A",
            }}
            onChange={handlesetcityList}
            value={cityName}
            required
          >
            <option value="" selected>Select City</option>
            {
              cityList.selCity.map((city) => {
                return (
                  <option value={city.ID}>{city.NAME}</option>
                )
              })
            }
          </select>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn my-1 mx-1" style={{ fontSize: "0.7rem", background: "#FBA93E", color: "#515C5A", fontWeight: "600", }} onClick={props.onHide}>
            Close
          </Button>
          <Button variant="primary" className="btn my-1 mx-1 float-end" type="submit" name="form"
            style={{
              background: "#FBA93E",
              fontSize: "0.7rem",
              color: "#515C5A", fontWeight: "600",
            }}
          >
            Add as a new organization
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

function CompanySearchModal(props) {
  const [getcompany, setGetCompany] = useState([]);
  const [getCompanySearch, setGetCompanySearch] = useState("");
  const device_key = localStorage.getItem("DEVICE_KEY");
  useEffect(() => {
    const reqData = {
      mod: "GJ",
      actionType: "get-school-list",
      subAction: JSON.stringify({
        TYPE: "3",
        DEVICE_KEY: device_key,
        SEARCH_KEY: getCompanySearch,
      }),
    };
    axios({
      method: "post",
      url: "https://dev-api.guidejuniors.com/",
      data: qs.stringify(reqData),
      header: { "content-type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      const sample = res.data;
      setGetCompany(sample.XSCData);
    });
  }, [getCompanySearch, device_key]);

  const handleCompanySearchInsert = (e) => {
    setGetCompanySearch(e.target.value);
  };
  const handleCompanyId = (company) => {
    props.setCompany(company);
    props.onHide();
  };
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setGetCompanySearch("");
  }
  const handleShow = () => setShow(true);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      scrollable={true}
    >
      <Modal.Header onHide={handleClose} closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Select organization
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <input
            type="text"
            onChange={handleCompanySearchInsert}
            className="w-100 py-1"
            // style={{border:"none"}}
            placeholder="Search your company"
            autoFocus
          />
        </div>
        {getcompany.map((company, index) => {
          return (
            <div key={index} onClick={() => handleCompanyId(company)}>
              <div style={{ fontSize: "1rem" }}>
                <b dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(company.NAME) }}></b>
              </div>
              <div style={{ fontSize: "0.8rem" }}>
                <span>
                  <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(company.STATE) }}></span>
                  <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(company.CITY) }}></span>
                </span>
              </div>
            </div>
          );
        })}
        <Button onClick={handleShow} className="mt-2 mx-2 float-end"
          style={{
            background: "#FBA93E",
            fontSize: "0.7rem",
            color: "#515C5A", fontWeight: "600",
          }}
        >
          Add new organization
        </Button>
        <AddNewOrg deviceKey={device_key} show={show} onHide={handleClose} />
      </Modal.Body>
    </Modal>
  );
}

function ProfilePageTwo({ formData, setFormData }) {
  const device_key = localStorage.getItem("DEVICE_KEY");
  const [category, setCategory] = useState("");
  const [company, setCompany] = useState("");
  const [jobRole, setJobRole] = useState("");
  const [experience, setExperience] = useState("");
  const [classes, setClass] = useState("");
  const [higherGroup, setHigherGroup] = useState("");
  const [higherSubject, setHigherSubject] = useState("");
  const [images, setImages] = useState("");
  // console.log(images);
  const [degree, setDegree] = useState("");
  // const [group, setGroup] = useState("");
  const [pursueYears, setPursueYears] = useState("");

  //useState for API's
  const [classId, setClassId] = useState([]);
  const [group, setGroup] = useState([]);
  const [higherGroupSelect, setHigherGroupSelect] = useState([]);
  const [higherSubjectSelect, setHigherSubjectSelect] = useState([]);
  const [streamSelect, setStreamSelect] = useState([]);
  const [groupSelect, setGroupSelect] = useState([]);
  const [pursueSelect, setPursueSelect] = useState([]);
  const [categorySelect, setCategorySelect] = useState([]);
  const [jobRoleSelect, setJobRoleSelect] = useState([]);
  const [yearOfExperienceSelect, setYearOfExperienceSelect] = useState([]);
  const [schoolShow, setSchoolShow] = useState(false);
  const [collegeShow, setCollegeShow] = useState(false);
  const [companyShow, setCompanyShow] = useState(false);
  const [school, setSchool] = useState({
    ID: "",
    NAME: "",
  });
  const [college, setCollege] = useState({
    ID: "",
    NAME: "",
  });
  // console.log(school.ID);

  const handleSchoolClick = (school) => {
    setSchoolShow(true);

    formData.study === "1" || formData.study === ""
      ? setFormData({ ...formData, school: school.ID })
      : setFormData();
  };
  const handleCollegeClick = (college) => {
    setCollegeShow(true);

    formData.study === "2"
      ? setFormData({ ...formData, college: college.ID })
      : setFormData();
  };
  const handleCompanyClick = (company) => {
    setCompanyShow(true);

    formData.study === "3"
      ? setFormData({ ...formData, company: company.ID })
      : setFormData();
  };
  const handleCompanyClose = () => {
    setCompanyShow(false);
  };
  const handleCollegeClose = () => {
    setCollegeShow(false);
  };
  const handleSchoolClose = () => {
    setSchoolShow(false);
  };
  // School useEffect
  /****************************************************/
  //School Class
  useEffect(() => {
    const reqData = {
      mod: "GJ",
      actionType: "get-class-list",
      subAction: JSON.stringify({
        TYPE: "1",
        DEVICE_KEY: device_key,
      }),
    };
    axios({
      method: "post",
      url: "https://dev-api.guidejuniors.com/",
      data: qs.stringify(reqData),
      header: { "content-type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      let response = res.data;
      setClassId(response.XSCData);
    });
  }, [device_key]);
  //school Group
  useEffect(() => {
    const reqData = {
      mod: "GJ",
      actionType: "get-group-list",
      subAction: JSON.stringify({
        TYPE: "1",
        DEVICE_KEY: device_key,
      }),
    };
    axios({
      method: "post",
      url: "https://dev-api.guidejuniors.com/",
      data: qs.stringify(reqData),
      header: { "content-type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      let response = res.data;
      setHigherGroupSelect(response.XSCData);
    });
  }, [device_key]);
  //school higher subjects
  useEffect(() => {
    const reqData = {
      mod: "GJ",
      actionType: "get-stream-list",
      subAction: JSON.stringify({
        TYPE: "1",
        DEVICE_KEY: device_key,
      }),
    };
    axios({
      method: "post",
      url: "https://dev-api.guidejuniors.com/",
      data: qs.stringify(reqData),
      header: { "content-type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      let response = res.data;
      setHigherSubjectSelect(response.XSCData);
    });
  }, [device_key]);
  /****************************************************/
  {
    /*College useEffect*/
  }
  /*****************************************************/
  //college Degree
  useEffect(() => {
    const reqData = {
      mod: "GJ",
      actionType: "get-stream-list",
      subAction: JSON.stringify({
        TYPE: "2",
        DEVICE_KEY: device_key,
      }),
    };
    axios({
      method: "post",
      url: "https://dev-api.guidejuniors.com/",
      data: qs.stringify(reqData),
      header: { "content-type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      let response = res.data;
      setStreamSelect(response.XSCData);
    });
  }, [device_key]);
  // college Department
  useEffect(() => {
    const reqData = {
      mod: "GJ",
      actionType: "get-group-list",
      subAction: JSON.stringify({
        TYPE: "2",
        DEVICE_KEY: device_key,
      }),
    };
    axios({
      method: "post",
      url: "https://dev-api.guidejuniors.com/",
      data: qs.stringify(reqData),
      header: { "content-type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      let response = res.data;
      setGroupSelect(response.XSCData);
    });
  }, [device_key]);
  //college Pursue Years
  useEffect(() => {
    const reqData = {
      mod: "GJ",
      actionType: "get-class-list",
      subAction: JSON.stringify({
        TYPE: "2",
        DEVICE_KEY: device_key,
      }),
    };
    axios({
      method: "post",
      url: "https://dev-api.guidejuniors.com/",
      data: qs.stringify(reqData),
      header: { "content-type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      let response = res.data;
      setPursueSelect(response.XSCData);
    });
  }, [device_key]);
  /*****************************************************/

  {
    /*Company UseEffect*/
  }
  /****************************************************/
  // company category
  useEffect(() => {
    const reqData = {
      mod: "GJ",
      actionType: "get-stream-list",
      subAction: JSON.stringify({
        TYPE: "3",
        DEVICE_KEY: device_key,
      }),
    };
    axios({
      method: "post",
      url: "https://dev-api.guidejuniors.com/",
      data: qs.stringify(reqData),
      header: { "content-type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      let response = res.data;
      setCategorySelect(response.XSCData);
    });
  }, [device_key]);
  //company Job Role
  useEffect(() => {
    const reqData = {
      mod: "GJ",
      actionType: "get-group-list",
      subAction: JSON.stringify({
        TYPE: "3",
        DEVICE_KEY: device_key,
      }),
    };
    axios({
      method: "post",
      url: "https://dev-api.guidejuniors.com/",
      data: qs.stringify(reqData),
      header: { "content-type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      let response = res.data;
      setJobRoleSelect(response.XSCData);
    });
  }, [device_key]);
  //Company Year of Experience
  useEffect(() => {
    const reqData = {
      mod: "GJ",
      actionType: "get-class-list",
      subAction: JSON.stringify({
        TYPE: "3",
        DEVICE_KEY: device_key,
      }),
    };
    axios({
      method: "post",
      url: "https://dev-api.guidejuniors.com/",
      data: qs.stringify(reqData),
      header: { "content-type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      let response = res.data;
      setYearOfExperienceSelect(response.XSCData);
    });
  }, [device_key]);

  /****************************************************/

  const handleCategory = (e) => {
    formData.study === "3" ? setCategory(e.target.value) : setCategory();
    formData.study === "3"
      ? setFormData({ ...formData, category: e.target.value })
      : setFormData();
  };
  const handleCompany = (e) => {
    formData.study === "3" ? setCompany(e.target.value) : setCompany();
    formData.study === "3"
      ? setFormData({ ...formData, company: e.target.value })
      : setFormData();
  };
  const handleJobRole = (e) => {
    formData.study === "3" ? setJobRole(e.target.value) : setJobRole();
    formData.study === "3"
      ? setFormData({ ...formData, jobRole: e.target.value })
      : setFormData();
  };
  const handleYOE = (e) => {
    formData.study === "3" ? setExperience(e.target.value) : setExperience();
    formData.study === "3"
      ? setFormData({ ...formData, experience: e.target.value })
      : setFormData();
  };
  // const handleCollege = (e) => {
  //   formData.study === "2" ? setCollege(e.target.value) : setCollege();
  //   formData.study === "2"
  //     ? setFormData({ ...formData, college: e.target.value })
  //     : setFormData();
  // };

  // College Click Functions
  const handleDegree = (e) => {
    formData.study === "2" ? setDegree(e.target.value) : setDegree();
    formData.study === "2"
      ? setFormData({ ...formData, degree: e.target.value })
      : setFormData();
  };
  const handleDepartment = (e) => {
    formData.study === "2" ? setGroup(e.target.value) : setGroup();
    formData.study === "2"
      ? setFormData({ ...formData, group: e.target.value })
      : setFormData();
  };
  const handlePursueYears = (e) => {
    setPursueYears(e.target.value);
    formData.study === "2" ? setPursueYears(e.target.value) : setPursueYears();
    formData.study === "2"
      ? setFormData({ ...formData, pursueYears: e.target.value })
      : setFormData();
  };
  //School Click Function
  const handleClass = (e) => {
    formData.study === "1" ? setClass(e.target.value) : setClass();
    setFormData({ ...formData, classes: e.target.value });
  };

  const handleHigherGroup = (e) => {
    formData.study === "1" ? setHigherGroup(e.target.value) : setHigherGroup();
    setFormData({ ...formData, higherGroup: e.target.value });
  };
  const handleHigherSubjectGroup = (e) => {
    formData.study === "1"
      ? setHigherSubject(e.target.value)
      : setHigherSubject();
    setFormData({ ...formData, higherSubjects: e.target.value });
  };
  const onFileChange = (files) => {
    setImages(files);

    setFormData({ ...formData, idCard: files[0] });
  };

  return (
    <div className="px-md-5">

      {formData.study === "3" ? (
        <div>
          {/* Company modal */}
          <input
            className="w-100 mb-3 py-2 ps-3"
            style={{
              border: "1px solid #DFE1E1",
              background: "#FAFAFA",
              fontWeight: "600",
              fontSize: "0.8rem",
              color: "#515C5A",
            }}
            value={company.NAME}
            onClick={() => handleCompanyClick(company)}
            type="text"
            placeholder="Select Company"
          />
          <CompanySearchModal
            show={companyShow}
            onHide={handleCompanyClose}
            setCompany={setCompany}
          />

          <select
            className="form-select form-select-lg mb-3"
            onChange={handleCategory}
            aria-label=".form-select-lg example"
            style={{
              border: "1px solid #DFE1E1",
              background: "#FAFAFA",
              fontWeight: "600",
              fontSize: "0.8rem",
              color: "#515C5A",
            }}
            required>
            <option selected>Select Category</option>
            {categorySelect.map((companySet, index) => {
              return <option key={index} value={companySet.ID}>{companySet.NAME}</option>;
            })}
          </select>

          <select
            className="form-select form-select-lg mb-3"
            onChange={handleJobRole}
            aria-label=".form-select-lg example"
            style={{
              border: "1px solid #DFE1E1",
              background: "#FAFAFA",
              fontWeight: "600",
              fontSize: "0.8rem",
              color: "#515C5A",
            }}
            required>
            <option selected>Select Job role</option>
            {jobRoleSelect.map((jobRole, index) => {
              return <option key={index} value={jobRole.ID}>{jobRole.NAME}</option>;
            })}
          </select>
          <select
            className="form-select form-select-lg mb-3"
            onChange={handleYOE}
            aria-label=".form-select-lg example"
            style={{
              border: "1px solid #DFE1E1",
              background: "#FAFAFA",
              fontWeight: "600",
              fontSize: "0.8rem",
              color: "#515C5A",
            }}
            required>
            <option selected>Select Experience in year</option>
            {yearOfExperienceSelect.map((yoe, index) => {
              return <option key={index} value={yoe.ID}>{yoe.NAME}</option>;
            })}
          </select>
        </div>
      ) : (
        <></>
      )}

      {formData.study === "2" ? (
        <div>
          {/* College Modal */}
          <input
            className="w-100 mb-3 py-2 ps-3"
            style={{
              border: "1px solid #DFE1E1",
              background: "#FAFAFA",
              fontWeight: "600",
              fontSize: "0.8rem",
              color: "#515C5A",
            }}
            value={college.NAME}
            onClick={() => handleCollegeClick(college)}
            type="text"
            placeholder="Select College"
          />
          <CollegeSearchModal
            show={collegeShow}
            onHide={handleCollegeClose}
            setCollege={setCollege}
          />
          {/* Degree Dropdown */}
          <select
            className="form-select form-select-lg mb-3"
            onChange={handleDegree}
            aria-label=".form-select-lg example"
            style={{
              border: "1px solid #DFE1E1",
              background: "#FAFAFA",
              fontWeight: "600",
              fontSize: "0.8rem",
              color: "#515C5A",
            }}
          >
            <option selected>Select Degree</option>
            {streamSelect.map((streamSet, index) => {
              return <option key={index} value={streamSet.ID}>{streamSet.NAME}</option>;
            })}
            {/* <option value="B.E." selected={(formData.degree == "B.E.") ? true : false}>B.E.</option>
            <option value="B.Sc" selected={(formData.degree == "B.Sc") ? true : false} >B.SC.</option>
            <option value="BCA" selected={(formData.degree == "BCA") ? true : false}>BCA</option> */}
          </select>
          {/* Department Dropdown */}
          <select
            className="form-select form-select-lg mb-3"
            onChange={handleDepartment}
            aria-label=".form-select-lg example"
            style={{
              border: "1px solid #DFE1E1",
              background: "#FAFAFA",
              fontWeight: "600",
              fontSize: "0.8rem",
              color: "#515C5A",
            }}
          >
            <option selected>Select Departmants</option>
            {groupSelect.map((groupSet, index) => {
              return <option key={index} value={groupSet.ID}>{groupSet.NAME}</option>;
            })}
          </select>
          {/* Pursuing Year */}
          <select
            className="form-select form-select-lg mb-3"
            onChange={handlePursueYears}
            aria-label=".form-select-lg example"
            style={{
              border: "1px solid #DFE1E1",
              background: "#FAFAFA",
              fontWeight: "600",
              fontSize: "0.8rem",
              color: "#515C5A",
            }}
          >
            <option selected>Pursuing Year</option>
            {pursueSelect.map((pursue, index) => {
              return <option key={index} value={pursue.ID}>{pursue.NAME}</option>;
            })}
          </select>
        </div>
      ) : (
        <></>
      )}
      {formData.study === "1" || formData.study === "" ? (
        <div>
          <input
            className="w-100 mb-3 py-2 ps-3"
            style={{
              border: "1px solid #DFE1E1",
              background: "#FAFAFA",
              fontWeight: "600",
              fontSize: "0.8rem",
              color: "#515C5A",
            }}
            value={school.NAME}
            onClick={() => handleSchoolClick(school)}
            type="text"
            placeholder="Select School"
          />
          <SchoolSearchModal
            show={schoolShow}
            onHide={handleSchoolClose}
            setSchool={setSchool}
          />
          <select
            className="form-select form-select-lg mb-3"
            onChange={handleClass}
            aria-label=".form-select-lg example"
            style={{
              border: "1px solid #DFE1E1",
              background: "#FAFAFA",
              fontWeight: "600",
              fontSize: "0.8rem",
              color: "#515C5A",
            }}
          >
            <option selected>select class</option>
            {classId.map((classSet, index) => {
              return <option key={index} value={classSet.ID}>{classSet.NAME}</option>;
            })}
          </select>

          {formData.classes === "11" || formData.classes === "12" ? (
            <div>
              <select
                className="form-select form-select-lg mb-3"
                onChange={handleHigherGroup}
                aria-label=".form-select-lg example"
                style={{
                  border: "1px solid #DFE1E1",
                  background: "#FAFAFA",
                  fontWeight: "600",
                  fontSize: "0.8rem",
                  color: "#515C5A",
                }}
              >
                <option selected>Select Group</option>
                {higherGroupSelect.map((classSet, index) => {
                  return <option key={index} value={classSet.ID}>{classSet.NAME}</option>;
                })}
              </select>

              <select
                className="form-select form-select-lg mb-3"
                onChange={handleHigherSubjectGroup}
                aria-label=".form-select-lg example"
                style={{
                  border: "1px solid #DFE1E1",
                  background: "#FAFAFA",
                  fontWeight: "600",
                  fontSize: "0.8rem",
                  color: "#515C5A",
                }}
              >
                <option selected>Select Subject</option>
                {higherSubjectSelect.map((classSet, index) => {
                  return <option key={index} value={classSet.ID}>{classSet.NAME}</option>;
                })}
              </select>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}

      <div
        style={{
          fontWeight: 700,
          fontSize: "0.8rem",

        }}
      >
        <label>Upload Id card image</label>
        <DropFileInput onFileChange={(files) => onFileChange(files)} />
      </div>
    </div>
  );
}

export default ProfilePageTwo;
