import React, {useState, useEffect} from "react";
import Gj_Side_Logo from "../images/gj_side_logo.png";
import Blog_admin_side_img from "../images/sign_in_side_img.png";
import { ToastContainer } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import {auth, sendPasswordReset} from "./Firebase";

function ForgetPassword() {
 const [email, setEmail] = useState("");
 const [user, loading, error] = useAuthState(auth);
 const navigate = useNavigate();
 useEffect(()=>{
    if(loading)return;
    if(user) navigate("/dashboard");
 }, [user, loading])

const signinWidth = window.innerWidth;
  return (
    <div>
      <div className=" container-fluid ">
        <div className="sign_in_page">
          <div
            className={
              signinWidth < 767
                ? "row px-2 px-md-0 py-1"
                : signinWidth < 991
                ? "row py-5 px-2 "
                : "row signin_resolution  "
            }
          >
            <div className="signin_form  mt-lg-5  col-lg-6 col-md-12 col-sm-12 col-xs-12">
              {signinWidth < 991 ? (
                <div className="d-flex  justify-content-between py-2">
                  <div>
                    {" "}
                    <i
                      className="fa fa-long-arrow-left "
                      style={{ fontSize: "1.5rem" }}
                    ></i>
                    <a
                      href="https://guidejuniors.com/"
                      className="text-decoration-none text-dark"
                    >
                      <span
                        className="ps-2 "
                        style={{ fontSize: "0.8rem", fontWeight: "600" }}
                      ></span>
                    </a>
                  </div>
                  <div>
                    <img src={Gj_Side_Logo} width="30" alt="GJ_LOGO" />
                  </div>
                </div>
              ) : (
                <div className="home_navigation_signin">
                  <i
                    className="fa fa-long-arrow-left "
                    style={{ fontSize: "0.8rem" }}
                  ></i>
                  <a
                    href="https://guidejuniors.com/"
                    className="text-decoration-none text-dark"
                  >
                    <span
                      className="ps-2 "
                      style={{ fontSize: "0.8rem", fontWeight: "600" }}
                    >
                      Home
                    </span>
                  </a>
                </div>
              )}
              <div className=" px-md-5 signin_form  mobile_width_signin ">
                <p style={{ fontColor: "#515C5A" }}>
                  <b>Forget Password?</b>
                </p>

                <div>
                  <div
                    style={{
                      color: "#6C7674",
                      fontSize: "0.8rem",
                      fontWeight: "500",
                      lineHeight: "24px",
                    }}
                  >
                    {" "}
                    Please enter your Email Id
                  </div>
                  <div >
                    <div className="py-1 mt-2" style={{ fontSize: "0.8rem" }}>
                      {" "}
                      <div className="mb-2">
                        <label>
                          <b>Email address</b>
                        </label>
                      </div>
                      <input
                        type="email"
                        className="resolution_width_signin"
                        style={{
                          height: "60px",
                          width: "400px",
                          backgroundColor: " #FAFAFA",
                          borderRadius: "8px",
                          border: " 1.2px solid #DFE1E1",
                          padding: "20px",
                        }}
                        placeholder="Your Email Address"
                        required
                        value={email}
                        onChange={(e)=> setEmail(e.target.value)}
                      />
                    </div>
                    <div
                    className="py-1 mt-2 resolution_width_signin"
                    style={{ fontSize: "0.8rem", width: "400px" }}
                  >
                    <button
                      type="submit"
                      className="btn resolution_width_signin"
                      style={{
                        height: "60px",
                        width: "400px",
                        background: "#FFAA3B",
                        fontSize: "0.8rem",
                        borderRadius: "8px",
                      }}
                      placeholder="SUBMIT"
                      onClick={() => sendPasswordReset(email)}
                    >
                      <b>Send Password Reset Email</b>
                    </button>
                  </div>
                  </div>
                </div>
                <div
                  align="center"
                  className="mt-3 resolution_width_signin"
                  style={{
                    fontSize: "0.8rem",
                    fontWeight: "500",
                    width: "400px",
                  }}
                >
                  Don't have an account yet?
                  <span>
                    <Link
                      className="ps-2 text-decoration-none"
                      style={{ color: "#FFAA3B" }}
                      to="/sign-up"
                    >
                      <b>Sign up</b>
                    </Link>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-6 display_hidden_medium_signin ">
              <div className="float-end" style={{ margin: "-12px" }}>
                <img
                  src={Blog_admin_side_img}
                  className="img-fluid "
                  alt="blog_admin"
                  style={{ height: "100vh" }}
                />
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}
export default ForgetPassword;
