import React from 'react'
import circle_img from '../images/circle.png'
import avatar from '../images/avatar.png'
import { useState, useEffect, useContext } from 'react'
import qs from 'qs'
import axios from 'axios'
import DOMPurify from 'dompurify'

function MentorsChatHistory (props) {
  const decodeNames = x => {
    if (x) {
      let r = /\\u([\d\w]{4})/gi
      x = x.replace(r, function (match, grp) {
        return String.fromCharCode(parseInt(grp, 16))
      })
    }

    return x
  }
  const userDeviceKey = localStorage.getItem('DEVICE_KEY')
  function DateFormat (unixTimestamp) {
    let date = new Date(unixTimestamp * 1000)
    return date.toLocaleTimeString([], { timeStyle: 'short' })
  }

  const [mentorChatHistory, setMentorChatHistory] = useState([])
  useEffect(() => {
    const reqData = {
      mod: 'Chat',
      actionType: props.actionType,
      subAction: JSON.stringify({
        DEVICE_KEY: userDeviceKey,
        SEARCH_KEY: props.searchKey
      })
    }
    
    axios({
      method: 'post',
      url: 'https://dev-api.guidejuniors.com/',
      data: qs.stringify(reqData),
      header: { 'content-type': 'application/x-www-form-urlencoded' }
    }).then(res => {
      let resData = res.data
      setMentorChatHistory(resData.XSCData.LIST)
    })
  }, [props])

  const mentorList = mentorChatHistory.sort((a, b) =>
    a.MESSAGE_DETAILS.UTC_DATETIME < b.MESSAGE_DETAILS.UTC_DATETIME ? 1 : -1
  )
 console.log(mentorList);
  const tempDate = new Date()
  const date =
    tempDate.getDate()+
    '/' +
    (tempDate.getMonth() + 1) +
    '/' +
    tempDate.getFullYear() ;
  const currDate =  date;

  function getMyCreateDate (messageDate) {
    
    const DateFormat = (messageDate === '')? messageDate: new Date(messageDate).getDate() +'/' +(new Date(messageDate).getMonth() + 1) +'/' +new Date(messageDate).getFullYear();

    return DateFormat
  }
  
  return (
    <>
      <div className='message_scroll'>
        {mentorChatHistory.map((data, index) => {
          return (
            <>
              <div
              key={index}
                className='d-flex py-3 px-1'
                
                onClick={() => props.handleChat(data)}
                style={{ cursor: 'pointer' }}
              >
                <div>
                  <img
                    src={
                      data.IMAGE_URL === ''
                        ? avatar
                        : data.IMAGE_URL.includes('https://')
                        ? data.IMAGE_URL
                        : 'https://dev-api.guidejuniors.com/' + data.IMAGE_URL
                    }
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null // prevents looping
                      currentTarget.src = avatar
                    }}
                    style={{
                      borderRadius: '50%',
                      height: '40px',
                      width: '40px',
                      objectFit: 'cover'
                    }}
                    alt=''
                  ></img>
                  {data.IS_ONLINE === '1' ? (
                    <span
                      style={{
                        position: 'relative',
                        left: '-15px',
                        top: '15px'
                      }}
                    >
                      <img src={circle_img} alt='' />
                    </span>
                  ) : (
                    ''
                  )}
                </div>
                <div
                  className='ps-2'
                  style={{
                    fontSize: '0.7rem',
                    width: '60%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  <b>{data.FULL_NAME}</b>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        decodeNames(data.MESSAGE_DETAILS.MESSAGE)
                      )
                    }}
                  ></p>

                  {/* <p>{decodeNames(data.MESSAGE_DETAILS.MESSAGE)}</p> */}
                </div>

                <div
                  className=' position-absolute pe-3'
                  style={{ right: '0%' }}
                >
                  <div style={{ fontSize: '0.7rem' }}>
                    {data.MESSAGE_DETAILS.UTC_DATETIME === ''
                      ? ''
                      : getMyCreateDate(data.MESSAGE_DETAILS.CREATED_DATE) ===
                        currDate
                      ? DateFormat(data.MESSAGE_DETAILS.UTC_DATETIME)
                      : getMyCreateDate(data.MESSAGE_DETAILS.CREATED_DATE)}
                  </div>
                  {data.MESSAGE_DETAILS.UNREAD_COUNT != '0' ? (
                    <div
                      style={{
                        fontSize: '0.7rem',
                        backgroundColor: '#FBA93E',
                        borderRadius: '50%',
                        width: '20px',
                        height: '20px',
                        textAlign: 'center',
                        marginLeft: '13px',
                        paddingTop: '2px'
                      }}
                      className='text-align-center ml-3'
                    >
                      {' '}
                      {data.MESSAGE_DETAILS.UNREAD_COUNT}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>{' '}
            </>
          )
        })}
      </div>
    </>
  )
}

export default MentorsChatHistory