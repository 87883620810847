import React, { useEffect, useState } from 'react'
import { Col, Nav, Row, Tab } from 'react-bootstrap'
import EditTopBar from './EditTopBar';
import suggested_materials from "../images/suggested_material.png"
import edit_icon from "../images/edit_icon.png";
import delete_icon from "../images/delete_icon.png";
import add_material_btn from "../images/add_material_btn.png"
import "../Styles/EditMaterial.css";
import axios from 'axios';
import qs from 'qs';
import { useLocation, useNavigate } from 'react-router-dom';
import Add from "./MaterialUploadForm/MaterialUpload"
import sample_img from "../images/user_profile_material.jpg";
import Swal from "sweetalert2";
import { toast, ToastContainer } from 'react-toastify';


// import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function Example(props) {
    const userDeviceKey = localStorage.getItem("DEVICE_KEY");

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleDelete = () => {
        const reqData = {
            mod: "Materials",
            actionType: "remove-material",
            subAction: JSON.stringify({
                DEVICE_KEY: userDeviceKey,
                MATERIAL_ID: [props.delete_id],

            }),
        };


        axios({
            method: "post",
            url: "https://dev-api.guidejuniors.com/",
            data: qs.stringify(reqData),
            header: { "content-type": "application/x-www-form-urlencoded" },

        }).then((res) => {
            let sample = res.data;
            if (sample.XSCStatus === 0) {

                window.location.reload(false);

            }
            else {
                alert("not submitted")
            }

        });

    }

    return (
        <>
            <img src={delete_icon} width="20" height="20" style={{ objectFit: "cover", cursor: "pointer" }} alt="imgss" onClick={handleShow} />
            {/* <Button variant="primary" >
        Launch demo modal
      </Button> */}
            <Modal centered size="sm" show={show} onHide={handleClose} style={{ borderRadius: "10px" }}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: "0.9rem", fontWeight: "700" }}>Delete Material</Modal.Title>
                </Modal.Header>
                <Modal.Body align="center" style={{ fontSize: "0.9rem", fontWeight: "600" }}>Are you sure you want to delete the material ?
                    “{props.delte_title} ”</Modal.Body>
                <Modal.Footer style={{ border: "none" }}>
                    <Button onClick={handleClose} style={{ boxShadow: "none", fontSize: "0.9rem", background: "none", color: "black", border: "none", fontWeight: "600" }}>
                        Keep it
                    </Button>
                    <Button style={{ boxShadow: "none", color: "#F44141", background: "rgba(244, 65, 65, 0.16)", border: "none", fontWeight: "600", padding: "20px", fontSize: "0.9rem", }} onClick={handleDelete} >
                        Delete
                    </Button>
                </Modal.Footer>

            </Modal>
            {/* <ToastContainer /> */}
        </>
    );
}




export default function EditMaterial() {

    const location = useLocation();


    function UpdateSoft(mat) {

        navigate("/user-profile/edit-profile/edit-materials/update-materials", {
            state: {

                title_name: mat.NAME,
                des: mat.DESCRIPTION,
                pri: mat.PRICE,
                mat_id: mat.MATERIAL_ID,
                category: 'STUDY MATERIAL',
                img_url: mat.IMAGE_URL,
                check_price: mat.PRICE <= 1 ? true : false,
                file_url: mat.MATERIAL_URL,
            }
        })

    }

    function UpdateHard(mat) {

        navigate("/user-profile/edit-profile/edit-materials/update-materials", {
            state: {

                title_name: mat.NAME,
                des: mat.DESCRIPTION,
                pri: mat.PRICE,
                mat_id: mat.MATERIAL_ID,
                category: 'BOOK',
                img_url: mat.IMAGE_URL,
                check_price: mat.PRICE <= 1 ? true : false,
                file_url: mat.MATERIAL_URL,


            }
        })

    }


    const navigate = useNavigate();
    // const [notificationAction, setNotificationAction] = useState({});

    const [submit, setSubmit] = useState(false);
    // console.log(submit);

    const [softcopy, setSoftcopy] = useState([]);
    const [hardcopy, setHardcopy] = useState([]);
    const decodeNames = (x) => {
        if (x) {
            let r = /\\u([\d\w]{4})/gi;
            x = x.replace(r, function (match, grp) {
                return String.fromCharCode(parseInt(grp, 16));
            });
        }

        return x;
    };
    const userDeviceKey = localStorage.getItem("DEVICE_KEY");

    // useEffect(()=>{
    // if(notificationAction.mod){
    //  axios({
    //         method: "post",
    //         url: "https://dev-api.guidejuniors.com/",
    //         data: qs.stringify(notificationAction),
    //         header: { "content-type": "application/x-www-form-urlencoded" },

    //     }).then((res) => {
    //         let sample = res.data;
    //         // console.log(sample.XSCData.LIST);
    //         if (sample.XSCStatus === 0) {
    //         Swal.fire("error", "", "info");

    //         }
    //       Swal.fire("Deleted Successfully", "", "success");
    //        setTimeout(function(){
    //         window.location.reload(1);
    //      }, 2000);

    //     });


    // }

    // },[notificationAction])


    // const handleDelete=(deleteId,change)=>{
    // Swal.fire({
    //     title:change===1?"Are you Sure Delete Soft Copy?":"Are you Sure Delete Hard Copy?",
    //     showCancelButton:true,
    //     confirmButtonText:"Delete"

    // }).then((result)=>{
    //     if(result.isConfirmed){
    //            const reqData = {
    //         mod: "Materials",
    //         actionType: "remove-material",
    //         subAction: JSON.stringify({

    //             DEVICE_KEY:"PI2kcXCmdhQqNNNbf5n4Q4wT4Vt1",
    //             MATERIAL_ID:[deleteId.MATERIAL_ID],

    //         }),
    //     };
    //         setNotificationAction(reqData);

    //     }

    // })
    //     // console.log(deleteId.MATERIAL_ID);

    //     // axios({
    //     //     method: "post",
    //     //     url: "https://dev-api.guidejuniors.com/",
    //     //     data: qs.stringify(reqData),
    //     //     header: { "content-type": "application/x-www-form-urlencoded" },

    //     // }).then((res) => {
    //     //     let sample = res.data;
    //     //     console.log(sample.XSCData.LIST);
    //     //     if(sample.XSCStatus===0){
    //     //         alert('deleted succesfully')
    //     //       window.location.reload(false);
    //     //     }

    //     // });



    // }

    useEffect(() => {
        const reqData = {
            mod: "Materials",
            actionType: "get-user-material-list",
            subAction: JSON.stringify({
                DEVICE_KEY: userDeviceKey,
                CATEGORY: "STUDY MATERIAL"
                // CURRENT_PAGE: "1",


            })
        }

        axios({
            method: "post",
            url: "https://dev-api.guidejuniors.com/",
            data: qs.stringify(reqData),
            header: { "content-type": "application/x-www-form-urlencoded" },

        }).then((res) => {
            let sample = res.data;
            console.log(sample.XSCData);
            setSoftcopy(sample.XSCData
            );
        });
    }, [])
    useEffect(() => {
        const reqData = {
            mod: "Materials",
            actionType: "get-user-material-list",
            subAction: JSON.stringify({
                DEVICE_KEY: userDeviceKey,
                CATEGORY: "BOOK"
                // CURRENT_PAGE: "1",


            })
        }

        axios({
            method: "post",
            url: "https://dev-api.guidejuniors.com/",
            data: qs.stringify(reqData),
            header: { "content-type": "application/x-www-form-urlencoded" },

        }).then((res) => {
            let sample = res.data;
            // console.log(sample);
            setHardcopy(sample.XSCData);
        });
    }, [])
    return (
        <div>
            <EditTopBar />
            <div className='row ps-2 w-100 py-2' >
                <div className='col-lg-3'>
                    <div className='py-2' style={{ fontWeight: 600 }}>
                        <a style={{ textDecoration: "none", color: "black" }} href="/user-profile">My Profile</a> {" "}{">"}{" "} Books & Materials
                    </div>

                </div>
                <div className='col-lg-6  order-3 order-lg-2 pt-3'>
                    <Tab.Container id="justify-tabs-example" defaultActiveKey="soft_copy">


                        <Nav className="flex-row edit-material-tab" >
                            <Nav.Item style={{ color: "black" }}>
                                <Nav.Link eventKey="soft_copy" style={{ color: "black", fontWeight: "600" }}>
                                    Soft Copy
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="hard_copy" style={{ color: "black", fontWeight: "600" }}>
                                    Hard Copy
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>


                        <Tab.Content className='my-3'>
                            <Tab.Pane eventKey="soft_copy">
                                <table style={{ width: "100%" }}>

                                    {
                                        softcopy.map((soft, index) => {
                                            const getProfileImage = (imageUrl) => {
                                                if (!imageUrl) {
                                                    return sample_img;
                                                }
                                                if (imageUrl.includes("http")) {
                                                    return imageUrl;
                                                }
                                                return "https://dev-api.guidejuniors.com/" + imageUrl;
                                            };
                                            return (
                                                <tr key={index}>
                                                    <td style={{ width: "10%", fontWeight: "700" }}>{index + 1}</td>
                                                    <td style={{ width: "80%", fontSize: "0.8rem" }}>
                                                        <div className='d-flex'>
                                                            <div>
                                                                <img src={getProfileImage(soft.IMAGE_URL)} width="50" height="50" style={{ borderRadius: "10%", objectFit: "cover" }} />
                                                            </div>
                                                            <div className='ps-2'>
                                                                <div style={{ fontWeight: "700" }}>{soft.NAME}</div>
                                                                <div style={{ fontWeight: "500", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", width: "150px" }}>{decodeNames(soft.DESCRIPTION)}</div>
                                                                <div>{soft.PRICE === "0" ? "Free" : <div>₹{" "}{soft.PRICE}</div>}</div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td style={{ width: "10%" }}>
                                                        <div className='d-flex' style={{ justifyContent: "space-between" }}>

                                                            <img src={edit_icon} width="20" height="20" style={{ objectFit: "cover", cursor: "pointer" }} alt="imgs"
                                                                onClick={() => {

                                                                    UpdateSoft(soft)

                                                                }}
                                                            />{" "}
                                                            <Example delte_title={soft.NAME} delete_id={soft.MATERIAL_ID} />

                                                            {/* <img src={delete_icon} width="20" height="20" style={{objectFit:"cover",cursor:"pointer"}} alt="imgss" onClick={()=>{
                                                            
                                                            handleDelete(soft,1)

                                                        }}/> */}

                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }

                                </table>
                            </Tab.Pane>
                            <Tab.Pane eventKey="hard_copy">
                                <table style={{ width: "100%" }}>



                                    {
                                        hardcopy.map((hard, index) => {
                                            const getProfileImage = (imageUrl) => {
                                                if (!imageUrl) {
                                                    return sample_img;
                                                }
                                                if (imageUrl.includes("http")) {
                                                    return imageUrl;
                                                }
                                                return "https://dev-api.guidejuniors.com/" + imageUrl;
                                            };
                                            return (
                                                <tr key={index}>
                                                    <td style={{ width: "10%", fontWeight: "700" }}>{index + 1}</td>
                                                    <td style={{ width: "80%", fontSize: "0.8rem" }}>
                                                        <div className='d-flex'>
                                                            <div>
                                                                <img src={getProfileImage(hard.IMAGE_URL)} width="50" height="50" style={{ borderRadius: "10%", objectFit: "cover" }} />
                                                            </div>
                                                            <div className='ps-2'>
                                                                <div style={{ fontWeight: "700" }}>{hard.NAME}</div>
                                                                <div style={{ fontWeight: "500", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", width: "150px" }}>{decodeNames(hard.DESCRIPTION)}</div>
                                                                <div>{hard.PRICE === "0" ? "Free" : <div>₹{" "}{hard.PRICE}</div>}</div>

                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td style={{ width: "10%" }}>
                                                        <div className='d-flex' style={{ justifyContent: "space-between" }}>
                                                            <img src={edit_icon} width="20" height="20" style={{ objectFit: "cover", cursor: "pointer" }} alt="img"
                                                                onClick={()  => {

                                                                    UpdateHard(hard)

                                                                }}
                                                            />{" "}
                                                            <Example delte_title={hard.NAME} delete_id={hard.MATERIAL_ID} />
                                                            {/* <img src={delete_icon} width="20" height="20" style={{objectFit:"cover",cursor:"pointer"}}

                                                        alt="imgss" onClick={()=>{
                                                            
                                                            handleDelete(hard,2)

                                                        }}
                                                        /> */}
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </table>

                            </Tab.Pane>
                        </Tab.Content>


                    </Tab.Container>
                </div>
                <div className='col-lg-3 order-2 order-lg-3' style={{ fontWeight: "700" }}>
                    <a href="/user-profile/edit-profile/edit-materials/add-materials" className='py-2'><img src={add_material_btn} style={{ objectFit: "cover" }} width="30" height="30" /></a> Add materials
                </div>
                <div style={{ display: "none" }}>

                    <Add setAlert={setSubmit} />

                </div>

                {

                    submit === true ? <>{alert("submitted")}</> : <></>
                }
            </div>
        </div>
    )
}
