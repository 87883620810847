import React, { useEffect, useState } from "react";
import filter_img from "../images/filter_img.png";
import suggested_materials from "../images/suggested_material.png";
import dollar_symbol from "../images/dollar_symbol.png";
import search_icon from "../images/search_icon.png";
import TopBar from "./TopBar";
import Sidemenu from "./Sidemenu";
import axios from "axios";
import qs from "qs";
// import Select from "react-select";
import Select from "react-dropdown-select";
import { Form } from 'react-bootstrap';
import {FiFilter} from "react-icons/fi";
// import {useRef} from 'react';
import suggested_material from "../images/suggested_material.png";
import suggested_material2 from "../images/suggested_material2.png";
import suggested_material3 from "../images/suggested_material3.png";
import suggested_material4 from "../images/suggested_material4.png";
function MaterialDetails(props) {
  const userDeviceKey = localStorage.getItem("DEVICE_KEY");
  const [material, setMaterial] = useState("");
  useEffect(() => {
    const reqData = {
      mod: "Materials",
      actionType: "get-material-details",
      subAction: JSON.stringify({
        DEVICE_KEY: userDeviceKey,
        MATERIAL_ID: props.materialId,
      }),
    };

    axios({
      method: "post",
      url: "https://dev-api.guidejuniors.com/",
      data: qs.stringify(reqData),
      header: { "content-type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      let sample = res.data;
      setMaterial(sample.XSCData.MATERIAL_DETAILS);
    });
  }, []);
  return (
    <div
      className="card  rounded"
      style={{ zIndex: "1", position: "absolute", fontSize: "0.7rem" }}
    >
      <div
        className="d-flex justify-content-between px-2 py-2 "
        style={{ backgroundColor: "#FDCD8F" }}
      >
        <div className="py-2">
          <b>Material Details</b>
        </div>
        <div style={{ fontSize: "1.4rem" }} onClick={props.show}>
          &times;
        </div>
      </div>
      <div className="card-body">
        <table>
          <tr>
            <td>TITLE</td>
            <td>{material.TITLE}</td>
          </tr>
          <tr>
            <td>TYPE</td>
            <td>{material.TYPE}</td>
          </tr>
          <tr>
            <td>CLASS NAME</td>
            <td>{material.CLASS_NAME}</td>
          </tr>
          <tr>
            <td>SUBJECT NAME</td>
            <td>{material.SUBJECT_NAME}</td>
          </tr>
        </table>
      </div>
    </div>
  );
}

export default function NotesMaterials() {
  // const inputRef = useRef(null)
  const [notes, setNotes] = useState([]);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState({
    classes: [],
    // stream: [],
    subjects: [],
    materials: [],
    cost: [],
  });
  const [show, setShow] = useState(false);
  const [filterShow, setFilterShow] = useState(false);
  const [idPass, setIdPass] = useState("");
  const userDeviceKey = localStorage.getItem("DEVICE_KEY");
  const [selectclass, setSelectClass] = useState([]);
  const [selectStream, setSelectStream] = useState([]);
  const [selectSubjects, setSelectSubjects] = useState([]);
  const [selectMaterials, setSelectMaterials] = useState([]);
  const [selectCost, setSelectCost] = useState([]);

  const handleShow = (material) => {
    setShow(true);
    setIdPass(material);
  };
  useEffect(() => {
    const reqData = {
      mod: "GJ",
      actionType: "get-master-filter-list",
      subAction: JSON.stringify({
        DEVICE_KEY: userDeviceKey,
        FILTER_TYPE: "MATERIAL",
      }),
    };

    axios({
      method: "post",
      url: "https://dev-api.guidejuniors.com/",
      data: qs.stringify(reqData),
      header: { "content-type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      let sample = res.data;
      if(sample!==null){
 setFilter({
        classes: sample.XSCData[0].FILTERS,
        // stream: sample.XSCData[1].FILTERS,
        subjects: sample.XSCData[1].FILTERS,
        materials: sample.XSCData[2].FILTERS,
        cost: sample.XSCData[3].FILTERS,
      });
      }
    });
  }, [userDeviceKey]);
  const classOptions = filter.classes.map((count) => {
    return {
      value: count.ID,
      label: count.NAME,
    };
  });
  const handleSelectClass = (e) => {
    setSelectClass(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  // const streamOptions = filter.stream.map((count) => {
  //     return (
  //         {
  //             value: count.ID,
  //             label: count.NAME
  //         }

  //     )
  // });

  // const handleSelectStream = (e) => {
  //     setSelectStream(Array.isArray(e) ? e.map(x => x.value) : []);

  // }
  const subjectsOptions = filter.subjects.map((count) => {
    return {
      value: count.ID,
      label: count.NAME,
    };
  });

  const handleSelectSubjects = (e) => {
    setSelectSubjects(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const handleSelectCost = (e) => {
    setSelectCost(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  const costOptions = filter.cost.map((count) => {
    return {
      value: count.ID,
      label: count.NAME,
    };
  });

  const handleSelectMaterials = (e) => {
    setSelectMaterials(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  const materialsOptions = filter.materials.map((count) => {
    return {
      value: count.ID,
      label: count.NAME,
    };
  });
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    const reqData = {
      mod: "Materials",
      actionType: "get-notes-and-materials-list",
      subAction: JSON.stringify({
        DEVICE_KEY: userDeviceKey,
        CURRENT_PAGE: "1",
        SEARCH_KEY: search,
        FILTERS: [
          { ID: "1", SELECTED: selectclass },
          { ID: "2", SELECTED: selectStream },
          { ID: "3", SELECTED: selectSubjects },
          { ID: "4", SELECTED: selectMaterials },
          { ID: "5", SELECTED: selectCost },
        ],
      }),
    };

   
    axios({
      method: "post",
      url: "https://dev-api.guidejuniors.com/",
      data: qs.stringify(reqData),
      header: { "content-type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      let sample = res.data;
     
if(sample.XSCData!=0){
  setNotes(sample.XSCData.LIST);
}

    });
  }, [
    search,
    selectclass,
    selectStream,
    selectSubjects,
    selectMaterials,
    selectCost,
    userDeviceKey
  ]);
  const Materials=[
    {
      IMAGE_URL:suggested_material,
      TITLE:"Physics Volume III",
      DESCRIPTION:"It is the Physics chapter III book of higher secondary in the application",
      PRICE:"559",
  },
    {
      IMAGE_URL:suggested_material2,
      TITLE:"Chemistry Volume I",
      DESCRIPTION:"It is the Chemistry chapter I book of higher secondary in the application",
      PRICE:"399",
  },
    {
      IMAGE_URL:suggested_material3,
      TITLE:"Mathematics Volume II",
      DESCRIPTION:"It is the Mathematics chapter II book of higher secondary in the application",
      PRICE:"525",
  },
    {
      IMAGE_URL:suggested_material4,
      TITLE:"Biology ",
      DESCRIPTION:"It is the Biology book which helps in the project management",
      PRICE:"615",
  },
  
  ]
  return (
    <>
      <div className="container-fluid">
        <div className="row mb-lg-0 mb-5">
          <Sidemenu />
          <div
            className="col-lg-10 mt-2"
            style={{ borderLeft: "2px solid #EBEDEC" }}
          >
            <TopBar />
            <div className="pe-5 pt-2 pb-2 ">
              <div>
                <h5>
                  <b>Notes & Materials</b>
                </h5>
              </div>
              
              <div className="d-md-flex w-100" style={{ fontSize: "0.7rem" }}>
                <div className="mx-2 my-2 ">
                  <div className="d-md-flex w-100 ">
                    <div className="d-flex">
                      <div
                      className="w-100"
                      style={{ position: "relative", display: "inlineBlock" }}
                    >
                      <span
                        className="my-2"
                        style={{ position: "absolute", left: "0", top: "1px" }}
                      >
                        <img src={search_icon} width="20" className="ps-1" />
                      </span>
                      <input
                        className="rounded"
                        placeholder="Search for Materials"
                        style={{
                          backgroundColor: "#F5F6F6",
                          border: "none",
                          paddingLeft: "30px",
                          width: "100%",
                          height: "38px",
                        }}
                        onChange={handleSearch}
                      />
                    </div>
                    <div className="d-md-none ps-3 mt-1" >
                    <FiFilter style={{fontSize:"1.6rem",color:"#374957",cursor:"pointer"}} onClick={() => (filterShow) ? setFilterShow(false) : setFilterShow(true)}/>
                    {/* <img src={filter_img} alt="filter_img" onClick={() => (filterShow) ? setFilterShow(false) : setFilterShow(true)} /> */}
                    </div>
                    </div>
                    
                    <div>
                        {
                  filterShow ? <div className="d-md-flex">
                  
                    <div className="me-1 ms-md-2 ms-1 my-2 my-md-0 w-100" style={{minWidth:"26%"}}>
                      <Select
                        options={classOptions}
                      
                        value={classOptions.filter((obj) =>
                          selectclass.includes(obj.value)
                        )}
                        multi
                        placeholder="Select class"
                        onChange={handleSelectClass}
                      />
                    </div>
                    
                    <div className="mx-1 my-2 my-md-0 w-100" style={{minWidth:"26%"}}>
                      <Select
                        options={costOptions}
                        value={costOptions.filter((obj) =>
                          selectCost.includes(obj.value)
                        )}
                        multi
                        placeholder="Select Cost"
                        onChange={handleSelectCost}
                      />
                    </div>
                    <div className="mx-1 my-2 my-md-0 w-100" style={{minWidth:"26%"}}>
                      <Select
                        options={subjectsOptions}
                        value={subjectsOptions.filter((obj) =>
                          selectSubjects.includes(obj.value)
                        )}
                        multi
                        placeholder="Select Subjects"
                        onChange={handleSelectSubjects}
                      />
                    </div>
                    <div className="mx-1 my-2 my-md-0 w-100" style={{minWidth:"26%"}}>
                      <Select 
                        options={materialsOptions}
                        value={materialsOptions.filter((obj) =>
                          selectMaterials.includes(obj.value)
                        )}
                        multi
                        placeholder="Select Materials"
                        onChange={handleSelectMaterials}
                      />
                    </div>
                  
                  </div> : <></>
                }
                    </div>
                  </div>
                </div>
               
              
                <div className=" mt-md-3 d-md-flex d-none pe-2" style={{position:"absolute",right:"0%"}}  >
                  
                  <div style={{fontSize:"0.9rem"}}>
                    More Filter<FiFilter style={{fontSize:"1.4rem",color:"#374957",cursor:"pointer"}} onClick={() => (filterShow) ? setFilterShow(false) : setFilterShow(true)}/>{/* <img src={filter_img} width="30" height="30"  /> */}
                  </div>
                  
                </div>
                
                {/* <div className=''>
                                    More Filter <span><img src={filter_img} /></span>
                                </div> */}
              </div>
              <div className="row ">
                {Materials.map((notes) => {
                  return (
                    <div className="col-lg-3 col-md-4 col-sm-6">
                      <div className="p-2">
                        <div
                          className="card rounded p-2"
                          style={{ width: "15rem" }}
                        >
                          <div align="center" className="">
                            {notes.IMAGE_URL == "" ? (
                              <img
                                src={suggested_materials}
                                width="100"
                                height="100"
                                alt="Notes"
                                style={{ objectFit: "cover" }}
                              />
                            ) : (
                              <img
                                src={
                                  notes.IMAGE_URL
                                }
                                width="100"
                                height="100"
                                alt="Notes"
                                style={{
                                  borderRadius: "10%",
                                  objectFit: "cover",
                                }}
                              />
                            )}
                          </div>

                          <div className="ps-2">
                            <div style={{ fontSize: "0.9rem" }}>
                              {notes.TITLE}
                            </div>
                            <p
                              className="card-text"
                              style={{ fontSize: "0.9rem" }}
                            >
                              $ {notes.PRICE}
                            </p>
                            <div
                              className="btn text-dark"
                              style={{
                                backgroundColor: "#FDCD8F",
                                fontSize: "0.8rem",
                                cursor:"auto"
                              }}
                            >
                        <>{notes.MATERIAL_URL_NEW!==""?<b>SOFT COPY</b>:<b>HARD COPY</b>}</>
       
                            </div>
                            {/* <div onClick={() => handleShow(notes.MATERIAL_ID)}>
                              ...
                            </div> */}
                          </div>
                        </div>
                        {show && notes.MATERIAL_ID === idPass ? (
                          <MaterialDetails
                            materialId={idPass}
                            show={() => setShow(false)}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
