import React from "react";
import avatar from "../images/avatar.png";
import { useState, useEffect } from "react";
import qs from "qs";
import axios from "axios";
import "../Styles/MessageBar.css";
import right_arrow from "../images/right_arrow.png";
import circle_img from "../images/circle.png";
import { createSearchParams, useNavigate } from "react-router-dom";

function Message() {
  const userDeviceKey = localStorage.getItem("DEVICE_KEY");
  const [chatHistory, setChatHistory] = useState([]);
  const decodeNames = (x) => {
    if (x) {
      let r = /\\u([\d\w]{4})/gi;
      x = x.replace(r, function (match, grp) {
        return String.fromCharCode(parseInt(grp, 16));
      });
    }
    return x;
  };
  useEffect(() => {
    const reqData = {
      mod: "Chat",
      actionType: "get-my-network-list",
      subAction: JSON.stringify({
        DEVICE_KEY: userDeviceKey,
      }),
    };
    axios({
      method: "post",
      url: "https://dev-api.guidejuniors.com/",
      data: qs.stringify(reqData),
      header: { "content-type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      let resData = res.data;
      // console.log(resData);
      setChatHistory(resData.XSCData.LIST);
    });
  }, []);

  function DateFormat(unixTimestamp) {
    let date = new Date(unixTimestamp * 1000);
    date.setSeconds(0);
    return date.toLocaleTimeString([], { timeStyle: "short" });
  }

  const navigate = useNavigate();
  const handleUserChatHistory = (mentor) => {
    const userId = mentor.USER_ID;
    const full_name = mentor.FULL_NAME;
    const img_url = mentor.IMAGE_URL;
    const onOff = mentor.IS_ONLINE;
    navigate({
      pathname: "/messages",
      search: `?${createSearchParams({
        id: userId,
        fullName: full_name,
        imgURL: img_url,
        isOnline: onOff
      })}`,
    });
  };
  const mentoList = chatHistory.sort((a, b) => (a.MESSAGE_DETAILS.UTC_DATETIME < b.MESSAGE_DETAILS.UTC_DATETIME) ? 1 : -1)
  const tempDate = new Date()
  const date =
    tempDate.getDate() +
    '/' +
    (tempDate.getMonth() + 1) +
    '/' +
    tempDate.getFullYear();
  const currDate = date
  // console.log(currDate)
  function getMyCreateDate(messageDate) {
    const DateFormat = (messageDate === '') ? messageDate : new Date(messageDate).getDate() + '/' + (new Date(messageDate).getMonth() + 1) + '/' + new Date(messageDate).getFullYear();
    // console.log(DateFormat)
    return DateFormat
  }
  return (
    
    <div>
      {" "}
      <div className=" ">
        <div className="mt-2 px-3 d-flex justify-content-between">
          <div className="" style={{ fontWeight: "600", fontSize: "1.2rem" }}>Message</div>
          <div>
            <a href="/messages">
              <img
                // className=" mx-5"
                src={right_arrow}
                style={{
                  height: "15px",
                  width: "15x",
                  color: "#424F4C",
                }}
                alt=""
              />
            </a>
          </div>
        </div>

        {
          chatHistory.length === 0 ? <p className="text-center mt-5">No Data Found</p> :
            <div className="px-3">
              {chatHistory.slice(0, 4).map((data, index) => {
                return (
                  <div className="mt-1" key={index}>
                    <div className="d-flex py-1 ps-1" style={{ cursor: "pointer" }}
                      onClick={() => handleUserChatHistory(data)}
                    >
                      <div className="" style={{width:"10%"}}>
                        <img
                          src={
                            data.IMAGE_URL === ""
                              ? avatar
                              : data.IMAGE_URL.includes("https://")
                                ? data.IMAGE_URL
                                : "https://dev-api.guidejuniors.com/" + data.IMAGE_URL
                          }
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = avatar;
                          }}
                          style={{
                            borderRadius: "50%",
                            height: "40px",
                            width: "40px",
                            objectFit: "cover"
                          }}
                          alt=""
                        />
                        {data.IS_ONLINE === "1" ? (
                          <span
                            style={{
                              position: "relative",
                              left: "30px",
                              top: "-20px",
                            }}
                          >
                            <img src={circle_img} alt="" />
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div
                        className="ps-2 mx-2"
                        style={{ fontSize: "0.7rem", width: "60%" }}
                      >
                        <b className="full_name_mgs">{data.FULL_NAME}</b>
                        <p className="single_line_clamp" style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{decodeNames(data.MESSAGE_DETAILS.MESSAGE)}</p>
                      </div>

                      <div className="" >
                        <div style={{ fontSize: "0.7rem"}}>
                          {data.MESSAGE_DETAILS.UTC_DATETIME === ''
                            ? ''
                            : getMyCreateDate(data.MESSAGE_DETAILS.CREATED_DATE) ===
                              currDate
                              ? DateFormat(data.MESSAGE_DETAILS.UTC_DATETIME)
                              : getMyCreateDate(data.MESSAGE_DETAILS.CREATED_DATE)}
                        </div>
                        {data.MESSAGE_DETAILS.UNREAD_COUNT != "0" ? (
                          <div
                            style={{
                              fontSize: "0.7rem",
                              backgroundColor: "#FBA93E",
                              borderRadius: "50%",
                              width: "15px",
                              height: "15px",
                              textAlign: "center",
                              marginLeft: "13px",
                              // paddingTop: "2px"
                            }}
                            className="text-align-center ml-5"
                          >
                            {" "}
                            {data.MESSAGE_DETAILS.UNREAD_COUNT}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>{" "}
                  </div>
                );
              })}
            </div>
        }
      </div>
    </div>
  );
}
export default Message;