import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import avatar from "../images/avatar.png";
import qs from "qs";
import BasicCard from "./BasicCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import edit_img from "../images/user_profile_material.jpg";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import "../Styles/hideScrollbar.css";
import usePreventBodyScroll from "./usePreventBodyScroll";
import carousal_img1 from "../images/carousal_img1.png"
import carousal_img2 from "../images/carousal_img2.png"
import carousal_img3 from "../images/carousal_img3.png"
import carousal_img4 from "../images/carousal_img4.png"
import carousal_img5 from "../images/carousal_img5.png"
import carousal_img6 from "../images/carousal_img6.png"
import { LeftArrow, RightArrow } from "./Arrows";

import { createSearchParams, useLocation, useNavigate } from "react-router-dom";

export default function MyMentors(props) {
  const path = useLocation();
  let pathName = path.pathname;
  // const slider = React.useRef(null);
  const [mentor, setMentor] = useState({
    List: [],
    Count: "",
  });

  const userDeviceKey = localStorage.getItem("DEVICE_KEY");
  const { disableScroll, enableScroll } = usePreventBodyScroll();
  const Arrows = () => (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        fontSize: "1.2rem",
        fontWeight: "800",
        // marginLeft: 20,

        marginTop: 12,
      }}
    >
      {
      (pathName==="/find-mentors/mentor-profile")?<>{"Mentors - " +Mentor.length}</>:<>{"My Mentors - " + Mentor.length}</>
    }
      <div style={{ marginLeft: "10px", marginRight: 20, display: "flex" }}>
        <LeftArrow /> <RightArrow />
      </div>
    </div>
  );

  useEffect(() => {
    const reqData = {
      mod: "Mentor",
      actionType: "get-my-mentors-list",
      subAction: JSON.stringify({
        DEVICE_KEY: userDeviceKey,
      }),
    };
    axios({
      method: "post",
      url: "https://dev-api.guidejuniors.com/",
      data: qs.stringify(reqData),
      header: { "content-type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      let sample = res.data;
      // console.log(sample.XSCData.LIST)
      setMentor({
        List: sample.XSCData.LIST,
        Count: sample.XSCData.COUNT,
      });
    });
  }, [userDeviceKey]);
  const Mentor=[
    {
      IMAGE_URL:carousal_img1,
      MENTOR_NAME:"Teena",
      MENTOR_SUBJECT:"Physics"
      },
    {
      IMAGE_URL:carousal_img2,
      MENTOR_NAME:"Fernandez",
      MENTOR_SUBJECT:"Mathematics"
      },
    {
      IMAGE_URL:carousal_img3,
      MENTOR_NAME:"Micheal",
      MENTOR_SUBJECT:"Chemistry"
      },
    {
      IMAGE_URL:carousal_img4,
      MENTOR_NAME:"John",
      MENTOR_SUBJECT:"Biology"
      },
    {
      IMAGE_URL:carousal_img5,
      MENTOR_NAME:"Joseph",
      MENTOR_SUBJECT:"English"
      },
    {
      IMAGE_URL:carousal_img6,
      MENTOR_NAME:"Erik",
      MENTOR_SUBJECT:"Commerce"
      },
  
  
  ]
  return (
    <div className="p-2 ">
      <div onMouseEnter={disableScroll} onMouseLeave={enableScroll}>
        <ScrollMenu Header={Arrows} onWheel={onWheel}>
          {Mentor?.map((item, index) => {
            return <Container item={item} index={index} key={index}/>;
          })}
        </ScrollMenu>
      </div>
    </div>
  );
}

const Container = ({ item, index }) => {
  return (
    <div style={{ margin: "20px" }}>
      <BasicCard
        mentorId={item}
        url={item.IMAGE_URL}
        item={index + 1}
        fullname={item.MENTOR_NAME}
        subject={item.MENTOR_SUBJECT}
      />
    </div>
  );
};

function onWheel(apiObj, ev) {
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isThouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev();
  }
}
