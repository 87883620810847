import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import './drop-file-input.css';

// import { ImageConfig } from '../config/ImageConfig'; 
import uploadImg from '../assets/upload_image_black.png';
import sample from '../assets/default.webp';
import { useEffect } from 'react';



const DropFileInput = props => {

    const wrapperRef = useRef(null);
    // var checks="https://dev-api.guidejuniors.com"+props.old
    const [fileList, setFileList] = useState("");

    const [name,setName]=useState("");

   
    // const [preview,setPreview]=useState("")
    // props.onFileChange(fileList);

    // const onDragEnter = () => wrapperRef.current.classList.add('dragover');

    // const onDragLeave = () => wrapperRef.current.classList.remove('dragover');

    // const onDrop = () => wrapperRef.current.classList.remove('dragover');
  
    const onFileDrop = (e) => {

        var filesArray = [].slice.call(e.target.files);
        filesArray.forEach(e => {
            console.log(e.name);
            setName(e.name)
          });
        
        setFileList(e.target.files[0]);

        // const newFile = e.target.files[0];
        // if (newFile) {
        //     const updatedList = [...fileList, newFile];
        //     setFileList(updatedList);
           
        // }
    }
    useEffect(()=>{
        props.onFileChange(fileList);

    },[fileList])

    // const fileRemove = (file) => {
    //     const updatedList = [...fileList];
    //     updatedList.splice(fileList.indexOf(file), 1);
    //     setFileList(updatedList);
    //     props.onFileChange(updatedList);
    // }
    // const remove =()=>{

    //     setFileList("")
        
    // }
    

    return (
        <>
            <div
                ref={wrapperRef}
                className="drop-file-input"
                // onDragEnter={onDragEnter}
                // onDragLeave={onDragLeave}
                // onDrop={onDrop}
            >
                <div className="drop-file-input__label text-dark" style={{display:"flex"}}>
                <div><img className='my-1' src={uploadImg} alt="" /> </div>

                    <div className=''>
                        <p className='my-1 mx-1'>{" "}Drag file or browse</p></div>
                    <input type="file" value="" accept='.pdf' onChange={onFileDrop}/>
                    
                </div>
               
            </div>

            <div className='my-3'>
                <>{
                    (fileList!=="")?
                    <div>
                <img className='' src={sample} alt="" width={50}/><small>{name===""?"":name}</small>
               
                {/* <span className='float-end my-2' onClick={()=>{remove()}}  style={{cursor:"pointer"}} >remove</span> */}
                </div>
                
                       
                :<> </>
}
                </>
                </div>

            {/* {
                fileList.length > 0 ? (
                    <div className="drop-file-preview">
                       
                        {
                            fileList.map((item, index) => (
                                <div key={index} className="drop-file-preview__item">
                                    <img width="50" height="50" src={ImageConfig[item.type.split('/')[1]] || ImageConfig['default']} alt="" />
                                    <div className="drop-file-preview__item__info">
                                        <p>{item.name}</p>
                                        
                                    </div>
                                    <span className="drop-file-preview__item__del" style={{fontSize:"0.7rem",whiteSpace:"noWrap",color:"#FBA93E"}} onClick={() => fileRemove(item)}>Remove File</span>
                                </div>
                            ))
                        }
                    </div>
                ) : null
            } */}
        </>
    );
}

DropFileInput.propTypes = {
    onFileChange: PropTypes.func,
    // filesPlace:PropTypes.func
    
}

export default DropFileInput;
