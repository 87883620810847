import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import gj_side_logo from "../../images/gj_side_logo.png";
import EditTopBar from "../EditTopBar";
import ProfilePageOne from "./MaterialPageOne";
import ProfilePageTwo from "./MaterialPageTwo";
import axios from "axios";
import qs from "qs";
import {toast , ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Create_Profile(props) {


  const navigate = useNavigate();

  const location = useLocation();


  const [page, setPage] = useState(0);
  console.log("skdk",location.state.img_url);

  // var kk="https://dev-api.guidejuniors.com"+location.state.fileUrl;
//  useEffect(()=>(location.state.img_url),[location.state.img_url]);
   
  const [formData, setFormData] = useState({
    title: location.state.title_name,
    describe: location.state.des,
    price: location.state.pri,
    study: location.state.category,
    profileImg:"https://dev-api.guidejuniors.com"+location.state.img_url,
    idCard:"",
    check: location.state.check_price,
  });
     var free=formData.check;

// var kk="https://dev-api.guidejuniors.com"+location.state.img_url;

// const [hello,setHello]=useState([]);

// useEffect(()=>( formData.idCard),[formData.idCard]);
// useEffect(()=>{
//   setHello(formData.idCard)
// },[formData.idCard])

  const [handle, setHandle] = useState(0);
  const userDeviceKey = localStorage.getItem("DEVICE_KEY");
  
    const handleS = () => {

    const profileData = new FormData();

    profileData.append("mod", "Materials");
    profileData.append("actionType", "add-material");
    profileData.append("subAction", JSON.stringify({
      MATERIAL_ID: location.state.mat_id,
      DEVICE_KEY: userDeviceKey,
      TITLE: formData.title,
      CATEGORY: formData.study,
      PRICE: free===true?0:formData.price,
      DESCRIPTION: formData.describe,
    }));

    profileData.append("MATERIAL_IMAGE", formData.profileImg);
    profileData.append("DOC_FILE", formData.idCard);

    console.log("profile", formData.profileImg);
    
    console.log("profhhhile", formData.idCard);

  
    axios({
      method: 'post',
      url: "https://dev-api.guidejuniors.com/",
      data: profileData,
      header: { "content-type": "multipart/form-data" },
    }).then((res) => {
     
      if (res.data.XSCStatus === 0) {

        
        setTimeout(function(){
          navigate('/user-profile/edit-profile/edit-materials');
         }, 3000);
         
         toast.success("Material Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          className: 'toastM'
          });
      


      }
      else {
         
        toast.error("Please fill empty fields", {
          position: toast.POSITION.TOP_RIGHT,
          className: 'toastU'
          // className: 'toastM'
          });

      }

  
    })

}


  const FormTitles = ["ProfilePageOne", "ProfilePageTwo"];

  const PageDisplay = () => {
    if (page === 0) {
      return <ProfilePageOne imgUrl={location.state.img_url} formData={formData} setFormData={setFormData} />;
    } else {
      return <ProfilePageTwo  fileUrl={location.state.file_url} formData={formData} setFormData={setFormData} />;
    }
  };

  return (
    <div className="w-100" >
      <EditTopBar />

      <div className="row w-100">
        <div className='col-lg-4' style={{ fontSize: "0.9rem" }}>
          <div className='ps-2' style={{fontWeight:600}}>
           <a href="/user-profile" style={{textDecoration:"none",color:"black"}}>My Profile</a> {" > "} 
           <a href="/user-profile/edit-profile/edit-materials" style={{textDecoration:"none",color:"black"}}>Book & Materials</a> {" > "} Update Materials {location.state.cate} 
            {/* {location.state.title} */}
          </div>
        </div>
        <div
          className="col-lg-4 mt-4 main"
          style={{ background: "white", border: "3px solid #E9EFEE", borderRadius: "10%" }}
        >
          <div
            className="progressbar w-100 mt-5 mx-2"
            style={{
              height: "5px",
              backgroundColor: "white",

              borderRadius: "30px",
            }}
          >
            <div
              style={{
                width: page === 0 ? "50%" : page === 1 ? "100%" : "100%"

              }}
            ></div>
          </div>
          <div>
            <div style={{ fontSize: "1rem" }}>
              <div >{PageDisplay()}</div>

              <div className="float-end my-2 p-2 mt-2">
                {
                  (page === 0) ? <></> : <button  style={{fontWeight:600}}
                    className="btn w-50"
                    disabled={page == 0}
                    onClick={() => {
                      setPage((currPage) => currPage - 1);
                    }}
                  >
                    Back
                  </button>

                }

                <button className={(page === 0) ? 'w-100 btn' : 'w-50 btn'} style={(page === 0) ? { marginRight: "-20%", backgroundColor: "#FFAA3B" } : { border: "none", backgroundColor: "#FFAA3B" }}
                  onClick={() => {

                    if (page === FormTitles.length - 1) {
                         handleS();
                      // navigate('/user-profile/edit-profile/edit-materials')
                      // console.log(formData);
                      // alert("submitted")


                    } else {
                      setPage((currPage) => currPage + 1);
                    }
                  }}

                >
                  <b style={{ backgroundColor: "FFAA3B", boxShadow: "none" }}>{page === FormTitles.length - 1 ? "Finish" : "Next"}</b>
                </button>
              </div>
              <ToastContainer />
            </div>
          </div>
        </div>

      </div>
    </div>

  );
}
export default Create_Profile;
