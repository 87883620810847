import Card from "react-bootstrap/Card";
import { createSearchParams, useNavigate } from "react-router-dom";
import "../Styles/CarouselCard.css";
import avatar from "../images/avatar.png";

function BasicCard(props) {
  const navigate = useNavigate();
  const handleClick = (mentor) => {
    const mentorId = mentor.USER_ID;
    navigate({
      pathname: "/find-mentors/mentor-profile",
      search: `?${createSearchParams({
        id: mentorId,
      })}`,
    });
  };
  return (
    <>
      <Card
      className="slideshow-wrapper"
        style={{
          width: "162px",
          height: "192px",
          borderRadius: "4%",
          cursor: "pointer",
          background: "#006088",

          border: 0,
        }}
        onClick={() => handleClick(props.mentorId)}
      >
        {/* className="slideshow-wrapper" */}
        <div>
          <Card.Img
            src={
             props.url
            }
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = avatar;
            }}
            alt="image"
            className="img-fluid"
            style={{
              width: "162px",
              height: "192px",
              borderRadius: "4%",
              objectFit: "cover",
            }}
          />
        </div>
        <div>
          <div
            className="mentor_name mb-1 p-2"
            style={{
              fontWeight: "700",
              fontSize: "0.8rem",
              letter: "0.4px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {props.fullname}
          </div>
          <div
            className="mentor_subject  p-2"
            style={{
              fontWeight: "500",
              fontSize: "0.6rem",
              letter: "0.4px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {props.subject}
          </div>
        </div>
      </Card>
    </>
  );
}

export default BasicCard;
