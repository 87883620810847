import React, { useEffect, useState } from "react";
import DropFileInput from "./drop-file-input/DropFileInput";
import axios from "axios";
import qs from "qs";
function ProfilePageTwo({ formData, setFormData }) {





  // const [category, setCategory] = useState("");
  // const [company, setCompany] = useState("");
  // const [jobRole, setJobRole] = useState("");
  // const [yoe, setYoe] = useState("");
  // const [school, setSchool] = useState("");
  // const [classes, setClass] = useState("");
 
  // const [college, setCollege] = useState("");
  // const [degree, setDegree] = useState("");
  // const [stream, setStream] = useState("");
  // const [yop, setYop] = useState("");

  // //useState for API's
  // const [classId, setClassId] = useState([]);
  // const [group, setGroup] = useState([]);
  // useEffect(() => {

  //   const reqData = {
  //     mod: "GJ",
  //     actionType: "get-stream-list",
  //     subAction: JSON.stringify({
  //       TYPE: "2",
  //       DEVICE_KEY: "rw0E4v9G38Ny2tZbU7MJG1RXTZM2"
  //     })
  //   };
  //   axios({
  //     method: 'post',
  //     url: "https://dev-api.guidejuniors.com/",
  //     data: qs.stringify(reqData),
  //     header: { "content-type": "application/x-www-form-urlencoded" },
  //   }).then((res) => {
  //     let response = res.data;
  //     setStreamSelect(
  //       response.XSCData
  //     )

  //   })
  // }, [])
  // useEffect(() => {

  //   const reqData = {
  //     mod: "GJ",
  //     actionType: "get-class-list",
  //     subAction: JSON.stringify({
  //       TYPE: "2",
  //       DEVICE_KEY: "rw0E4v9G38Ny2tZbU7MJG1RXTZM2"
  //     })
  //   };
  //   axios({
  //     method: 'post',
  //     url: "https://dev-api.guidejuniors.com/",
  //     data: qs.stringify(reqData),
  //     header: { "content-type": "application/x-www-form-urlencoded" },
  //   }).then((res) => {
  //     let response = res.data;
  //     setClassId(
  //       response.XSCData
  //     )

  //   })
  // }, [])
  // useEffect(() => {

  //   const reqData = {
  //     mod: "GJ",
  //     actionType: "get-group-list",
  //     subAction: JSON.stringify({
  //       TYPE: "2",
  //       DEVICE_KEY: "rw0E4v9G38Ny2tZbU7MJG1RXTZM2"
  //     })
  //   };
  //   axios({
  //     method: 'post',
  //     url: "https://dev-api.guidejuniors.com/",
  //     data: qs.stringify(reqData),
  //     header: { "content-type": "application/x-www-form-urlencoded" },
  //   }).then((res) => {
  //     let response = res.data;
  //     setGroup(
  //       response.XSCData
  //     )

  //   })
  // }, [])




  // const handleCategory = (e) => {
  //   (formData.study === 'profession') ? setCategory(e.target.value) : setCategory();
  //   (formData.study === 'profession') ? setFormData({ ...formData, category: e.target.value }) : setFormData();
  // }
  // const handleCompany = (e) => {
  //   (formData.study === 'profession') ? setCompany(e.target.value) : setCompany();
  //   (formData.study === 'profession') ? setFormData({ ...formData, company: e.target.value }) : setFormData();
  // }
  // const handleJobRole = (e) => {
  //   (formData.study === 'profession') ? setJobRole(e.target.value) : setJobRole();
  //   (formData.study === 'profession') ? setFormData({ ...formData, jobRole: e.target.value }) : setFormData();
  // }
  // const handleYOE = (e) => {
  //   (formData.study === 'profession') ? setYoe(e.target.value) : setYoe();
  //   (formData.study === 'profession') ? setFormData({ ...formData, yoe: e.target.value }) : setFormData();
  // }
  // const handleCollege = (e) => {

  //   (formData.study === 'college') ? setCollege(e.target.value) : setCollege();
  //   (formData.study === 'college') ? setFormData({ ...formData, college: e.target.value }) : setFormData();
  // }
  // const handleDegree = (e) => {

  //   (formData.study === 'college') ? setDegree(e.target.value) : setDegree();
  //   (formData.study === 'college') ? setFormData({ ...formData, degree: e.target.value }) : setFormData();
  // }
  // const handleStream = (e) => {
  //   (formData.study === 'college') ? setStream(e.target.value) : setStream();
  //   (formData.study === 'college') ? setFormData({ ...formData, stream: e.target.value }) : setFormData();
  // }
  // const handleYOP = (e) => {
  //   setYop(e.target.value);
  //   (formData.study === 'college') ? setYop(e.target.value) : setYop();
  //   (formData.study === 'college') ? setFormData({ ...formData, yop: e.target.value }) : setFormData();
  // }
  // const handleSchool = (e) => {

  //   (formData.study === 'school') ? setSchool(e.target.value) : setSchool();
  //   (formData.study === 'school') || (formData.study === "") ? setFormData({ ...formData, school: e.target.value }) : setFormData();

  // }
  // const handleClass = (e) => {
  //   (formData.study === 'school') ? setClass(e.target.value) : setClass();
  //   setFormData({ ...formData, classes: e.target.value })
  // }
  const onFileChange = (files) => {
    // setImages(files);
    setFormData({ ...formData, idCard: files})
  }
  return (
    <div className="px-md-5">
      {/* {(formData.study === 'profession') ?
        <div>
          <select className="form-select form-select-lg mb-3" onChange={handleCategory} aria-label=".form-select-lg example">
            <option selected>select category</option>
            <option value="IT" selected={(formData.college == "IT") ? true : false}>IT</option>
            <option value="Automobiles" selected={(formData.college == "Automobiles") ? true : false} >Automobiles</option>
            <option value="Accounts" selected={(formData.college == "Accounts") ? true : false}>Accounts</option>
          </select>
          <select className="form-select form-select-lg mb-3" onChange={handleCompany} aria-label=".form-select-lg example">
            <option selected>Select company</option>
            <option value="TCS" selected={(formData.degree == "TCS") ? true : false}>TCS</option>
            <option value="TATA MOTORS" selected={(formData.degree == "TATA MOTORS") ? true : false} >TATA MOTORS</option>
            <option value="TATA COMMS" selected={(formData.degree == "TATA COMMS") ? true : false}>TATA COMMS</option>
          </select>

          <select className="form-select form-select-lg mb-3" onChange={handleJobRole} aria-label=".form-select-lg example">
            <option selected>Select Job role</option>
            <option value="Developer" selected={(formData.job == "Developer") ? true : false}>Developer</option>
            <option value="Engineer" selected={(formData.job == "Engineer") ? true : false} >Engineer</option>
            <option value="Accountant" selected={(formData.job == "Accountant") ? true : false}>Accountant</option>
          </select>
          <select className="form-select form-select-lg mb-3" onChange={handleYOE} aria-label=".form-select-lg example">
            <option selected>Select Experience in year</option>
            <option value="1" selected={(formData.yop == "1") ? true : false}>1</option>
            <option value="2" selected={(formData.yop == "2") ? true : false} >2</option>
            <option value="3" selected={(formData.yop == "3") ? true : false}>3</option>
          </select>
        </div> : <></>} */}
     
      {(formData.check === false)?
        <div>
          <div className="py-2">
            <input type="number" style={{border: "1px solid #DFE1E1",background: "#FAFAFA"}} placeholder="Enter Price" 
             value={formData.price}
             onChange={(event) =>
               setFormData({ ...formData, price: event.target.value })
             }
            className="p-2 w-100"/>
          </div>
          
        </div>
        :
        <></>
      }

      <div className="py-2">
        {
          formData.study==="STUDY MATERIAL"?
          <>
        <label style={{fontWeight:600}}>Upload Document</label>

        <DropFileInput

          onFileChange={(files) => onFileChange(files)}

        />
        </>
        :<></>
}
      </div>

    </div>
  );
}

export default ProfilePageTwo;
