import React from "react";
import notification_icon from "../images/notification.png";
import cancel_icon from "../images/cancel_icon.png";
import avatar from "../images/avatar.png";
import qs from "qs";
import axios from "axios";
import request_cancel_icon from "../images/request_cancel_icon.png";
import { useState, useEffect } from "react";
import { Card, Nav, Tab } from "react-bootstrap";
import "../../src/Styles/NotificationModal.css";
// import Modal from 'react-modal';
import Swal from "sweetalert2";

const Modal = ({ handleClose, show }) => {
  const userDeviceKey = localStorage.getItem("DEVICE_KEY");
  const showHideClassName = show ? "modal d-block " : "modal d-none";

  //connect request
  const [notificationAction, setNotificationAction] = useState({});

  useEffect(() => {
    if (notificationAction.mod) {
      axios({
        method: "post",
        url: "https://dev-api.guidejuniors.com/",
        data: qs.stringify(notificationAction),
        header: { "content-type": "application/x-www-form-urlencoded" },
      }).then((res) => {
        let resData = res.data;
        if (resData.XSCStatus === 0) {
          Swal.fire("request accepted!", "", "success");
          getNotificationList();
          window.location.reload();
        } else {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
    }
  }, [notificationAction]);

  const [notification, setNotification] = useState([]);
  useEffect(() => {
    getNotificationList();
  }, []);

  const getNotificationList = () => {
    const reqData = {
      mod: "Notification",
      actionType: "get-notification-list",
      subAction: JSON.stringify({
        DEVICE_KEY: userDeviceKey,
      }),
    };
    axios({
      method: "post",
      url: "https://dev-api.guidejuniors.com/",
      data: qs.stringify(reqData),
      header: { "content-type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      let resData = res.data;
      // console.log(resData)
      setNotification(resData.XSCData.LIST);
    });
  };

  const [sendRequest, setSendRequest] = useState([]);

  const getSendRequestList = () => {
    const reqData = {
      mod: "Notification",
      actionType: "get-send-request-list",
      subAction: JSON.stringify({
        DEVICE_KEY: userDeviceKey,
      }),
    };
    axios({
      method: "post",
      url: "https://dev-api.guidejuniors.com/",
      data: qs.stringify(reqData),
      header: { "content-type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      let resData = res.data;
      // console.log(resData)
      setSendRequest(resData.XSCData.LIST);
    });
  };
  useEffect(() => {
    getSendRequestList();
  }, []);

  const handleConnect = (requestData, actionType) => {
    Swal.fire({
      title:
        actionType == 1
          ? "Do you want to accept the request ?"
          : "Do you want to decline the request?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      
    }).then((result) => {
      if (result.isConfirmed) {
        let subAction = requestData.SUBACTION;
        subAction.DEVICE_KEY = localStorage.getItem("DEVICE_KEY");

        const reqData = {
          mod: requestData.MOD,
          actionType: requestData.ACTION_TYPE,
          subAction: JSON.stringify(subAction),
        };
        setNotificationAction(reqData);
      }
    });
  };

  const handleRequestWithdraw = (datas) => {
    Swal.fire({
      title: "Are you sure ?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Request withdraw!",
    }).then((result) => {
      if (result.isConfirmed) {
        const reqData = {
          mod: "Mentor",
          actionType: "cancel-request",
          subAction: JSON.stringify({
            DEVICE_KEY: userDeviceKey,
            REQUEST_ID: datas.REQUEST_ID,
            REQUEST_TYPE: datas.REQUEST_TYPE,
          }),
        };

        axios({
          method: "post",
          url: "https://dev-api.guidejuniors.com/",
          data: qs.stringify(reqData),
          header: { "content-type": "application/x-www-form-urlencoded" },
        }).then((res) => {
          let resData = res.data;
          if (resData.XSCStatus === 0) {
            Swal.fire("Withdraw!", resData.XSCMessage, "success");
            getSendRequestList();
          } else {
            Swal.fire("Failed", resData.XSCMessage, "failed");
          }
        });
      }
    });
  };
let modalWidth=window.innerWidth;
  return (
    <div  className="container">
       <div
      className={showHideClassName}
      style={modalWidth>991?
        {
        top: "9%",
        height: "70%",
        width: "22%",
        left: "70%",
        zIndex: "4",
        background: "white",
        borderRadius: "2%",
        transform: "translate(0%, 12%)",
        // animation: "drop 0.2s forwards ",
        boxShadow: "0 0 0 9999px rgba(0, 0, 0, 0.5)",

        opacity: 0,
        animation: "fadeIn 0.5s ease-in both",
        animationDelay: "0.5s",
      }
    :
    modalWidth>767?{
      top: "9%",
      height: "70%",
      width: "60%",
      textAlign:"center",
      justifyContent:"center",
      alignItems:"center",
      margin:"0% 20% 0% 20%",
      // left: "70%",
      zIndex: "4",
      background: "white",
      borderRadius: "2%",
      transform: "translate(0%, 12%)",
      // animation: "drop 0.2s forwards ",
      boxShadow: "0 0 0 9999px rgba(0, 0, 0, 0.5)",
  
      opacity: 0,
      animation: "fadeIn 0.5s ease-in both",
      animationDelay: "0.5s",
    }:
  {
    top: "9%",
    height: "70%",
    width: "80%",
    textAlign:"center",
    justifyContent:"center",
    alignItems:"center",
    margin:"0% 10% 0% 10%",
    // left: "70%",
    zIndex: "4",
    background: "white",
    borderRadius: "2%",
    transform: "translate(0%, 12%)",
    // animation: "drop 0.2s forwards ",
    boxShadow: "0 0 0 9999px rgba(0, 0, 0, 0.5)",

    opacity: 0,
    animation: "fadeIn 0.5s ease-in both",
    animationDelay: "0.5s",
  }}
    >
      <div className="modal-container">
        <div className="d-flex m-3 justify-content-between">
          <div>
            <img
              className=""
              src={notification_icon}
              style={{ height: "25px", width: "25px" }}
              alt=""
            />
            <span className="ms-4">
              <b style={{ fontSize: "1rem" }}>Notifications</b>
            </span>
          </div>
          <div>
            <img
              className="ms-5 mt-1"
              src={cancel_icon}
              alt=""
              onClick={handleClose}
              style={{ height: "15px", width: "15px",cursor:"pointer" }}
            />
          </div>
        </div>

        <Tab.Container
          className="d-flex"
          id="left-tabs-example"
          defaultActiveKey="first"
        >
          <Nav variant="pills" className="flex-row notification_active_link">
            <Nav.Item>
              <Nav.Link eventKey="first" style={{ fontSize: "0.8rem" }}>
                Invitations
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second" style={{ fontSize: "0.8rem" }}>
              Requests
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="first">
              <div>
                {" "}
                <div className="" style={{ marginTop: "-19px" }}>
                  {notification.slice(0, 4).map((data,index) => {
                    return (
                      <div key={index}>
                        <hr style={{ border: "0px solid #0A1A17" }} />
                        <div className="d-flex px-2" >
                          <div>
                            <img
                              src={
                                data.IMAGE_URL === ""
                                  ? avatar
                                  : data.IMAGE_URL.includes("https://")
                                  ? data.IMAGE_URL
                                  : "https://dev-api.guidejuniors.com/" +
                                    data.IMAGE_URL
                              }
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = avatar;
                              }}
                              style={{
                                borderRadius: "50%",
                                height: "35px",
                                width: "35px",
                              }}
                              alt=""
                            />
                          </div>
                          <div
                            className="ps-2 pt-0"
                            style={{ fontSize: "0.7rem", width: "100%" }}
                          >
                            <b>{data.FULL_NAME}</b>
                            {data.CONTENT}
                          </div>
                          {data.ACTION.REQUIRED === true ? (
                            <div className="d-flex">
                              <div>
                                <button
                                  type="button"
                                  className=""
                                  style={{
                                    backgroundColor: "#FBA93E",
                                    borderRadius: "100px",
                                    fontSize: "0.7rem",
                                    border: "none",
                                    padding: "8px 22px",
                                  }}
                                  onClick={() =>
                                    handleConnect(data.ACTION.DESC.POSITIVE, 1)
                                  }
                                >
                                  Connect
                                </button>
                              </div>
                              <div align="center" className=" ms-3 ">
                                <img
                                  src={request_cancel_icon}
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handleConnect(data.ACTION.DESC.NEGATIVE, 0)
                                  }
                                  alt=""
                                />
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <div>
                {" "}
                <div className="" style={{ marginTop: "-19px" }}>
                  <div>
                    {
                      sendRequest.slice(0, 4).map((data,index) => {
                        return (
                          <div key={index}>
                            <hr style={{ border: "0px solid #0A1A17" }} />
                            <div className="d-flex py-1 px-2 mt-3" >
                              <div>
                                <img src={data.IMAGE_URL === '' ? avatar : data.IMAGE_URL.includes("https://") ? data.IMAGE_URL :
                                  "https://dev-api.guidejuniors.com/" + data.IMAGE_URL}
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = avatar;
                                  }}
                                  style={{ borderRadius: "50%", height: "35px", width: "35px" }} alt="" />
                              </div>
                              <div className="ps-2 pt-0" style={{ fontSize: "0.7rem", width: "60%" }}>
                                <b>{data.FULL_NAME}</b>
                                <div>{data.REQUEST_TYPE}</div>
                              </div>

                            <div className="d-flex">
                              <div>
                                <button
                                  type="button"
                                  className="text-white"
                                  style={{
                                    backgroundColor: "#FBA93E",
                                    borderRadius: "100px",
                                    fontSize: "0.7rem",
                                    border: "none",
                                    padding: "8px 22px",
                                  }}
                                  onClick={() => handleRequestWithdraw(data)}
                                >
                                  Pending
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
      </div>
   
    // </div>
  );
};
export default Modal;
