import React, { useEffect, useState } from "react";
import Sidemenu from "./Sidemenu";
import TopBar from "./TopBar";
import avatar from "../images/avatar.png";
import search_icon from "../images/search_icon.png";
import suggested_material_img from "../images/carousal_img1.png";
import { Card, Nav, Tab } from "react-bootstrap";
import axios from "axios";
// import Select from "react-select";
import Select from "react-dropdown-select";

import qs from "qs";
import { createSearchParams, useNavigate } from "react-router-dom";
import "../Styles/Connection.css";
import BasicCard from "./BasicCard";
import carousal_img1 from "../images/carousal_img1.png"
import carousal_img2 from "../images/carousal_img2.png"
import carousal_img3 from "../images/carousal_img3.png"
import carousal_img4 from "../images/carousal_img4.png"
import carousal_img5 from "../images/carousal_img5.png"
import carousal_img6 from "../images/carousal_img6.png"
export default function Connections() {
  const [junior, setjunior] = useState([]);
  const [mentor, setMentor] = useState([]);
  const [connection, setConnection] = useState([]);
  const [mentorSearch, setMentorSearch] = useState("");
  const [menteesSearch, setMenteesSearch] = useState("");
  const [connectionSearch, setConnectionSearch] = useState("");
  const [selectSubjects, setSelectSubjects] = useState([]);
  const [selMenteesSub, setSelMenteesSub] = useState([])
  const [message, setMessage] = useState("");
  const [filter, setFilter] = useState({
    subjects: [],
  });
  const userDeviceKey = localStorage.getItem("DEVICE_KEY");
  useEffect(() => {
    const reqData = {
      mod: "Mentor",
      actionType: "get-my-juniors-list",
      subAction: JSON.stringify({
        DEVICE_KEY: userDeviceKey,
        SEARCH_KEY: menteesSearch,
        SUBJECT_ID: selMenteesSub,
      }),
    };

    axios({
      method: "post",
      url: "https://dev-api.guidejuniors.com/",
      data: qs.stringify(reqData),
      header: { "content-type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      let sample = res.data;
      // console.log(sample)
      setjunior(sample.XSCData.LIST);
    });
  }, [menteesSearch, userDeviceKey, selMenteesSub]);

  const subjectsOptions = filter.subjects.map((count) => {
    return {
      value: count.ID,
      label: count.NAME,
    };
  });

  const handleSelectSubjects = (e) => {
    setSelectSubjects(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  const handleSelMenteesSub = (e) => {
    setSelMenteesSub(Array.isArray(e) ? e.map((x) => x.value) : []);
  }

  const handleMenteesSearch = (e) => {
    setMenteesSearch(e.target.value);
  };
  const handleMentorSearch = (e) => {
    setMentorSearch(e.target.value);
  };
  const handleConnectionSearch = (e) => {
    setConnectionSearch(e.target.value);
  };

  useEffect(() => {
    const reqData = {
      mod: "Mentor",
      actionType: "get-my-mentors-list",
      subAction: JSON.stringify({
        DEVICE_KEY: userDeviceKey,
        SEARCH_KEY: mentorSearch,
        FILTERS: [
          {
            ID: "3",
            NAME: "Subjects",
            SELECTED: selectSubjects,
          },
        ],
      }),
    };

    axios({
      method: "post",
      url: "https://dev-api.guidejuniors.com/",
      data: qs.stringify(reqData),
      header: { "content-type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      let sample = res.data;
      setMentor(sample.XSCData.LIST);
    });
  }, [mentorSearch, selectSubjects, userDeviceKey]);

  useEffect(() => {
    const reqData = {
      mod: "Mentor",
      actionType: "get-my-conections-list",
      subAction: JSON.stringify({
        DEVICE_KEY: userDeviceKey,
        SEARCH_KEY: connectionSearch,
      }),
    };

    axios({
      method: "post",
      url: "https://dev-api.guidejuniors.com/",
      data: qs.stringify(reqData),
      header: { "content-type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      let sample = res.data;
      setConnection(sample.XSCData.LIST);
    });
  }, [userDeviceKey, connectionSearch]);

  const navigate = useNavigate();

  const handleMentorClick = (mentor) => {
    const mentorId = mentor.USER_ID;
    navigate({
      pathname: "/find-mentors/mentor-profile",
      search: `?${createSearchParams({
        id: mentorId,
      })}`,
    });
  };

  useEffect(() => {
    const reqData = {
      mod: "GJ",
      actionType: "get-master-filter-list",
      subAction: JSON.stringify({
        DEVICE_KEY: userDeviceKey,
      }),
    };

    axios({
      method: "post",
      url: "https://dev-api.guidejuniors.com/",
      data: qs.stringify(reqData),
      header: { "content-type": "application/x-www-form-urlencoded" },
    }).then((res) => {
      let sample = res.data;
      // console.log(sample);
      setFilter({
        subjects: sample.XSCData[2].FILTERS,
      });
    });
  }, [userDeviceKey]);
  const handleSelect = (eventKey) => {
    setConnectionCount(eventKey);

  }
  const [connectionCount, setConnectionCount] = useState("mentors");
  const Mentor=[
    {
      IMAGE_URL:carousal_img1,
      MENTOR_NAME:"Teena",
      MENTOR_SUBJECT:"Physics"
      },
    {
      IMAGE_URL:carousal_img2,
      MENTOR_NAME:"Fernandez",
      MENTOR_SUBJECT:"Mathematics"
      },
    {
      IMAGE_URL:carousal_img3,
      MENTOR_NAME:"Micheal",
      MENTOR_SUBJECT:"Chemistry"
      },
    {
      IMAGE_URL:carousal_img4,
      MENTOR_NAME:"John",
      MENTOR_SUBJECT:"Biology"
      },
    {
      IMAGE_URL:carousal_img5,
      MENTOR_NAME:"Joseph",
      MENTOR_SUBJECT:"English"
      },
    {
      IMAGE_URL:carousal_img6,
      MENTOR_NAME:"Erik",
      MENTOR_SUBJECT:"Commerce"
      },
  
  
  ]
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <Sidemenu />
          <div
            className="col-lg-10 mt-2"
            style={{ borderLeft: "2px solid #EBEDEC" }}
          >
            <TopBar />
            <div className="pe-3 pt-2 pb-5">
              <div className="mt-3">
                <h5>
                  <b>Connections</b>
                </h5>
              </div>
              <div className="row pb-2">
                <div className="col-lg-4 col-md-6"
                  style={{ fontSize: "0.8rem" }}>
                  People who you connect with for study materials or for
                  mentoring will show here
                </div>
              </div>

              <Tab.Container
                id="justify-tabs-example"
                defaultActiveKey="mentors"
                onSelect={(key) => handleSelect(key)}
              // activeKey={activeTab}
              >
                <div
                  className="d-flex justify-content-between mt-4"
                >
                  <div className="ms-4" >
                    <Nav className="flex-row connection-tab" >
                      <Nav.Item className="my-2 my-md-0 "
                        style={{
                          background: "#FFF6EC", marginRight: "12px",
                          // borderRadius: "10px 10px 0px 0px"
                        }}  >
                        <Nav.Link eventKey="mentors" style={{ color: "black", paddingLeft: "40px", paddingRight: "40px" }}>
                          Mentors
                        </Nav.Link>
                      </Nav.Item >
                      <Nav.Item className="my-2 my-md-0"
                        style={{
                          background: "#FFF6EC", marginRight: "12px",
                          // borderRadius: "10px 10px 0px 0px" 
                        }}>
                        <Nav.Link eventKey="mentees" style={{ color: "black", paddingLeft: "40px", paddingRight: "40px" }}>
                          Mentees
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="my-2 my-md-0"
                        style={{
                          background: "#FFF6EC",
                          // borderRadius: "10px 10px 0px 0px"
                        }}>
                        <Nav.Link
                          eventKey="connections"
                          style={{ color: "black", paddingLeft: "40px", paddingRight: "40px" }}
                        >
                          Connections
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                  <div className="me-5" style={{ whiteSpace: "nowrap" }}>Total - {(connectionCount === "mentors") ? mentor.length : (connectionCount === "mentees") ? junior.length : connection.length}</div>
                </div>

                <Tab.Content className="ms-0 p-3"
                  style={{ border: "1px solid #EBEDEC", borderRadius: "10px" }}>
                  <Tab.Pane eventKey="mentors">
                    <div className="d-md-flex my-3">
                      <div className="mx-2 my-2" >
                        <div>
                          <div
                            className="w-100"
                            style={{
                              position: "relative",
                              display: "inlineBlock",
                            }}
                          >
                            <span
                              className="my-2"
                              style={{
                                position: "absolute",
                                left: "0",
                                top: "-1px",
                              }}
                            >
                              <img
                                src={search_icon}
                                className="ps-1"
                                width="20"

                              />
                            </span>
                            <input
                              className="rounded input_field"
                              placeholder="Search your mentors"
                              onChange={handleMentorSearch}
                              style={{
                                backgroundColor: "#F5F6F6",
                                border: "none",
                                paddingLeft: "30px",
                                width: "100%",
                                height: "38px",
                                fontSize: "0.8rem"
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className="mx-1 my-2"
                        style={{ fontSize: "0.8rem" }}
                      >
                        <div className="mx-1 ">
                          <Select
                            options={subjectsOptions}
                            // style={{
                            //   width: "100%",
                            //   height: "30px",
                            //   zIndex: "2",
                            // }}
                            value={subjectsOptions.filter((obj) =>
                              selectSubjects.includes(obj.value)
                            )}
                            multi
                            placeholder="Select Subjects"
                            onChange={handleSelectSubjects}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {Mentor.map((mentor, index) => {
                        return (
                          <div className="col-md-3 col-lg-2 col-sm-4 col-6" key={index}>
                            <div className="">
                              <>
                                <BasicCard
                                  mentorId={mentor}
                                  url={
                                    mentor.IMAGE_URL     }
                                  item={index + 1}
                                  fullname={mentor.MENTOR_NAME}
                                  subject={mentor.MENTOR_SUBJECT}
                                />
                              </>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="mentees">
                    <div className="d-md-flex my-3">
                      <div className="mx-2 my-2" >
                        <div>
                          <div
                            // className="w-100"
                            style={{
                              position: "relative",
                              display: "inlineBlock",
                            }}
                          >
                            <span
                              className="my-2"
                              style={{
                                position: "absolute",
                                left: "0",
                                top: "-1px",
                              }}
                            >
                              <img
                                src={search_icon}
                                className="ps-1"
                                width="20"
                              />
                            </span>
                            <input
                              className="rounded input_field"
                              onChange={handleMenteesSearch}
                              placeholder="Search for Mentees"
                              style={{
                                backgroundColor: "#F5F6F6",
                                border: "none",
                                paddingLeft: "30px",
                                width: "100%",
                                height: "38px",
                                fontSize: "0.8rem"
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className='mx-1 my-2' style={{ fontSize: "0.8rem" }}>
                        <Select options={subjectsOptions}
                          // style={{
                          //   width: "100%",
                          //   height: "30px",
                          //   zIndex: "2",
                          // }}
                          value={subjectsOptions.filter((obj) =>
                            selMenteesSub.includes(obj.value)
                          )}
                          multi
                          placeholder="Select Subjects"
                          onChange={handleSelMenteesSub}
                        />
                      </div>

                    </div>
                    <div className="row">
                      {junior.map((junior, index) => {
                        return (
                          <div className=" col-lg-2 col-md-3 col-sm-4 col-6" key={index}>
                            <div className="p-2">
                              <>
                                <BasicCard
                                  mentorId={junior}
                                  url={
                                    junior.IMAGE_URL === ""
                                      ? avatar
                                      : junior.IMAGE_URL.includes("https://")
                                        ? junior.IMAGE_URL
                                        : "https://dev-api.guidejuniors.com/" + junior.IMAGE_URL
                                  }
                                  item={index + 1}
                                  fullname={junior.FULL_NAME}
                                  subject={junior.SUBJECT_NAME}
                                />
                              </>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="connections">
                    <div className="d-md-flex ">
                      <div className="mx-2 my-2" >
                        <div>
                          <div
                            style={{
                              position: "relative",
                              display: "inlineBlock",
                            }}
                          >
                            <span
                              className="my-2"
                              style={{
                                position: "absolute",
                                left: "0",
                                top: "-1px",
                              }}
                            >
                              <img
                                src={search_icon}
                                className="ps-1"
                                width="20"
                              />
                            </span>
                            <input
                              className="rounded input_field"
                              onChange={handleConnectionSearch}
                              placeholder="Search for Connections"
                              style={{
                                backgroundColor: "#F5F6F6",
                                border: "none",
                                paddingLeft: "30px",
                                width: "100%",
                                height: "38px",
                                fontSize: "0.8rem"
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className='mx-1' style={{ width: "15%", fontSize: "0.8rem" }}>
                                                <select className='rounded' style={{ width: "100%", height: "25px" }}>
                                                    <option defaultValue>
                                                        All Subjects
                                                    </option>
                                                </select>
                                            </div> */}
                    </div>
                    <div className="row">
                      {connection.map((connection, index) => {
                        return (
                          <div className="col-md-3 col-lg-2 col-sm-4 col-6" key={index}>
                            <div className="p-2">
                              <>
                                <BasicCard
                                  mentorId={connection}
                                  url={
                                    connection.IMAGE_URL === ""
                                      ? avatar
                                      : connection.IMAGE_URL.includes("https://")
                                        ? connection.IMAGE_URL
                                        : "https://dev-api.guidejuniors.com/" + connection.IMAGE_URL
                                  }
                                  item={index + 1}
                                  fullname={connection.FULL_NAME}
                                  subject={connection.SUBJECT_NAME} />
                              </>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
