import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './drop-file-input.css';
import { useEffect } from 'react';

const DropFileInput = props => {
    const wrapperRef = useRef(null);
    const [fileList, setFileList] = useState("");
    const [name, setName] = useState("");
    const [img, setImg] = useState();

    const onFileDrop = (e) => {
        var filesArray = [].slice.call(e.target.files);
        filesArray.forEach(e => {
            console.log(e.name);
            setName(e.name)
        });
        setFileList(e.target.files[0]);
        console.log(e.target.files[0])
        setImg(URL.createObjectURL(e.target.files[0]))
    }
    useEffect(() => {
        props.onFileChange(fileList);
    }, [fileList])
    return (
        <>
            <div
                ref={wrapperRef}
                className="drop-file-input"
            >
                <div className="drop-file-input__label text-dark" style={{ display: "flex" }}>
                    <div>
                        <p className='my-1 mx-1'>{" "}Drag file or browse</p>
                    </div>
                    <input type="file" value="" accept='image/*' onChange={onFileDrop} />
                </div>
            </div>
            <div className='my-3'>
                <>
                    <img className='' src={img === "" ? "https://dev-api.guidejuniors.com/" + props.urlImage : img} alt="" width={50} /><small>{name == "" ? props.old : name}</small>
                </>
            </div>
        </>
    );
}

DropFileInput.propTypes = {
    onFileChange: PropTypes.func,
}

export default DropFileInput;
