import React, { useEffect, useState } from 'react'
import EditTopBar from './EditTopBar'
import mentor_profile_bg from '../images/mentor_profile_bg.png'
import share_icon from '../images/share_icon.png'
import edit_profile_icon from '../images/edit_profile_icon.png'
import edit_icon from '../images/edit_icon.png'
import { Rating } from 'react-simple-star-rating'
import qs from 'qs'
import white_arrow_icon from '../images/white_arrow_icon.png'
import '../Styles/UserProfile.css'
import axios from 'axios'
import AddSubject from './AddMentorSubjects'
import avatar from '../images/avatar.png'
import MyMentors from './MyMentors'
import ReactStars from 'react-rating-stars-component'
import ShareOnSocial from 'react-share-on-social'
import { useLocation } from 'react-router'
import favicon from '../images/gj_side_logo.png'
import { FiEdit } from 'react-icons/fi'
import { BiShareAlt } from 'react-icons/bi'
import suggested_material from '../images/suggested_material.png'
import suggested_material2 from '../images/suggested_material2.png'
import suggested_material3 from '../images/suggested_material3.png'
import suggested_material4 from '../images/suggested_material4.png'
import request_img_2 from '../images/request_img_1.png'
import MentorProfile from './MentorProfile'
export default function UserProfile () {
  const [userProfile, setUserProfile] = useState({
    profile: '',
    connectionCount: '',
    mentorSubject: [],
    myMaterials: []
  })
  const userDeviceKey = localStorage.getItem('DEVICE_KEY')
  const [userRating, setuserRating] = useState([])
  useEffect(() => {
    const reqData = {
      mod: 'Profile',
      actionType: 'get-user-profile',
      subAction: JSON.stringify({
        DEVICE_KEY: userDeviceKey
      })
    }

    axios({
      method: 'post',
      url: 'https://dev-api.guidejuniors.com/',
      data: qs.stringify(reqData),
      header: { 'content-type': 'application/x-www-form-urlencoded' }
    }).then(res => {
      let sample = res.data.XSCData

      localStorage.setItem('user_id', sample.PROFILE_DETAILS.USER_ID)
      setUserProfile({
        profile: sample.PROFILE_DETAILS,
        connectionCount: sample.CONNECTION_DETAILS,
        mentorSubject: sample.MENTORING_SUBJECT_DETAILS,
        myMaterials: sample.MY_MATERIALS
      })
    })
  }, [])
  useEffect(() => {
    const reqData = {
      mod: 'UserRating',
      actionType: 'get-user-ratings-list',
      subAction: JSON.stringify({
        DEVICE_KEY: localStorage.getItem('DEVICE_KEY')
      })
    }

    axios({
      method: 'post',
      url: 'https://dev-api.guidejuniors.com/',
      data: qs.stringify(reqData),
      header: { 'content-type': 'application/x-www-form-urlencoded' }
    }).then(res => {
      let sample = res.data.XSCData

      setuserRating(sample.LIST)
    })
  }, [])

  let userId = localStorage.getItem('user_id')
  // console.log("userID", userId);

  const getProfileImage = imageUrl => {
    if (!imageUrl) {
      return avatar
    }
    if (imageUrl.includes('http')) {
      return imageUrl
    }
    return 'https://dev-api.guidejuniors.com/' + imageUrl
  }
  const decodeNames = x => {
    if (x) {
      let r = /\\u([\d\w]{4})/gi
      x = x.replace(r, function (match, grp) {
        return String.fromCharCode(parseInt(grp, 16))
      })
    }

    return x
  }

  const location = window.location.origin
  console.log(
    location + '/find-mentors/mentor-profile?id=' + userProfile.profile.USER_ID
  )
  const Materials = [
    {
      IMAGE_URL: suggested_material,
      TITLE: 'Physics Volume III',
      DESCRIPTION:
        'It is the Physics chapter III book of higher secondary in the application',
      PRICE: '559'
    },
    {
      IMAGE_URL: suggested_material2,
      TITLE: 'Chemistry Volume I',
      DESCRIPTION:
        'It is the Chemistry chapter I book of higher secondary in the application',
      PRICE: '399'
    },
    {
      IMAGE_URL: suggested_material3,
      TITLE: 'Mathematics Volume II',
      DESCRIPTION:
        'It is the Mathematics chapter II book of higher secondary in the application',
      PRICE: '525'
    },
    {
      IMAGE_URL: suggested_material4,
      TITLE: 'Biology ',
      DESCRIPTION:
        'It is the Biology book which helps in the project management',
      PRICE: '615'
    }
  ]
  const MentorProfile = [{ SUBJECT: 'Biology' }, { SUBJECT: 'Commerce' }]
  return (
    <div
      style={{ fontSize: '0.7rem', padding: '0' }}
      className='container-fluid'
    >
      <EditTopBar />

      <div className='profile_header_content'>
        <div className='pb-4'>
          <div
            className=' pb-5 '
            style={{
              backgroundImage: `url(${mentor_profile_bg})`,
              objectFit: 'cover',
              backgroundSize: '100%',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat'
            }}
          >
            <div className='profile_mobile py-2 px-3  justify-content-between'>
              <div>
                <img src={white_arrow_icon} />
              </div>
              <div>
                <ShareOnSocial
                  linkFavicon={favicon}
                  linkTitle='GuideJunior Profile'
                  link={
                    location +
                    '/find-mentors/mentor-profile?id=' +
                    userProfile.profile.USER_ID
                  }
                >
                  <BiShareAlt style={{ fontSize: '1.1rem', color: 'white' }} />
                </ShareOnSocial>{' '}
                <a href='/user-profile/edit-profile' className='mx-2'>
                  {/* <img
                    src={edit_profile_icon}
                    width='15'
                    height='15'
                    className='ms-2'
                    alt='share_icon'
                  /> */}
                  <FiEdit style={{ fontSize: '1rem', color: 'white' }} />
                </a>
              </div>
            </div>
            <div
              className='py-5 ps-3 profile_mbl_not_show text-light'
              style={{ fontSize: '1.2rem', fontWeight: '700' }}
            >
              <a
                href='/dashboard'
                style={{ textDecoration: 'none', color: 'white' }}
              >
                <img src={white_arrow_icon} /> My Profile{' '}
              </a>
            </div>
            <div className='float-end profile_mbl_not_show'>
              <ShareOnSocial
                linkTitle='GuideJunior Profile'
                linkFavicon={favicon}
                link={
                  location +
                  '/find-mentors/mentor-profile?id=' +
                  userProfile.profile.USER_ID
                }
              >
                <div
                  className='btn btn-outline-light me-2'
                  style={{ fontSize: '0.7rem' }}
                  // onClick={handleShare}
                >
                  <BiShareAlt style={{ fontSize: '1.1rem', color: 'white' }} />{' '}
                  Share Profile
                </div>
              </ShareOnSocial>
              <a
                href='/user-profile/edit-profile'
                className='text-decoration-none text-dark'
              >
                <div
                  className='btn btn-light text-dark me-2'
                  style={{ fontSize: '0.7rem' }}
                >
                  {/* <img
                    src={edit_profile_icon}
                    width='15'
                    height='15'
                    alt='share_icon'
                  /> */}
                  <FiEdit style={{ fontSize: '1rem' }} /> Edit Profile
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className='row ps-3 ' style={{ width: '100%' }}>
        <div className='col-md-2'>
          <div
            className='card profile_card p-2'
            style={{
              fontSize: '0.7rem',
              width: '15%',
              position: 'absolute',
              top: '30%'
            }}
          >
            <div align='center' className='py-1'>
              <img
                src={request_img_2}
                className='rounded-circle'
                width='60'
                height='60'
                alt='profile'
                style={{ objectFit: 'cover' }}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null // prevents looping
                  currentTarget.src = avatar
                }}
              />
            </div>
            <div
              align='center'
              className='py-1'
              style={{
                fontWeight: '700',
                fontSize: '1rem',
                letterSpacing: '1px'
              }}
            >
              <b>John</b>
            </div>
            <div
              align='center'
              className=''
              style={{ fontSize: '0.744rem', fontWeight: '600' }}
            >
              {userProfile.profile.CLASS_ID <= 12
                ? 'Class:' + userProfile.profile.CLASS_NAME
                : userProfile.profile.CLASS_ID >= 13 &&
                  userProfile.profile.CLASS_ID <= 17
                ? 'Pursuving:' + userProfile.profile.CLASS_NAME
                : 'Class:' + 'XII'}
              {/* {"Class:"+userProfile.profile.CLASS_NAME} */}
            </div>

            <div
              className='d-flex py-2'
              style={{
                justifyContent: 'space-between',
                fontSize: '0.744rem',
                fontWeight: '600'
              }}
            >
              <div>Connections</div>
              <div>
                13
                {userProfile.connectionCount.MY_CONNECTIONS}
              </div>
            </div>
            <div
              className='d-flex py-2'
              style={{
                justifyContent: 'space-between',
                fontSize: '0.744rem',
                fontWeight: '600'
              }}
            >
              <div>Mentors</div>
              <div>8{userProfile.connectionCount.MENTORING_COUNT}</div>
            </div>
            <div
              className='d-flex py-2'
              style={{
                justifyContent: 'space-between',
                fontSize: '0.744rem',
                fontWeight: '600'
              }}
            >
              <div>Mentees</div>
              <div>5{userProfile.connectionCount.MENTEES_COUNT}</div>
            </div>
          </div>
        </div>
        <div className='col-md-10'>
          <div className='py-2'>
            <div style={{ fontSize: '1.2rem' }}>
              <b>
                Mentoring Subjects{' '}
                {/* <AddSubject subjectId={userProfile.mentorSubject} /> */}
              </b>
            </div>
            <div className=''>
              {MentorProfile.map((subject, index) => {
                return (
                  <div
                    key={index}
                    className='btn rounded me-2 my-2'
                    style={{
                      background: '#F5F6F6',
                      fontSize: '0.7rem',
                      fontWeight: 600
                    }}
                  >
                    {subject.SUBJECT}
                  </div>
                )
              })}
            </div>
          </div>
          <div className='py-2'>
            <MyMentors />
          </div>
          <div className='py-2'>
            <div style={{ fontSize: '1.2rem' }}>
              <b>
                Books & Materials Published{' '}
                <a href='/user-profile/edit-profile/edit-materials'>
                  <img src={edit_icon} width='15' height='15' />
                </a>
              </b>
            </div>
            <div className='row'>
              {Materials.map((material, index) => {
                return (
                  <div key={index} className='col-lg-3 col-md-6'>
                    <div className='p-2'>
                      <div
                        className='card rounded p-2'
                        style={{ width: '15rem', fontSize: '0.7rem' }}
                      >
                        <div align='center' className=''>
                          <img
                            src={
                              'https://dev-api.guidejuniors.com' +
                              material.IMAGE_URL
                            }
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null // prevents looping
                              currentTarget.src = avatar
                            }}
                            style={{ objectFit: 'cover' }}
                            width='100'
                            height='100'
                            alt='...'
                          />
                        </div>

                        <div className='ps-2'>
                          <div style={{ fontSize: '0.7rem' }}>
                            {material.TITLE}
                          </div>
                          <p
                            className='card-text'
                            style={{ fontSize: '0.7rem' }}
                          >
                            $ {material.PRICE}
                          </p>
                          <div
                            className='btn text-dark'
                            style={{
                              backgroundColor: '#FDCD8F',
                              fontSize: '0.7rem',
                              cursor: 'default'
                            }}
                          >
                            <b>
                              {material.CATEGORY === 'BOOK'
                                ? 'HARD COPY'
                                : 'SOFY COPY'}
                            </b>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          {/* Ratings */}
          <div className='py-2'>
            <div style={{ fontSize: '1.2rem' }}>
              <b>
                Mentor Ratings & Reviews{' '}
                {userRating.length === 0 ? (
                  <span></span>
                ) : (
                  <span>{'-' + userRating.length}</span>
                )}
              </b>
            </div>
            {userRating.length === 0 ? (
              <div style={{ fontSize: '0.9rem' }}>No Reviews Found</div>
            ) : (
              userRating.map((rating, index) => {
                return (
                  <div key={index} className='py-2'>
                    <div>
                      <img
                        src={getProfileImage(rating.IMAGE_URL)}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null // prevents looping
                          currentTarget.src = avatar
                        }}
                        className='rounded-circle'
                        style={{ objectFit: 'cover' }}
                        width='30'
                        height='30'
                      />{' '}
                      {decodeNames(rating.FULL_NAME)}
                    </div>
                    <div>
                      <ReactStars
                        count={5}
                        edit={false}
                        value={rating.RATINGS}
                        size={20}
                        emptyIcon={<i className='far fa-star'></i>}
                        fullIcon={<i className='fa fa-star'></i>}
                        activeColor='#49796F'
                      />{' '}
                      {rating.CREATED_DATE}
                    </div>
                    <div>{decodeNames(rating.FEEDBACK)}</div>
                  </div>
                )
              })
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
