import "../Styles/SignUp.css";
import Blog_admin_side_img from "../images/sign_in_side_img.png";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, signInWithGoogle } from "./Firebase";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { registerWithEmailAndPassword } from "./Firebase";
import axios from "axios";
import qs from "qs";
import Gj_Side_Logo from "../images/gj_side_logo.png";
import google_icon from "../images/Google_icon.png";
import { ToastContainer } from "react-toastify";
function SignUp() {
  const [email, setemail] = useState();
  const [password, setPassword] = useState();
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  useEffect(()=>{
    if(user){
      signUpApi(user);
    }
  },[user, loading])



  const signUpApi = () =>{
    const reqData = {
      mod: "AA",
      actionType: "verify-sign-in",
      subAction: JSON.stringify({
        EMAIL: user.email,
        UUID: user.uid,
         PLATFORM: "WEB",
      }),
    };
    console.log(reqData.subAction, "REQDATA");

    axios({
      method: "post",
      url: "https://dev-api.guidejuniors.com/",
      data: qs.stringify(reqData),
      header: { "content-type": "application/x-www-form-urlencoded" },
    }).then(
      (res) => {
        // console.log(res, "ges");
        let userData = res.data;
        console.log(userData,"userData");
        localStorage.setItem("DEVICE_KEY", userData.XSCData.DEVICE_KEY);
        userData.XSCData.REGISTRATION_STATUS === "COMPLETED"
          ? navigate("/dashboard")
          : navigate("/create-profile");
      },

    );
  }

  const register = (e) => {
    registerWithEmailAndPassword(email, password);
    e.preventDefault();
    // setPassword("");
    // setEmail("");
    // console.log(user.email, "email");
    // console.log(user.uid, "password");


    
  };
  const signupWidth = window.innerWidth;
  return (
    <div className=" container-fluid ">
      <div className="sign_up_page  ">
        <div
          className={
            signupWidth < 767
              ? "row px-2 px-md-0 py-1"
              : signupWidth < 991
              ? "row py-5 px-2 "
              : "row signup_resolution"
          }
        >
          <div className="signup_form  px-0 col-lg-6 col-md-12 col-sm-12 col-xs-12">
            {signupWidth < 991 ? (
              <div className="d-flex px-md-2  justify-content-between py-2">
                <div>
                  {" "}
                  <i
                    className="fa fa-long-arrow-left "
                    style={{ fontSize: "1.5rem" }}
                  ></i>
                  <a
                    href="https://guidejuniors.com/"
                    className="text-decoration-none text-dark"
                  >
                    <span
                      className="ps-2 "
                      style={{ fontSize: "0.8rem", fontWeight: "600" }}
                    ></span>
                  </a>
                </div>
                <div>
                  <img src={Gj_Side_Logo} width="30" alt="GJ_LOGO" />
                </div>
              </div>
            ) : (
              <div className="home_naviagtion">
                <a className="text-decoration-none text-dark" href="/sign-in">
                  {" "}
                  <i
                    className="fa fa-long-arrow-left "
                    style={{ fontSize: "0.8rem" }}
                  ></i>
                  <span
                    className="ps-2"
                    style={{ fontSize: "0.8rem", fontWeight: "600" }}
                  >
                    Home
                  </span>
                </a>
              </div>
            )}

            <div className="px-2 px-md-5 signin_form mobile_width_signup">
              <p style={{ fontColor: "#515C5A" }}>
                <b>Sign-up</b>
              </p>
              <div
                style={{
                  fontWeight: "900",
                  fontSize: "2.3rem",
                  lineHeight: "56px",
                }}
              >
                <b>Welcome </b>
              </div>
              <div>
                <div
                  style={{
                    color: "#6C7674",
                    fontSize: "0.8rem",
                    fontWeight: "500",
                    lineHeight: "24px",
                  }}
                >
                  {" "}
                  Please enter your account details
                </div>
                <form>
                  <div className="py-2" style={{ fontSize: "0.8rem" }}>
                    {" "}
                    <div>
                      <label>
                        <b>Email address</b>
                      </label>
                    </div>
                    <input
                      className="mobile_width_signup"
                      type="email"
                      style={{
                        height: "60px",
                        width: "400px",
                        backgroundColor: " #FAFAFA",
                        borderRadius: "8px",
                        border: " 1.2px solid #DFE1E1",
                        padding: "20px",
                      }}
                      placeholder="Your Email Address"
                      required
                      onChange={(e) => setemail(e.target.value.trimStart())}
                      value={email}
                    />
                  </div>
                  <div className="py-2" style={{ fontSize: "0.8rem" }}>
                    {" "}
                    <div>
                      {" "}
                      <label>
                        <b>Password</b>
                      </label>
                    </div>
                    <input
                      className="mobile_width_signup"
                      type="password"
                      style={{
                        height: "60px",
                        width: "400px",
                        backgroundColor: " #FAFAFA",
                        borderRadius: "8px",
                        border: " 1.2px solid #DFE1E1",
                        padding: "20px",
                      }}
                      placeholder="Your Password"
                      required
                      onChange={(e) => setPassword(e.target.value.trimStart())}
                      value={password}
                    />
                  </div>
                  <div
                    className="py-2 mobile_width_signup"
                    style={{ fontSize: "0.8rem", width: "400px" }}
                  >
                    <button
                      type="submit"
                      className="btn"
                      style={{
                        height: "60px",
                        width: "100%",
                        background: "#FFAA3B",
                        fontSize: "0.8rem",
                        borderRadius: "8px",
                      }}
                      placeholder="SUBMIT"
                      onClick={register}
                    >
                      <b>Sign Up</b>
                    </button>
                  </div>
                </form>
                <div
                  align="center"
                  className="row py-2 px-0 mobile_width_signup"
                  style={{ fontSize: "0.8rem", width: "400px" }}
                >
                  <div className="col-5 px-0 ">
                    <hr />
                  </div>
                  <div className="col-2">OR</div>
                  <div className="col-5 px-0">
                    <hr />
                  </div>
                </div>
                <div className="py-2 mt-2 mobile_width_signup">
                  <div
                    className="btn  mobile_width_signup"
                    style={{
                      border: "1.2px solid #DFE1E1",
                      boxSizing: "border-box",
                      width: "400px",
                      height: "60px",
                      textAlign: "center",
                      fontSize: "0.8rem",
                      paddingTop: "20px",
                    }}
                    onClick={signInWithGoogle}
                  >
                    <div>
                      <img src={google_icon}></img>
                      <span className="ps-2">Login with Google</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                align="center"
                className="mobile_width_signup py-2"
                style={{ fontSize: "0.8rem", width: "400px" }}
              >
                Already have an account yet?
                <span>
                  <Link
                    to="/sign-in"
                    style={{ color: "#FFAA3B" }}
                    className="ps-2 text-decoration-none"
                  >
                    Sign in
                  </Link>
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-6 display_hidden_medium ">
            <div className="float-end" style={{ margin: "-12px" }}>
              <img
                src={Blog_admin_side_img}
                className="img-fluid "
                alt="blog_admin"
                style={{ height: "100vh" }}
              />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default SignUp;
