import Create_Profile from "./Components/ProfileUploadForm/CreateProfile";
import Sign_in from "./Components/SignIn";
import Sign_up from "./Components/SignUp";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./Components/Dashboard";
import "./App.css";
import NotesMaterials from "./Components/NotesMaterials";
import Connections from "./Components/Connections";
import Message from "./Components/Message";
import FindMentors from "./Components/FindMentors";
import MentorProfile from "./Components/MentorProfile";
import UserProfile from "./Components/UserProfile";
import EditUserProfile from "./Components/EditUserProfile";
import EditMaterial from "./Components/EditMaterial";
import MaterialsUpload from "./Components/MaterialUploadForm/MaterialUpload";
import MaterialsUploadUpdate from "./Components/MaterialUploadFormUpdate/MaterialUpload";

import Password_bg from "./Components/Password_bg";
import { socket, SocketContext } from "./context/socket";
import ForgetPassword from "./Components/ForgotPassword";
import ChildRequest from "./Components/ChildRequest";
// import MobileMessage from "./Components/MobileMessage";

// import { Reset_password } from './Components/Reset';
function App() {
  return (
    <SocketContext.Provider value={socket}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Sign_in />} />
          <Route path="/sign-in" element={<Sign_in />} />
          <Route path="/reset-password" element={<Password_bg />} />
          <Route path="/sign-up" element={<Sign_up />} />
          {/* <Route path ="/reset-password" element={<Reset_password />} /> */}
          <Route path="/create-profile" element={<Create_Profile />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/notes-materials" element={<NotesMaterials />} />
          <Route path="/connections" element={<Connections />} />
          <Route path="/messages" element={<Message />} />
          <Route path="/find-mentors" element={<FindMentors />} />
          <Route
            path="/find-mentors/mentor-profile"
            element={<MentorProfile />}
          />
          <Route path="/user-profile" element={<UserProfile />} />
          <Route
            path="/user-profile/edit-profile"
            element={<EditUserProfile />}
          />
          <Route
            path="/user-profile/edit-profile/edit-materials"
            element={<EditMaterial />}
          />
          <Route
            path="/user-profile/edit-profile/edit-materials/add-materials"
            element={<MaterialsUpload />}
          />
          <Route
            path="/user-profile/edit-profile/edit-materials/update-materials"
            element={<MaterialsUploadUpdate />}
          />
          {/* <Route
            path="/mobile-message"
            element={<MobileMessage />}
          /> */}
          <Route path="/user-verify" element={<ChildRequest />} />

        </Routes>
      </BrowserRouter>
    </SocketContext.Provider>
  );
}

export default App;
